@media screen and (min-width: 0px) and (max-width: 413px) {
    .cardDashboardInfo{
        margin-top: 10px !important;
    }

    .mobItemTable{
        margin-top: 15px;
    }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
    .cardDashboardInfo{
        margin-top: 10px;
    }

    .mobItemTable{
        margin-top: 15px;
    }
}