.documentMetaDataFormFields {
    width: 100%;
    margin-top: 5px;
}

.expoText {
    border: 1px solid #1777ff;
    border-radius: 10px;
    padding: 2px 4px;
    font-size: 10px;
    cursor: pointer;
    color: #1777ff;
}

.errorText {
    color: red;
    font-size: 12px;
  }

  .containerDocumentMobileView .ant-row {
    display: flex; 
  }

  @media screen and (min-width: 0px) and (max-width: 413px) {
    .addressContainer{
        display: flex;
        flex-direction: column;
        margin: 0px !important;
    }

    .title{
        margin-left: 10px !important;
    }

    .rowBuyerSupplierMobile{
        display: flex;
        flex-direction: row;
    }

    .containerDocumentMobileView .ant-row {
        flex-direction: column;
        width: 100%;
        padding: 0px;
      }

      .gutter-row{
        margin-top: 10px;
      }

      .rowMobileDocumentLabel{
        display: flex;flex-direction: row;
      }

      .addressCard{
        height: auto !important;
      }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
    .addressContainer{
        display: flex;
        flex-direction: column;
        margin: 0px !important;
    }

    .title{
        margin-left: 10px !important;
        margin-top: 10px !important;
    }

    .containerDocumentMobileView .ant-row {
      flex-direction: column;
      width: 100%;
      padding: 0px;
    }

    .gutter-row{
      margin-top: 10px;
    }

    .rowMobileDocumentLabel{
      display: flex;flex-direction: row;
    }
}