@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

/* Slim and rounded scrollbar for WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 6px;
    /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Color of the track */
    border-radius: 10px;
    /* Rounds the track */
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the scroll thumb */
    border-radius: 10px;
    /* Rounds the scroll thumb */
    border: 2px solid transparent;
    /* Space around the thumb */
    background-clip: content-box;
    /* Ensures the thumb respects the border */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Darker color when hovered */
}

/* For Firefox (using scrollbar-width) */
* {
    scrollbar-width: thin;
    /* Thin scrollbar */
    scrollbar-color: #888 #f1f1f1;
    /* Thumb color and track color */
}

/* Apply rounded track and thumb for Firefox */
.scrollable-element {
    overflow-y: auto;
    max-height: 400px;
    scrollbar-color: #888 #f1f1f1;
    /* Thumb color and track color */
    scrollbar-width: thin;
    /* Thin scrollbar */
}

.scrollable-element::-webkit-scrollbar-track {
    border-radius: 10px;
    /* Rounds the track edges */
}

.scrollable-element::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* Rounds the thumb edges */
}

.tra-menu .wsmainfull {
    padding: 0 !important;
}

.header-wrapper {
    background-color: white !important;
}

svg {
    height: 350px !important;
    margin-left: 0 !important;
}

/* .anticon {
    height: 30px !important;
    margin-left: 0 !important;
} */

/* .ant-dropdown-menu {
    margin-top: 20px !important;
    padding: 0 !important;
} */

.flexBox {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
}

.alignedFlex {
    display: flex;
    column-gap: 10px;
}

.switch-split-screen {
    margin-right: 20px;
}

.text-split {
    margin-right: 20px;
}

table tr:nth-child(odd) {
    /* background-color: #f8f8f8; */
    /* Light grey for odd rows */
}

table tr:nth-child(even) {
    /* background-color: #ffffff; */
    /* White for even rows */
}

table tr:hover {
    background-color: #f8f8f8 !important;
    /* Change background color to light grey on hover */
}

.mt-20 {
    margin-top: 20px;
}

.ant-card {
    padding: 15px !important;
}

.ant-card-body,
.ant-card-head {
    padding: 0 !important;
}

.backLinkLG {
    color: #1677ff;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
}

.backLinkSM {
    color: #1677ff;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
}

.halfGrow {
    flex: 1;
}

.title {
    font-weight: 500;
}

.link {
    color: #1677ff;
    cursor: pointer;
}

.linkText {
    color: #1677ff;
    font-size: 15px;
    font-weight: 500;
}

.p-20 {
    padding: 20px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.flex-grow-1 {
    flex-grow: 1;
}

.noData {
    text-align: center;
    vertical-align: middle;
}

.col-gap-20 {
    column-gap: 20px;
}

.col-gap-100 {
    column-gap: 100px;
}

.bgWhite {
    background-color: #ffffff;
}

.p-10 {
    padding: 10px;
}

.br-10 {
    border-radius: 10px;
}

.fw-500 {
    font-weight: 500;
}

.minWidth200 {
    min-width: 200px;
}

.minWidth100 {
    min-width: 100px;
}

.maxWidth100 {
    max-width: 100px !important;
}

.actionIcons {
    cursor: pointer;
    color: #000000 !important;
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.actionIcons:hover {
    background-color: transparent !important;
}

.mb-flexFlow {
    flex-flow: row;
}

.headerRight {
    margin-top: 20px;
}

.noData img {
    margin-top: 100px;
}

.viewMore {
    font-size: 12px;
    cursor: pointer;
    color: #1677ff;
}

.center {
    text-align: center;
}

.ant-table-cell {
    padding: 10px !important;
}

.ant-layout-footer {
    padding: 10px 15px;
    background-color: #ffffff !important;
    color: #000000 !important;
    border-top: 1px solid #dddddd;
}

.ant-table-wrapper,
.table-container {
    height: calc(100% - 90px);
    overflow: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.splitterTable {
    height: calc(100%);
}

.ant-splitter {
    height: calc(100vh - 270px) !important;
    box-shadow: none;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
}

.content-section {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 10px;
    height: calc(100vh - 170px);
    overflow: hidden;
}

.textDocument {
    color: #1677ff;
    font-weight: 600;
    font-size: 22px;
  }
  
.mainLayout {
    max-height: calc(100vh);
    overflow: hidden;
}

.ant-breadcrumb {
    margin: 10px 0 !important;
}

.ant-btn-link:hover {
    color: #1777ff !important;
    background-color: #ebf4ff !important;
}


button {
    font-size: 14px;
    color: #1890ff;
    transition: all 0.3s ease;
}

button:hover {
    color: #40a9ff;
}

button .anticon {
    margin-right: 0;
}

.search-input {
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-input:focus {
    border-color: #1890ff;
    box-shadow: 0 0 8px rgba(24, 144, 255, 0.2);
}

.no-data-dimensions {
    width: auto;
    height: 300px;
}
  
.form-label {
    background: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
}
  

button {
    padding: 0 10px;
    display: inline-flex;
    align-items: center;
}

button:hover {
    background-color: transparent;
}

.global-search-input {
    min-width: 500px;
    /* Maximum width on larger screens */
    padding: 10px;
    border: none;
    border-radius: 2px;
    /* Softer rounded corners for modern look */
    background-color: #f0f2f5;
    /* Light grey background for modern look */
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    color: #333;
    outline: none;
}

.global-search-input::placeholder {
    color: #aaa;
    /* Subtle placeholder color */
    transition: color 0.3s ease;
}

.global-search-input:focus {
    border-color: transparent;
    /* Blue border when focused */
    background-color: #fff;
    /* Change background to white on focus */
    box-shadow: 0 0 5px rgba(24, 144, 255, 0.5);
    /* Modern shadow effect */
}

.global-search-input:hover {
    /* Border changes color on hover */
}

.documentSlicerPanel {
    padding: 20px;
}

/* file and preview CSS */

.image-preview-container {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px;
}

.previewImage {
    width: auto;
    height: 100px;
    border-radius: 8px;
    margin: 0;
}

.remove-icon {
    position: absolute;
    margin-top: -16px;
    margin-left: -6px;
    font-size: 15px !important;
    color: #FFFFFF;
    background-color: red;
    border-radius: 25%;
    padding: 6px 4px;
    cursor: pointer;
    transition: color 0.3s;

}

.file-upload-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.file-upload-label {
    padding: 5px 15px;
    background-color: #FFFFFF;
    color: #000;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 400;
}

.file-upload-label:hover {
    background-color: #FFFFFF;
}

.file-name {
    color: #555;
}

/* file and preview CSS */

.error {
    font-size: 14px;
    color: #ff4d4f;
}

.fileList {
    padding: 2px 0;
    color: #1677ff;
}

.deleteIcon {
    color: red;
}

.fileCard {
    min-height: 200px;
}

/* Card Styling */
.reportCard {
    padding: 20px;
    border-radius: 8px;
    height: 150px;
    background: linear-gradient(135deg, #f7e7ff 0%, #e8effa 100%);
    color: #000000;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
}

/* Hover Effect */
.reportCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.25);
}

/* Radio Group Position */
.radio-group-top-right {
    position: absolute;
    top: 15px;
    right: 15px;
}

/* Title Styling */
.card-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
}

/* Description Styling */
.card-description {
    font-size: 1rem;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.85);
    margin-top: 10px;
}

/* Text Content Row */
.text-content-row {
    display: flex;
    justify-content: center;
}

/* Text Content Column */
.text-content-column {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.stockDropdownTitle {
    /* margin-bottom: 5px; */
}

/* dashboard.css */
.dashboard-card {
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ellipsisMenu {
    margin-top: 0px !important;
}

.website-row{
    display: flex;
    flex-direction: row !important;
}

@media (max-width: 768px) {
    .mb-flexFlow {
        flex-flow: column;
    }

    .headerRight {
        margin-top: 0;
    }

    .global-search-input {
        max-width: 100%;
        /* Full width on smaller screens */
        margin-left: 0;
        /* Remove margin for responsiveness */
    }
}

@media screen and (min-width: 0px) and (max-width: 413px) {
    .fS-12{
        font-size: 12px !important;
    }

    .fS-16{
        font-size: 16px !important;
    }

    .fS-18{
        font-size: 18px !important;
    }

    .fS-20{
        font-size: 20px !important;
    }

    .fS-14{
        font-size: 14px !important;
    }

    .Mt-20{
        margin-top: 20px;
    }

    .Mt-25{
        margin-top: 25px;
    }

    .mobile-row{
        display: flex;
        flex-direction: row !important;
    }

    .M-0{
        margin: 0px !important;
    }

    .Mt-10{
        margin-top: 10px;
    }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
    .fS-12{
        font-size: 12px !important; 
    }

    .fS-14{
        font-size: 14px !important;
    }

    .fS-16{
        font-size: 16px !important;
    }

    .fS-20{
        font-size: 20px !important;
    }

    .Mt-20{
        margin-top: 20px;
    }

    .Mt-25{
        margin-top: 25px;
    }

    .mobile-row{
        display: flex;
        flex-direction: row !important;
    }

    .M-0{
        margin: 0px !important;
    }

    .Mt-10{
        margin-top: 10px;
    }
}