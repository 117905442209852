.network-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .card-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    position: absolute;
    top: 0;
    height: 100%;
  }
  
  .card-row.left {
    left: 0;
  }
  
  .card-row.right {
    right: 0;
  }
  
  .card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #ffffff;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    text-align: center;
    width: 150px;
    margin: 20px 0;
    z-index: 1;
  }
  
  .center-card {
    width: 300px;
    height: 150px;
    z-index: 2;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
  }
  
  .connecting-line {
    fill: none;
    stroke: #007bff;
    stroke-width: 2;
    stroke-dasharray: 5, 5;
    animation: dash 2s linear infinite;
  }
  
  @keyframes dash {
    to {
      stroke-dashoffset: -20;
    }
  }
  