.inner-page-hero{
    padding-top: 100px !important;
}

.comment-section{
    margin-top: 20px !important;
    background-color: white !important;
}

.commentForm {
    padding-left: 10px;
}

@media (max-width: 480px) {
    .blog-section-container{
        height: auto !important;
        margin: 0px !important;
        padding: 0px !important;
    }

    .blog-page-section{
        margin: 0px !important;
        padding: 80px 0px 0px !important;
    }

    .margin-mobile{
        margin-left: auto;
    }

    .blog-author{
        margin-top: -20px;
        margin-left: -10px;
    }

    .update{
        margin-left: -10px;
    }

    .blog-shortDesc{
        font-size: 16px !important;
    }

    .blog-desc{
        font-size: 16px !important;
    }
}

@media (min-width: 481px) and (max-width: 684px) and (orientation: landscape) {
    .blog-section-container{
        margin-top: -70px;
    }
}

@media (min-width: 685px) and (max-width: 750px) and (orientation: landscape) {
    .blog-section-container{
        margin-top: -70px;
    }
}

@media (min-width: 751px) and (max-width: 899px) and (orientation: portrait) {
    .blog-section-container{
        margin-top: -70px !important;
    }
}