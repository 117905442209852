.linkToDocs {
  margin-top: -10px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.divDocuments {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.divEditButton {
  display: flex;
  justify-content: flex-end !important;
  align-items: right !important;
  margin-top: -30px !important;
}

.addressContainer {
  display: flex;
  color: gray;
  width: 100%;
  column-gap: 10px;
  font-size: 15px;
}

.infoCard,
.addressCard {
  flex: 1;
  overflow: visible;
  border-radius: 15px;
  height: auto;
  font-size: 15px;
}

.infoCard:last-child

.cardContent {
  display: flex;
  flex-direction: column;
}

.cardParagraph {
  color: #8c8a89;
  margin-top: 10px;
  font-size: 14px;
  white-space: normal;
}
.cardPhoneInfo {
  color: #8c8a89;
  font-size: 14px;
  margin-top: -15px;
}

.cardForm {
  width: 100%;
  margin-top: 10px;
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 20px;
}

.formItem {
  flex: 1 1 22%;
  min-width: 200px;
}

.formItem .ant-form-item-control-input {
  height: 100%;
}

.datePicker,
input,
select {
  width: 100%;
  height: 32px;
}

.phoneContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editPhoneButton { 
  margin-top: -125px;
}
