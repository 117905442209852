.inventory-landing-container {
  display: flex;
  flex-direction: column;
  margin: 0px 110px;
}

.section-inventory-details {
  height: calc(100vh - 200px);
  width: 100%;
  margin-top: 10px;
  place-content: center !important;
  place-items: center !important;
}

.section-multiple-warehouse {
  height: calc(100vh - 350px);
  width: 100%;
  margin-top: 10px;
  padding: 50px;
  display: flex;
  flex-direction: row;
}

.section-physical-stock {
  height: calc(100vh - 350px);
  width: 100%;
  place-content: center !important;
  place-items: center !important;
  padding: 0px !important;
}

.section-inventory-feature {
  width: 100%;
  margin-top: 40px;
  place-content: center !important;
  place-items: center !important;
  background-color: #1777ff;
  color: white;
  height: calc(100vh - 600px);
  border-radius: 20px;
}

.section-inventory-cards {
  background-color: #1777ff;
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin-top: 20px;
  padding: 30px;
  place-content: center !important;
  place-items: center !important;
}

.section-bulk-upload {
  height: calc(100vh - 350px);
  width: 100%;
  place-content: center !important;
  place-items: center !important;
}

.section-alternate-units {
  height: calc(100vh - 350px);
  width: 100%;
  margin-top: 40px;
  place-content: center !important;
  place-items: center !important;
}

.section-custom-fields {
  height: calc(100vh - 450px);
  width: 100%;
  margin-top: 40px;
  place-content: center !important;
  place-items: center !important;
}

.section-multiple-categories {
  height: calc(100vh - 450px);
  width: 100%;
  margin-top: 40px;
  place-content: center !important;
  place-items: center !important;
}

.section-stock-real-time {
  height: calc(100vh - 450px);
  width: 100%;
  margin-top: 40px;
  place-content: center !important;
  place-items: center !important;
}

.text-inventory-feature {
  font-size: 38px;
}

.text-heading-feature {
  margin-top: 20px;
  font-size: 50px;
  font-weight: 400;
}

.inventory-container-row {
  display: flex;
  flex-direction: row;
}

.inventory-container-column {
  display: flex;
  flex-direction: column;
}

.image-multiple-warehouse {
  height: 300px;
  width: 600px;
}

.container-multiple-warehouse {
  margin-left: 100px;
  margin-top: 15px;
}

.content-inventory-text {
  font-size: 18px;
  color: black;
  margin-top: 15px;
  line-height: 15px;
}

.image-phy-stock {
  height: 300px;
  width: 500px;
  margin-left: 60px;
}

.container-phy-stock {
  margin-top: 60px;
}

.image-bulk-upload {
  height: 350px;
  width: 400px;
}

.container-bulk-upload {
  margin-top: 50px;
  margin-left: 100px;
}

.inventory-footer-container {
  margin-top: 30px;
}

.heading-inventory-intro {
  font-size: 38px;
  font-weight: 400;
}

.paragh-text-intro {
  font-size: 20px;
  font-weight: 300;
  margin-top: 10px;
}

.image-stock-history {
  width: 600px;
  height: 300px;
}

.container-inventoryDetailed-cards {
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.inventoryDetailedColumn {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto;
}

.inventoryDetailedCardOne {
  border: 2px solid #0b5fd7;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 200px !important;
  height: 20%;
  margin-left: 40px;
  margin-top: 13px;
}

.inventoryDetailedcardTwo {
  border: 2px solid #1777ff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 200px !important;
  height: 20%;
  margin-top: 13px;
}

.inventoryDetailedMainCard {
  height: 80%;
  border-radius: 10px;
  width: 350px;
  height: 50%;
  margin-top: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.inventoryDetailedCardThree {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 200px !important;
  height: 20%;
  margin-top: 13px;
}

.inventoryDetailedcardFour {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 200px !important;
  height: 20%;
  margin-top: 13px;
  margin-left: 40px;
}

.dashed-line-blue {
  border: 2px solid #0b5fd7 !important;
  width: 100px;
  background-image: repeating-linear-gradient(angle color-stop1, color-stop2);
}

.dashed-line-lightblue {
  border: 2px solid #1777ff !important;
  width: 100px;
}

.image-order-confirmation {
  height: 300px;
  width: 600px;
}

.image-invoice-challan {
  height: 300px;
  width: 600px;
}

.container-order-confirmation {
  margin-right: 50px;
}

.image-good-received {
  height: 300px;
  width: 600px;
}

.container-goods-received {
  margin-right: 50px;
}

.heading-inventory-card {
  font-size: 40px;
  color: white;
}

.button-inventory-signup {
  color: #1777ff;
  height: 50px;
  width: 200px;
  margin-top: 10px;
}

.inventory-card-row {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.inventory-cards {
  height: 300px;
  width: 200px;
  background-color: white;
  margin: 20px 50px;
  border-radius: 10px;
  place-content: center !important;
  place-items: center !important;
  padding: 10px;
  text-align: center;
  color: #1777ff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  opacity: 0;
  transform: scale(1.2);
}

@media (prefers-reduced-motion: no-preference) {
  .inventory-cards {
    transition: opacity 2s ease, transform 2s ease;
  }
}

.inventory-card-transition {
    opacity: 1;
    transform: none;
}

.icon-inventory-cards {
  height: 50px;
  width: 50px;
}

.headingh3 {
  font-size: 22px;
  color: #1777ff;
  margin-top: 5px;
}

.paraghtextCards {
  color: #1777ff;
  font-size: 14px;
  font-weight: 200;
  margin-top: 5px;
}

.heading-frequently-asked {
  font-size: 30px;
  font-weight: 200;
  margin-top: 20px;
}

@media screen and (max-width: 432px) {
  .inventory-landing-container {
    margin: 40px 10px;
    display: flex;
    flex-direction: column;
  }

  .section-inventory-feature {
    width: 100%;
    border-radius: 10px;
    height: 180px;
    color: white;
    margin-bottom: 10px;
    text-align: center !important;
    padding: 20px;
  }

  .text-inventory-feature {
    font-size: 27px;
  }

  .text-heading-feature {
    font-size: 28px;
  }

  .inventory-container-row img{
    order: -1;
  }

  .section-multiple-warehouse {
    display: flex;
    flex-direction: column !important;
    height: auto !important;
    margin-top: 20px;
    width: auto !important;
    padding: 10px;
  }

  .image-multiple-warehouse {
    height: 50%;
    width: 100%;
  }

  .section-physical-stock {
    display: flex;
    flex-direction: column;
    height: auto !important;
    padding: 0px 10px 10px !important;
    margin: 0px !important;
  }

  .inventory-container-row {
    display: flex;
    flex-direction: column;
  }

  .container-multiple-warehouse {
    margin-left: 0px;
    margin-top: 20px;
  }

  .content-inventory-text {
    line-height: 1px !important;
  }

  .container-phy-stock {
    margin-top: 20px;
  }

  .image-phy-stock {
    height: 50%;
    width: 100%;
    margin: 0px;
  }

  .image-bulk-upload {
    height: 100%;
    width: 100%;
  }

  .section-bulk-upload {
    height: auto;
    width: 100%;
    margin-top: 10px !important;
    padding: 0px 10px;
  }

  .container-bulk-upload {
    margin: 20px 0px 0px;
  }

  .section-alternate-units {
    height: auto;
    width: 100%;
    padding: 0px 10px 0px;
    margin: 0px;
  }

  .image-stock-history {
    height: 100%;
    width: 100%;
  }

  .image-good-received {
    height: 100%;
    width: 100%;
  }

  .image-order-confirmation {
    height: 100%;
    width: 100%;
  }

  .image-invoice-challan {
    height: 100%;
    width: 100%;
  }

  .icon-check {
    margin-top: 3px !important;
  }

  .section-inventory-cards {
    background-color:white;
    width: 100%;
    height: auto !important;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 10px;
    place-content: center !important;
    place-items: center !important;
    text-align: center !important;
  }

  .heading-inventory-card {
    font-size: 30px;
    color: black;
  }

  .button-inventory-signup {
    color: #1777ff;
    height: 50px;
    width: 200px;
    margin-top: 10px;
    border: 1px solid #1777ff;
  }

  .inventory-card-row {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }

  .inventory-cards {
    height: 300px;
    width: 300px;
    background-color: white;
    margin: 20px;
    border-radius: 10px;
    place-content: center !important;
    place-items: center !important;
    padding: 10px;
    text-align: center;
    color: #1777ff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .icon-inventory-cards {
    height: 50px;
    width: 50px;
  }

  .headingh3 {
    font-size: 22px;
    color: #1777ff;
    margin-top: 5px;
  }

  .paraghtextCards {
    color: #1777ff;
    font-size: 14px;
    font-weight: 200;
    margin-top: 5px;
  }
}

@media screen and (min-width: 1241px) and (max-width: 1440px) {
  .inventory-landing-container {
    display: flex;
    flex-direction: column;
    margin: 0px 110px;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1640px) {
  .inventory-landing-container {
    display: flex;
    flex-direction: column;
    margin: 0px 110px;
  }
}

@media screen and (min-width: 1641px) and (max-width: 2440px) {
  .inventory-landing-container {
    display: flex;
    flex-direction: column;
    margin: 0px 200px;
  }

  .section-inventory-feature {
    width: 100%;
    margin-top: 40px;
    place-content: center !important;
    place-items: center !important;
    background-color: #1777ff;
    color: white;
    height: calc(100vh - 700px);
    border-radius: 20px;
  }
  
  .section-multiple-warehouse {
    height: calc(100vh - 420px);
    width: 100%;
    margin-top: 10px;
    padding: 50px;
    display: flex;
    flex-direction: row;
  }
  
  .section-physical-stock {
    height: calc(100vh - 420px);
    width: 100%;
    place-content: center !important;
    place-items: center !important;
    padding: 0px !important;
  }

  .section-bulk-upload {
    height: calc(100vh - 420px);
    width: 100%;
    place-content: center !important;
    place-items: center !important;
  }
  
  .section-alternate-units {
    height: calc(100vh - 420px);
    width: 100%;
    place-content: center !important;
    place-items: center !important;
    margin-top: 0px;
  }
}