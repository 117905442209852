.dashboardBody {
  background-image: url('https://png.pngtree.com/background/20210717/original/pngtree-abstract-backgrouns-set-grunge-texture-minimalistic-art-brush-strokes-style-design-picture-image_1446772.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.avatar {
  background-color: #1677ff;
  margin-right: 10px;
  text-align: center;
  padding-left: 3px;
  margin-left: 100px;
}

.logo-easemargin {
  margin: 15px 0px 0px -30px !important;
  height: 30px;
  position: absolute;
}

.ant-badge-count {
  font-size: 10px !important;
  border: none !important;
  max-height: 20px !important;
  max-width: 20px !important;
  box-shadow: none !important;
}

.header {
  display: flex;
  justify-content: space-between;
  background: #1777ff;
  max-height: 50px !important;
  top: 0;
  z-index: 999;
}

.notification-header{
  background-color: #1777ff;
  color: white;
  font-weight: bold;
  width: 100%;
  height: 50px;
  margin: 0;
  padding: 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.menu-notification{
  width: 300px;
  height: 300px;
  overflow-y: scroll;
}

.notification-user-action{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  font-size: 12px;
}

.paragh-text-time{
  font-size: 12px;
  margin: 1px 0 0 0;
}

.paragh-user-action{
  font-size: 14px;
}

.avatar-icon{
  margin-right: 10px !important;
}

.item-notification{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
  width: -webkit-fill-available;
  column-gap: 10px;
}

.dropdown-menu-notification{
  display: flex;
  flex-direction: row !important;
  padding: 10px;
  align-items: center;
}

.dismiss-icon{
  align-self: first baseline ;
  text-align: right !important;
  margin-top: 5px;
  margin-left: auto !important;
}

.hide {
  display: none;
}

.notification-list-container {
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto;
  min-height: 300px;
}

.notification-list-container > li {
  background-color: #FFFFFF !important;
}

.item-notification {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}

.avatar-icon {
  margin-right: 10px;
}

.notification-user-action {
  flex-grow: 1;
}

.paragh-user-action {
  margin: 0;
}

.paragh-text-time {
  font-size: 12px;
  color: gray;
}

.dismiss-icon {
  cursor: pointer;
  align-self: center;
}

.notification-list-container > li {
  padding: 0 10px;
}

.noNotifications {
  margin-top: 85px;
  text-align: center;
}
/* General styles for the sidebar */
.sideNav {
  transition: background 0.3s, transform 0.3s; /* Smooth transition */
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Active state for the sidebar */
.sideNav.active {
  transform: translateX(0); /* Slide in */
}

/* Styling the menu items */
.ant-menu-item,
.ant-menu-submenu-title {
  font-size: 15px; /* Modern font size */
  color: #333; /* Dark text color */
  transition: color 0.3s; /* Transition effect for color only */
}

/* Change text color on hover for regular items only */
.ant-menu-item:hover:not(.ant-menu-submenu-title),
.ant-menu-submenu-title:hover {
  color: #1890ff; /* Blue text color */
}

/* Prevent highlighting of submenu items on hover */
.ant-menu-submenu-title:hover {
  background-color: transparent; /* No background change */
  color: #333; /* Keep the same color */
}

/* Icon styling */
.anticon {
  font-size: 20px; /* Increase icon size */
}

/* Active menu item style */
.ant-menu-item-selected {
  background-color: transparent; /* No background change on active */
  color: #1890ff; /* Change text color for active item */
}

/* Styling for the submenus */
.ant-menu-submenu {
  border-radius: 4px; /* Rounded corners */
  width: 100%;
}

/* Styling the submenu items */
.ant-menu-submenu-title {
  padding: 10px 15px; /* Padding for better spacing */
}


/* Responsive design */
@media (max-width: 768px) {
  .sideNav {
    width: 100%; /* Full width on smaller screens */
  }
}


@media (max-width: 768px) {
  .logo {
    width: 100px;
    height: auto !important;
    margin: 0px 0px 0px 10px !important;
  }
  .hide {
    display: inline;
  }
  .dhide {
    display: none;
  }
  .hamburgur {
    color: #FFFFFF;
  }
  .header {
    padding: 0 10px !important;
  }
  .sideNav {
    transform: translateX(-100%);
    position: absolute !important;
    top: 50;
    left: 0;
    height: 100vh; /* Full height side nav */
    width: 250px;
    z-index: 10;
  }
  .sideNav.active {
    transform: translateX(0); /* Show the side nav when active */
  }
}