.refundMainContainer{
    height: auto;
    margin: 10px 110px 10px 110px !important;
    width: calc(100vw - 260px);
    padding: 20px
}

.headingRefund{
    font-weight: 400;
}

.textRefundIntro{
    font-weight: 400;
    margin-top: 10px;
}

.paraghRefund{
    margin-top: 10px;
    font-weight: 400;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
    .refundMainContainer{
        height: auto;
        margin: 50px 10px 0px !important;
        width: auto;
        padding: 20px;
    }
    
    .headingRefund{
        font-weight: 400;
    }
    
    .textRefundIntro{
        font-weight: 400;
        margin-top: 10px;
    }
    
    .paraghRefund{
        margin-top: 10px;
        font-weight: 400;
    }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
    .refundMainContainer{
        height: auto;
        margin: 10px 110px 10px 110px !important;
        width: calc(100vw - 260px);
        padding: 20px
    }
    
    .headingRefund{
        font-weight: 400;
    }
    
    .textRefundIntro{
        font-weight: 400;
        margin-top: 10px;
    }
    
    .paraghRefund{
        margin-top: 10px;
        font-weight: 400;
    }
}

@media screen and (min-width: 1241px) and (max-width: 1440px) {
    .refundMainContainer{
        height: auto;
        margin: 50px 10px 0px !important;
        width: auto;
        padding: 20px;
    }
  }
  
  @media screen and (min-width: 1441px) and (max-width: 1640px) {
    .refundMainContainer{
        height: auto;
        margin: 10px 110px 10px 110px !important;
        width: calc(100vw - 260px);
        padding: 20px
    }
  }
  
  @media screen and (min-width: 1641px) and (max-width: 2440px) {
    .refundMainContainer{
        height: auto;
        margin: 10px 190px 10px !important;
        width: calc(100vw - 260px);
        padding: 20px
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .refundMainContainer{
        height: auto;
        margin: 20px 60px !important;
        width: auto;
        padding: 20px
    }
    
    .headingRefund{
        font-weight: 400;
    }
    
    .textRefundIntro{
        font-weight: 400;
        margin-top: 10px;
    }
    
    .paraghRefund{
        margin-top: 10px;
        font-weight: 400;
    }
  }