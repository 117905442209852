.cardTable {
  margin-top: 10px;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.spaceTableContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.inputSearchWIthId {
  flex: 1 1 300px;
  margin: 0 10px;
  box-sizing: border-box;
  height: 32px;
  padding: 0 12px;
  max-width: 200px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.divSuffixforInput {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.divDivider {
  width: 1px;
  height: 20px;
  background: #dcdcdc;
  margin-right: 8px;
}

.buttonAddColumn {
  margin-right: 10px;
}

.tableWidth {
  min-width: 100px;
}

.table-component { 
  z-index: 100;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
  .inputSearchWIthId{
    max-height: 100% !important;
    max-width: 90%;
  }

  .spaceTableContent{
    display: flex;
    flex-direction: row !important;
  }

  .buttonEllipsisMobile{
    width: 10%;
  }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
  .inputSearchWIthId{
    height: 32px !important;
    max-height: 9%;
    max-width: 80%;
  }

  .spaceTableContent{
    display: flex;
    flex-direction: row !important;
  }
}