.about-container{
    height: auto;
    margin: 0px 110px;
    padding: 20px;
}

.heading-aboutus{
    font-size: 40px;
    margin-top: 10px;
    font-weight: 400;
}

.text-aboutus{
    color: black;
    font-size: 20px;
    font-weight: 200;
    margin-top: 20px;
}

.aboutus-block-container{
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    width: 100%;
}

.block-container-one{
    display: flex;
    flex-direction: row;
}

.block-container-two{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.block-container-three{
    display: flex;
    flex-direction: column;
    margin-left: 25px;
}

.mission-container{
    height: auto;
    width: 100%;
    background-color: #2e85ff;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
}

.vision-container{
    height: 200px;
    width: 400px;
    background-color: #20C1AA;
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.target-container{
    height: auto;
    width: 400px !important;
    max-width: 440px;
    background-color: #c5ddff;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
}

.core-container{
    height: 130px;
    width: 840px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.breif-container{
    height: 320px;
    width: 840px;
    background-color: #2e85ff;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-radius: 10px;
}

.brief-text{
    color: white;
    font-size: 15px;
    font-weight: 300;
}

.heading-brief-text{
    color: white;
}

.text-about-mission{
    color: white;
    font-size: 15px;
    font-weight: 300;
}

.core-sub-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    width: 100%;
    height: 100%;
    place-items: center;
}

.core-text{
    color: black;
    font-size: 25px;
    font-weight: 300;
}

.text-target{
    color: black;
    font-weight: 300;
    font-size: 14px;
    margin-top: 10px;
}

.card-core-text{
    background-color: #aecfff;
    padding: 5px;
    border-left-color: #2e85ff;
    border-left-width: 3px;
    margin-top: 13px;
    width: 200px !important;
}

@media screen and (min-width: 0px) and (max-width: 432px) {
    .about-container{
        height: auto; 
        margin: 40px 0px;
        padding-bottom: 0px;
    }

    .aboutus-block-container{
        display: flex;
        flex-direction: column;
        margin: 20px 0px;
    }

    .block-container-two{
        display: flex;
        flex-direction: column;
    }

    .block-container-three{
        margin: 20px 0px;
    }

    .target-container{
        height: auto !important;
        width: 100% !important; 
    }

    .core-container{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column; 
        margin: 10px;
    }

    .breif-container{
        height: auto;
        width: 100%;
        padding: 15px;
    }

    .core-sub-container{
        display: flex;
        flex-direction: column !important;
        margin-top: 15px;
    }

    .text-aboutus{
        font-size: 16px;
    }

    .mission-container{
        padding: 15px;
    }

    .core-text{
        font-size: 16px;
    }
    .card-core-text{
        margin-top: 5px;
        width: 150px !important;
    }

    .core-mobile-row{
        display: flex;
        flex-direction: row !important;
    }
}

@media screen and (min-width: 1241px) and (max-width: 1440px) {
    .about-container{
        height: calc(100vh + 100px);
        margin: 0px 110px;
        padding: 20px;
    }
  }
  
  @media screen and (min-width: 1441px) and (max-width: 1640px) {
    .about-container{
        height: auto;
        margin: 0px 110px;
        padding: 20px;
    }
  }
  
  @media screen and (min-width: 1641px) and (max-width: 2440px) {
    .about-container{
        height: auto;
        margin: 0px 200px;
        padding: 20px;
    }
  }