.container-warehouse {
    margin-left: -70px;
    margin-top: -25px;
}

.image-warehouse {
    height: 500px;
    width: 500px;
}

/* .container-inventory-text {
    margin-top: -70px;
} */

.container-sales-text {
    padding-top: 10px;
    position: relative;
    margin-top: -50px;
    height: calc(100vh - 150px);
    width: 100%;
}

.sub-container-sales{
    position: relative;
    margin-top: -100px !important;
}

.container-user-text {
    margin-top: 0px !important;
    position: relative;
    margin-left: 10px;
    height: calc(100vh - 160px);
    width: 100%;
    z-index: 10 !important;
}

/* .sub-container-sales {
    margin-bottom: 90px;
} */

.image-user-graph {
    margin-left: -60px;
}

.container-additional {
    margin-top: 0px !important;
    width: 100%;
    height: calc(100vh - 200px);
}

.image-security {
    margin-top: -130px;
    height: 500px !important;
    width: 500px !important;
}

.image-user {
    height: 500px;
}

.container-user-experience{
    margin-top: -70px;
    position: relative;
    z-index: 10;
}

.section-user{
    margin-top: 100px !important;
}

.container-production{
    margin-top: 10px;
    height: calc(100vh - 320px);
    z-index: 10 !important;
}

.container-inventory{
    margin-left: 15px;
    padding-top: 10px !important;
    position: relative;
    /* margin-top: -100px; */
    height: calc(100vh - 180px);
    width: 100%;
    z-index:10 !important;
}

.container-security{
    margin-left: 10px;
    height: 100%;
    padding: 0px !important;
    width: 100%;
    height: 100%;
    z-index: 10 !important;
}

.container-text-security{
    margin-top: -30px;
    z-index: 10 !important;
}

.container-security-reliability{
    width: 100%;
    height: 100%;
    z-index: 10 !important;
}

.image-sales{
    height: 450px
}

@media (max-width: 768px) {
    .container-warehouse {
        margin-left: -30px;
        margin-top: -15px;
    }

    .image-warehouse {
        height: 300px;
        width: 300px;
    }

    .container-inventory-text {
        margin-top: 10px;
    }

    .image-sales {
        margin-top: 50px;
    }

    .image-user-graph {
        margin-top: -80px;
    }

    .image-security {
        margin-top: -85px;
        margin-left: -30px;
    }

    .image-user{
        height: 350px;
        margin-left: -30px;
        margin-top: -30px;
    }

    .container-warehouse {
        margin-left: -20px;
        margin-top: -10px;
    }

    .image-warehouse {
        height: 250px;
        width: 250px;
    }

    .container-inventory-text {
        margin-top: 10px;
    }

    .image-sales {
        margin-top: -100px;
        position: relative;
    }

    .sub-container-sales{
        margin-top: 30px !important;
    }

    .image-user-graph {
        margin-top: -130px;
    }

    .image-security {
        margin-top: -85px;
        margin-left: -30px;
        height: 250px !important;
        width: 250px !important;
    }

    .image-user{
        height: 350px;
        margin-left: -30px;
        margin-top: -30px;
    }

    .container-user-experience p{
        line-height: 25px;
    }

    .line-height-paragh{
        margin-top: -22px;
    }

    .container-security{
        text-align: left;
    }

    .subcontainer-security{
        display: flex;
        flex-direction: row;
    }

    .heading-security{
        margin-left: 10px;
        margin-top: 14px;
    }

    .container-production{
        visibility: visible;
        width: 100%;
        height: 100%;
    }

    .container-inventory{
        margin-top: 20px;
        visibility: visible;
        height: 100%;
        padding: 0px;
        margin-left: 5px;
    }

    .container-sales-text{
        margin-top: 20px;
        width: 100%;
        height: 100%;
        margin-left: 2px;
    }

    .container-user-text{
        width: 100%;
        height: 100%;
        margin-left: 5px;
    }

    .section-user{
        height: calc(150vh - 0px);
        width: 100%;
        margin-top: -10px !important;
        position: relative;
    }

    .container-additional{
        width: 100%;
        height: 100%;
        position: relative;
        margin-top: -45px !important;
    }

    .container-security-reliability{
        width: 100% !important; 
        height: 100%;
    }

    .container-text-security{
        margin-top: 20px !important;
    }
/* 
    .footer{
        margin-top: -70px;
    } */
} 

@media screen and (min-width: 0px) and (max-width: 413px) {
    .section-user{
        height: auto !important;
        padding-bottom: 25px !important;
    }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
    .section-user{
        height: auto !important;
        padding-bottom: 20px !important;
    }
}