/* 
.report-cards {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.text-content-row {
  display: flex;
  flex-direction: row;
}

.text-content-column {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.radio-group-top-right {
  position: absolute;
  top: 16px;
  right: 16px;
}

.buttonViewReport {
  display: flex;
  justify-content: flex-end;
} */

.reports-dashboard {
  font-family: Arial, sans-serif;
}

.reports-dashboard h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.reports-row {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.report-card {
  flex: 1;
  max-width: 22%;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.inventory-report {
  background-color: rgb(230, 247, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  border-radius: 10px;
  border-left: 3px solid #abccfa;
}

.payment-report {
  background-color: #fffbe6;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  border-radius: 10px;
  border-left: 3px solid #f8e8bc;
}

.sales-report {
  background-color: rgb(255, 240, 246);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  border-radius: 10px;
  border-left: 3px solid #faadcb;
}

.purchase-report {
  background-color: rgb(240, 245, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  border-radius: 10px;
  border-left: 3px solid #8bb7f4;
}


.report-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.card-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}

.card-icon {
  font-size: 32px;
  color: #1890ff;
  margin-bottom: 12px;
}

.card-icon .bar-chart {
  color: #1890ff;
}

.card-icon .dollar {
  color: #52c41a;
}

.card-icon .sales {
  color: #faad14;
}

.card-icon .purchase {
  color: #f5222d;
}

.card-actions {
  margin-top: 12px;
}

.btn-link {
  background: none;
  border: none;
  color: #086ac5;
  cursor: pointer;
  font-size: 14px;
  transition: color 0.2s ease;
  padding: 0;
  margin-right: 10px;
  text-decoration: none;
}

.btn-link:hover {
  color: #0b5c9e;
}

.report-card p {
  font-size: 14px;
  color: #666666;
  margin: 8px 0;
}

@media (max-width: 768px) {
  .report-card {
    max-width: 48%;
    margin-bottom: 16px;
  }
}

@media (max-width: 480px) {
  .report-card {
    max-width: 100%;
  }
}