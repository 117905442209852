.calculation {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  font-size: 18px;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
  .title{
    font-weight: 500;
  }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
  .title{
    font-weight: 500;
  }
}