.blogList {
    padding-top: 100px !important;
}

.mainBlogImage {
    height: 250px !important;
    width: auto;
}

.readMore {
    color: #1780fb;
    font-size: 17px;
    cursor: pointer;
}

@media (max-width: 480px) {
    .container-blog{
        margin-top: 0px !important;
        height: auto !important;
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
    }

    .mainBlogImage{
        margin-left: auto;
    }
}