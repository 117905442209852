.image-hero-section {
    margin-left: -10px;
    margin-top: -200px;
    height: 540px;
    width: 540px;
}

.hero-section{
    display: flex;
    flex-direction: row;
    margin-top: -60px !important;
    width: 100%;
    height: calc(100vh - 150px);
    z-index: 990;
}

.heading-hero-section{
    margin-top: -20px !important;
    position: relative;
}

.sub-heading{
    font-size: 44px;
    font-weight: 500;
}

.image-india{
    height: 30px;
    width: auto;
    margin-bottom: 3px;
}

.rocket-icon{
    height: 35px;
    width: auto;
    /* margin-top: 5px; */
}

.text-rocket{
    margin-top: -25px !important;
    font-weight: 700;
    margin-left: 5px;
    padding-top: 5px;
    font-weight: 400;
}

.text-india{
    margin-top: 10px !important;
}

.text-credit{
    margin-top: -25px !important;
}

.div-coming-soon{
    background-color: #1780FB;
    width: 200px;
    height: 45px;
    color: white;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: center !important;
}

.div-coming-soon:hover {
    background-color: #0f60c5; 
    transform: translateY(-2px);
}

@media (max-width: 1024px) {
    .image-hero-section {
        margin-left: -8px;
        margin-top: -40px;
        height: 400px;
        width: 400px;
    }
    .heading {
        margin-top: 8px;
    } 
}

@media (max-width: 768px) {
    .image-hero-section {
        margin-left: 15px;
        margin-top: -120px;
        height: 450px;
        width: 450px;
    }
    .heading {
        margin-top: 6px;
    } 
}

@media (max-width: 480px) {
    .image-hero-section {
        margin-left: -3px;
        margin-top: -150px;
        height: 350px;
        width: 350px;
        z-index: 10;
    }
    .heading {
        margin-top: -85px;
    }

    .sub-heading{
        font-size: 30px;
        font-weight: 500;
    }

    .image-india{
        height: 22px;
        width: 38px;
        margin-top: 5px;
    }

    .rocket-icon{
        height: 28px;
        width: 28px;
        padding-top: -5px !important;
    }

    .div-coming-soon{
        margin-left: 60px;
        padding: auto;
    }

    .text-rocket{
        padding-top: 10px;
        font-weight: 400 !important;
    }

    .hero-section{
        position: relative;
        margin-top: -20px !important;
        height: calc(100vh + 160px);
        width: 100%;
        z-index: 990;
    }
}

@media screen and (min-width: 0px) and (max-width: 413px) {
    .hero-section{
        height: auto !important;
        padding-bottom: 0px !important;
    }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
    .hero-section{
        height: auto !important;
        padding-bottom: 0px !important;
    }
}