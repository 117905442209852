.logCards {
  flex: 1;
  max-width: 200px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.logCards:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.pendingCard {
  background-color: #fffbe6;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  border-radius: 10px;
  border-left: 3px solid #f8e8bc;
}

.approvedCard {
  background-color: #e6f7e6;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  border-radius: 10px;
  border-left: 3px solid #7cbf7c;
}

.cancelledCard {
  background-color: rgb(255, 240, 246);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  border-radius: 10px;
  border-left: 3px solid #faadcb;
}
