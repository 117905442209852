.loginContainer {
    display: flex;
    justify-content: space-between;
    height: calc(100vh);
    background-image: url('https://png.pngtree.com/background/20210717/original/pngtree-abstract-backgrouns-set-grunge-texture-minimalistic-art-brush-strokes-style-design-picture-image_1446772.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: black;
} 

.welcomeContent {
    flex-grow: 7;
    
    padding: 40px;
    max-width: 70%
}

.loginForm {
    background-color: #FFFFFF;
    flex-grow: 3;
    padding: 0 50px;
    align-content: center;
    opacity: 0.8;
}

.formBody {
    margin: 0 auto;
}

.loginTitle {
    margin: 20px 0;
}

.loginText {
    margin: 20px 0;
}

.link {
    color: #1677ff;
    cursor: pointer;
}

.loginError {
    background-color: red;
    color: white;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.loginBox {
    display: flex;
    justify-content: center;
    align-items: center; /* Ensures vertical centering */
    column-gap: 50px;
    height: calc(100vh - 50px); /* Takes full screen height */
    padding-left: 12%;
    padding-right: 12%;
    background: url('https://miro.medium.com/v2/resize:fit:1358/1*Zyoor62U2IIUNQDb0rZ04A.jpeg') no-repeat center center/cover;
    position: relative;
}

.loginBox::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7); /* Transparent white overlay */
    backdrop-filter: blur(20px); /* Glass effect */
    -webkit-backdrop-filter: blur(20px); /* Safari support */
    z-index: 1;
}

.left-section,
.right-section {
    position: relative;
    z-index: 2; /* Ensure content is above the glass effect */
}

.left-section {
    flex: 1; /* Keep left section smaller */
}

.left-section img {
    margin-left: -10px;
    height: 100px;
}

.right-section {
    flex: 1; /* Increase the width of the right section */
    display: flex;
    justify-content: center;
    align-items: center; /* Centers the form vertically */
}

.form-container {
    width: 100%;
    max-width: 500px;
    background: rgba(255, 255, 255, 0.8); /* Slightly transparent */
    padding: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    backdrop-filter: blur(15px); /* More blur for glass effect */
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle border for glass feel */
}

  
@media (min-width: 0px) and (max-width: 790px) {
    .mhide {
        display: none;
    }
    .loginContainer{
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: space-between;
    width: 100%;
    height: calc(100%);
    background-image: url('https://png.pngtree.com/background/20210717/original/pngtree-abstract-backgrouns-set-grunge-texture-minimalistic-art-brush-strokes-style-design-picture-image_1446772.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: black;
    }

    .formBody{
        margin-top: 30px;
    }

    .loginTitle{
        margin-top: 10px;
    }

    .loginHeading{
        margin-top: 100px;
    }

    .welcomeContent{
        padding: 0px;
        margin-top: 15px !important;
        margin-left: 48px;
    }
}