.footer {
  border-top: 1px solid #f4f4f9;
}
.footer>.container {
  padding: 50px 10px 0 10px !important;
}


.footer .bottom-footer {
  background-color: #f4f4f9;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

hr {
  padding: 0px !important;
  margin: 0px !important;
  margin-bottom: 15px !important;
}

.footer {
  height: 100%;
  width: 100%;
  padding: 0 !important;
}

@media (max-width: 576px) {
  .footer hr {
    margin: 5px 0;
  }

  .footer .row {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .footer .bottom-footer {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}