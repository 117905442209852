



/*
  Template Name: Martex - Software, App, SaaS & Startup Landing Pages Pack
  Theme URL: https://themeforest.net/user/dsathemes
  Description: Martex - Software, App, SaaS & Startup Landing Pages Pack
  Author: DSAThemes
  Author URL: https://themeforest.net/user/dsathemes
  Version: 1.0.0
  Website: www.dsathemes.com
  Tags: Responsive, HTML5, DSAThemes, Landing, Software, Mobile App, SaaS, Startup, Creative, Digital Produc
*/




/* ==========================================================================
  01. GENERAL & BASIC STYLES
  =========================================================================== */

html {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
} 

body {
  font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #6c757d;
  line-height: 1.6666;
  font-weight: 400;
}

/*------------------------------------------*/
/*  PAGE CONTENT
/*------------------------------------------*/

#page,
.hidd { 
  overflow: hidden; 
}

.rel,
section,
.container { 
  position: relative!important; 
  z-index: 3; 
}

.section-overlay {
  width: 100%;
  height: 100%;
}

/*------------------------------------------*/
/*  BLOCK SHADOW
/*------------------------------------------*/

.block-shadow { 
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
}

/*------------------------------------------*/
/*  BORDER SETTINGS
/*------------------------------------------*/

.block-border {
  border: 1px solid #e7e7e7;
}

.block-border.block-shadow { 
  border: 1px solid #f5f6f6;
}

.x-border {
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.top-border {
  border-top: 1px solid #e7e7e7;
}

.bottom-border {
  border-bottom: 1px solid #e7e7e7;
}

/*------------------------------------------*/
/*  BORDER RADIUS SETTING
/*------------------------------------------*/ 

.r-0 { -webkit-border-radius: 0px; -moz-border-radius: 0px; -o-border-radius: 0px; border-radius: 0px; }
.r-02 { -webkit-border-radius: 2px; -moz-border-radius: 2px; -o-border-radius: 2px; border-radius: 2px; }
.r-04 { -webkit-border-radius: 4px; -moz-border-radius: 4px; -o-border-radius: 4px; border-radius: 4px; }
.r-06 { -webkit-border-radius: 6px; -moz-border-radius: 6px; -o-border-radius: 6px; border-radius: 6px; }
.r-08 { -webkit-border-radius: 8px; -moz-border-radius: 8px; -o-border-radius: 8px; border-radius: 8px; }
.r-10 { -webkit-border-radius: 10px; -moz-border-radius: 10px; -o-border-radius: 10px; border-radius: 10px; }
.r-12 { -webkit-border-radius: 12px; -moz-border-radius: 12px; -o-border-radius: 12px; border-radius: 12px; }
.r-14 { -webkit-border-radius: 14px; -moz-border-radius: 14px; -o-border-radius: 14px; border-radius: 14px; }
.r-16 { -webkit-border-radius: 16px; -moz-border-radius: 16px; -o-border-radius: 16px; border-radius: 16px; }
.r-18 { -webkit-border-radius: 18px; -moz-border-radius: 18px; -o-border-radius: 18px; border-radius: 18px; }
.r-20 { -webkit-border-radius: 20px; -moz-border-radius: 20px; -o-border-radius: 20px; border-radius: 20px; }
.r-22 { -webkit-border-radius: 22px; -moz-border-radius: 22px; -o-border-radius: 22px; border-radius: 22px; }
.r-24 { -webkit-border-radius: 24px; -moz-border-radius: 24px; -o-border-radius: 24px; border-radius: 24px; }
.r-26 { -webkit-border-radius: 26px; -moz-border-radius: 26px; -o-border-radius: 26px; border-radius: 26px; }
.r-28 { -webkit-border-radius: 28px; -moz-border-radius: 28px; -o-border-radius: 28px; border-radius: 28px; }
.r-30 { -webkit-border-radius: 30px; -moz-border-radius: 30px; -o-border-radius: 30px; border-radius: 30px; }
.r-32 { -webkit-border-radius: 32px; -moz-border-radius: 32px; -o-border-radius: 32px; border-radius: 32px; }
.r-34 { -webkit-border-radius: 34px; -moz-border-radius: 34px; -o-border-radius: 34px; border-radius: 34px; }
.r-36 { -webkit-border-radius: 36px; -moz-border-radius: 36px; -o-border-radius: 36px; border-radius: 36px; }
.r-100 { -webkit-border-radius: 100px; -moz-border-radius: 100px; -o-border-radius: 100px; border-radius: 100px; }

/*------------------------------------------*/
/*  OPACITY SETTINGS
/*------------------------------------------*/

.o-0 { opacity: 0!important; }
.o-10 { opacity: .1!important; }
.o-15 { opacity: .15!important; }
.o-20 { opacity: .2!important; }
.o-25 { opacity: .25!important; }
.o-30 { opacity: .3!important; }
.o-35 { opacity: .35!important; }
.o-40 { opacity: .4!important; }
.o-45 { opacity: .45!important; }
.o-50 { opacity: .5!important; }
.o-55 { opacity: .55!important; }
.o-60 { opacity: .60!important; }
.o-65 { opacity: .65!important; }
.o-70 { opacity: .7!important; }
.o-75 { opacity: .75!important; }
.o-80 { opacity: .8!important; }
.o-85 { opacity: .85!important; }
.o-90 { opacity: .9!important; }
.o-95 { opacity: .95!important; }
.o-100 { opacity: 1!important; }

/*------------------------------------------*/
/*  SPACING & INDENTS
/*------------------------------------------*/

.py-5 { padding-top: 5px; padding-bottom: 5px; }
.py-10 { padding-top: 10px; padding-bottom: 10px; }
.py-15 { padding-top: 15px; padding-bottom: 15px; }
.py-20 { padding-top: 20px; padding-bottom: 20px; }
.py-30 { padding-top: 30px; padding-bottom: 30px; }
.py-40 { padding-top: 40px; padding-bottom: 40px; }
.py-50 { padding-top: 50px; padding-bottom: 50px; }
.py-60 { padding-top: 60px; padding-bottom: 60px; }
.py-70 { padding-top: 70px; padding-bottom: 70px; }
.py-80 { padding-top: 80px; padding-bottom: 80px; }
.py-90 { padding-top: 90px; padding-bottom: 90px; }
.py-100 { padding-top: 100px; padding-bottom: 100px; }

.pt-30 { padding-top: 30px; }
.pt-40 { padding-top: 40px; }
.pt-50 { padding-top: 50px; }
.pt-60 { padding-top: 60px; }
.pt-70 { padding-top: 70px; }
.pt-80 { padding-top: 80px; }
.pt-90 { padding-top: 90px; }
.pt-100 { padding-top: 100px; }

.pb-30 { padding-bottom: 30px; }
.pb-40 { padding-bottom: 40px; }
.pb-50 { padding-bottom: 50px; }
.pb-60 { padding-bottom: 60px; }
.pb-70 { padding-bottom: 70px; }
.pb-80 { padding-bottom: 80px; }
.pb-90 { padding-bottom: 90px; }
.pb-100 { padding-bottom: 100px; }

.mt-10 { margin-top: 10px; }
.mt-15 { margin-top: 15px; }
.mt-20 { margin-top: 20px; }
.mt-25 { margin-top: 25px; }
.mt-30 { margin-top: 30px; }
.mt-35 { margin-top: 35px; }
.mt-40 { margin-top: 40px; }
.mt-45 { margin-top: 45px; }
.mt-50 { margin-top: 50px; }
.mt-55 { margin-top: 55px; }
.mt-60 { margin-top: 60px; }
.mt-65 { margin-top: 65px; }
.mt-70 { margin-top: 70px; }
.mt-75 { margin-top: 75px; }
.mt-80 { margin-top: 80px; }
.mt-85 { margin-top: 85px; }
.mt-90 { margin-top: 90px; }
.mt-95 { margin-top: 95px; }
.mt-100 { margin-top: 100px; }

.mb-10 { margin-bottom: 10px; }
.mb-15 { margin-bottom: 15px; }
.mb-20 { margin-bottom: 20px; }
.mb-25 { margin-bottom: 25px; }
.mb-30 { margin-bottom: 30px; }
.mb-35 { margin-bottom: 35px; }
.mb-40 { margin-bottom: 40px; }
.mb-45 { margin-bottom: 45px; }
.mb-50 { margin-bottom: 50px; }
.mb-55 { margin-bottom: 55px; }
.mb-60 { margin-bottom: 60px; }
.mb-65 { margin-bottom: 65px; }
.mb-70 { margin-bottom: 70px; }
.mb-75 { margin-bottom: 75px; }
.mb-80 { margin-bottom: 80px; }
.mb-85 { margin-bottom: 85px; }
.mb-90 { margin-bottom: 90px; }
.mb-95 { margin-bottom: 95px; }
.mb-100 { margin-bottom: 100px; }

.ml-5 { margin-left: 5px; }
.ml-10 { margin-left: 10px; }
.ml-15 { margin-left: 15px; }
.ml-20 { margin-left: 20px; }
.ml-25 { margin-left: 25px; }
.ml-30 { margin-left: 30px; }

.mr-5 { margin-right: 5px; }
.mr-10 { margin-right: 10px; }
.mr-15 { margin-right: 15px; }
.mr-20 { margin-right: 20px; }
.mr-25 { margin-right: 25px; }
.mr-30 { margin-right: 30px; }

/*------------------------------------------*/
/*  BACKGROUND SETTINGS
/*------------------------------------------*/

.bg--fixed,
.bg--scroll {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
}

.bg--fixed {
  background-attachment: scroll!important;
}

.bg--scroll {
  background-attachment: fixed!important;
}

/*------------------------------------------*/
/*  BACKGROUND COLORS
/*------------------------------------------*/

.bg--white-500 { background-color: #f2f4f8; }
.bg--white-400 { background-color: #f4f4f9; }
.bg--white-300 { background-color: #f7f8f9; }
.bg--white-200 { background-color: #fbfbfd; }
.bg--white-100 { background-color: #fff; }

.bg--black-500 { background-color: #22223b; }
.bg--black-400 { background-color: #2e3648; }
.bg--black-300 { background-color: #394455; }
.bg--black-200 { background-color: #e1e3e5; }
.bg--black-100 { background-color: #ecf1f4; }

.bg--blue-500 { background-color: #185abd; }
.bg--blue-400 { background-color: #1680fb; }
.bg--blue-300 { background-color: #3eb1ff; }
.bg--blue-200 { background-color: #ddedff; }
.bg--blue-100 { background-color: #f4f9fc; }

.bg--green-500 { background-color: #00a14e; }
.bg--green-400 { background-color: #0fbc49; }
.bg--green-300 { background-color: #6fd792; }
.bg--green-200 { background-color: #cff2db; }
.bg--green-100 { background-color: #e9f8f2; }

.bg--pink-500 { background-color: #ff149c; }
.bg--pink-400 { background-color: #f74780; }
.bg--pink-300 { background-color: #fcb5cc; }
.bg--pink-200 { background-color: #fee4ec; }
.bg--pink-100 { background-color: #feecf2; }

.bg--purple-5000 { background-color: #663ae9; }
.bg--purple-500 { background-color: #7827e6; }
.bg--purple-400 { background-color: #7b5dd6; }
.bg--purple-300 { background-color: #bdaeea; }
.bg--purple-200 { background-color: #e5deff; }
.bg--purple-100 { background-color: #f1edff; }

.bg--red-500 { background-color: #da0f29; }
.bg--red-400 { background-color: #ff1e1a; }
.bg--red-300 { background-color: #ffa5a3; }
.bg--red-200 { background-color: #ffd2d1; }
.bg--red-100 { background-color: #fce7e4; }

.bg--violet-500 { background-color: #c73e9b; }
.bg--violet-400 { background-color: #be6cbe; }
.bg--violet-300 { background-color: #e2bde2; }
.bg--violet-200 { background-color: #f1cde5; }
.bg--violet-100 { background-color: #f7e4f1; }

.bg--yellow-500 { background-color: #ffba01; }
.bg--yellow-400 { background-color: #ffbd45; }
.bg--yellow-300 { background-color: #ffdf35; }
.bg--yellow-200 { background-color: #fef5cd; }
.bg--yellow-100 { background-color: #fef9e5; }

.bg--tra-black { background-color: rgba(0, 0, 0, .04); }
.bg--tra-white { background-color: rgba(255, 255, 255, .15); }
.bg--primary-blue { background-color: #2160fd; }
.bg--light-grey { background-color: #f0f3ff; }
.bg--snow { background-color: #fdfdfd; }

/*------------------------------------------*/
/*  GRADIENT COLOR BACKGROUND
/*------------------------------------------*/

.gr--ghost { background-image: linear-gradient(180deg, rgba(247, 248, 249, .8) 50%, rgba(247, 248, 249, .05) 100%); }
.gr--snow { background-image: linear-gradient(to left, #f3edf7, #f9f6fa); }
.gr--cotton { background-image: linear-gradient(180deg, rgba(223, 225, 250, .8) 50%, rgba(223, 225, 250, .05) 100%); }
.gr--ghostwhite { background-image: linear-gradient(to left, #e9e9f5, #f4f4f9); }
.gr--smoke { background: linear-gradient(180deg, #f8f9fe 0%, #f4f4f9 100%); }
.gr--whitesmoke { background-image: linear-gradient(180deg, rgba(244, 244, 249, .8) 50%, rgba(244, 244, 249, .05) 100%); }
.gr--perl { background-image: linear-gradient(180deg, rgba(236, 234, 253, .8) 50%, rgba(236, 234, 253, .05) 100%); }

/*------------------------------------------*/
/*  BACKGROUND SHAPE
/*------------------------------------------*/

.shape--01:after,
.shape--02:after,
.shape--03:after,
.shape--04:after,
.shape--05:after,
.shape--06:after,
.shape--07:after {
  position: absolute;
  content: '';
  z-index: -1;
}

.shape--02.r-24:after {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -o-border-radius: 24px;
  border-radius: 24px;
}

.shape--01:after {
  width: 150%;
  height: 80%;
  top: 13%;
  left: -25%;
}

.shape--02:after {
  width: 86%;
  height: 80%;
  top: 13%;
  left: 7%;
}

.shape--03:after {
  width: 78%;
  height: 120%;
  top: -60px;
  left: 11%;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -o-border-radius: 24px;
  border-radius: 24px;
}

.shape--04:after {
  width: 100%;
  height: 80%;
  top: 10%;
  left: 0;
}

.shape--05:after {
  width: 150%;
  height: 90%;
  top: 0;
  left: -25%;
}

.shape--06:after {
  width: 150%;
  height: 75%;
  top: 0;
  left: -25%;
}

.shape--07:after {
  width: 200%;
  height: 50%;
  top: 37%;
  left: -50%;
}

.shape--whitesmoke:after { background-color: rgba(123, 104, 238, .05); }

.shape--white-500:after { background-color: #f2f4f8; }
.shape--white-400:after { background-color: #f4f4f9; }
.shape--white-300:after { background-color: #f7f8f9; }
.shape--white-200:after { background-color: #fbfbfd; }

.shape--black-500:after { background-color: #22223b; }
.shape--black-400:after { background-color: #2e3648; }
.shape--black-300:after { background-color: #394455; }
.shape--black-200:after { background-color: #e1e3e5; }
.shape--black-100:after { background-color: #ecf1f4; }

.shape--blue-500:after { background-color: #185abd; }
.shape--blue-400:after { background-color: #1680fb; }
.shape--blue-300:after { background-color: #3eb1ff; }
.shape--blue-200:after { background-color: #ddedff; }
.shape--blue-100:after { background-color: #f4f9fc; }

.shape--green-500:after { background-color: #00a14e; }
.shape--green-400:after { background-color: #0fbc49; }
.shape--green-300:after { background-color: #6fd792; }
.shape--green-200:after { background-color: #cff2db; }
.shape--green-100:after { background-color: #e9f8f2; }

.shape--pink-500:after { background-color: #ff149c; }
.shape--pink-400:after { background-color: #f74780; }
.shape--pink-300:after { background-color: #fcb5cc; }
.shape--pink-200:after { background-color: #fee4ec; }
.shape--pink-100:after { background-color: #feecf2; }

.shape--purple-500:after { background-color: #663ae9; }
.shape--purple-400:after { background-color: #7b5dd6; }
.shape--purple-300:after { background-color: #bdaeea; }
.shape--purple-200:after { background-color: #e5deff; }
.shape--purple-100:after { background-color: #f1edff; }

.shape--red-500:after { background-color: #da0f29; }
.shape--red-400:after { background-color: #ff1e1a; }
.shape--red-300:after { background-color: #ffa5a3; }
.shape--red-200:after { background-color: #ffd2d1; }
.shape--red-100:after { background-color: #fce7e4; }

.shape--violet-500:after { background-color: #c73e9b; }
.shape--violet-400:after { background-color: #be6cbe; }
.shape--violet-300:after { background-color: #e2bde2; }
.shape--violet-200:after { background-color: #f1cde5; }
.shape--violet-100:after { background-color: #f7e4f1; }

.shape--gr-ghost:after { background-image: linear-gradient(180deg, rgba(247, 248, 249, .8) 50%, rgba(247, 248, 249, .05) 100%); }
.shape--gr-whitesmoke:after {background-image: linear-gradient(180deg, rgba(244, 244, 249, .8) 50%, rgba(244, 244, 249, .05) 100%);}

/*------------------------------------------*/
/*  BACKGROUND PATTERN
/*------------------------------------------*/

.pattern-01, 
.pattern-02, 
.pattern-03, 
.pattern-04, 
.pattern-05 {
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
}

.pattern-01 { background-image: url(../images/pattern-01.png); }
.pattern-02 { background-image: url(../images/pattern-02.png); }
.pattern-03 { background-image: url(../images/pattern-03.png); }
.pattern-04 { background-image: url(../images/pattern-04.png); }
.pattern-05 { background-image: url(../images/pattern-05.html); }

/*------------------------------------------*/
/*  BACKGROUND IMAGE
/*------------------------------------------*/

.bg--01, .bg--02, .bg--03, .bg--04, 
.bg--05, .bg--06, .bg--07, .bg--08 {
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
}

.bg--01 { background-image: url(../images/bg-01.jpg); }
.bg--02 { background-image: url(../images/bg-02.jpg); }
.bg--03 { background-image: url(../images/bg-03.jpg); }
.bg--04 { background-image: url(../images/bg-04.jpg); }
.bg--05 { background-image: url(../images/bg-05.jpg); }
.bg--06 { background-image: url(../images/bg-06.html); }
.bg--07 { background-image: url(../images/bg-07.html); }
.bg--08 { background-image: url(../images/bg-08.html); }

/*------------------------------------------*/
/*  SECTION DIVIDER
/*------------------------------------------*/

hr.divider {
  width: 100%;
  height: 1px;
  background-color: transparent;
  background-image: linear-gradient(90deg, rgba(206,211,246,0) 0, #bbb 38%, #bbb 64%, rgba(206,211,246,0) 99%);
  opacity: .4;
  border: none;
  margin: 0;
}

/*------------------------------------------*/
/*  WAVE SHAPE BOTTOM
/*------------------------------------------*/

.wave-shape-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}




/* ==========================================================================
  02. TYPOGRAPHY
  =========================================================================== */

/*------------------------------------------*/
/*  HEADERS
/*------------------------------------------*/

h1, h2, h3, h4, h5, h6 {  
  color: #353f4f;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0;
}

.font--jakarta h1,
.font--jakarta h2,
.font--jakarta h3,
.font--jakarta h4,
.font--jakarta h5,
.font--jakarta h6 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  line-height: 1.35;
}

.font--jakarta h1,
.font--jakarta h2 {
  line-height: 1.25;
}

.font--inter h1,
.font--inter h2,
.font--inter h3,
.font--inter h4,
.font--inter h5,
.font--inter h6 {
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.5px;
}

/*------------------------------------------*/
/*  PARAGRAPHS
/*------------------------------------------*/

p.p-sm { font-size: 0.9375rem; }   /* 15px */
p { font-size: 1rem; }             /* 16px */
p.p-md { font-size: 1.0625rem; }   /* 17px */
p.p-lg { font-size: 1.125rem; }    /* 18px */
p.p-xl { font-size: 1.2rem; }      /* 19.2px */

/*------------------------------------------*/
/*  FONT SIZE
/*------------------------------------------*/

.s-15 { font-size: 0.9375rem; }  /* 15px */
.s-16 { font-size: 1rem; }       /* 16px */
.s-17 { font-size: 1.0625rem; }  /* 17px */
.s-18 { font-size: 1.125rem; }   /* 18px */
.s-19 { font-size: 1.1875rem; }  /* 19px */
.s-20 { font-size: 1.25rem; }    /* 20px */
.s-21 { font-size: 1.3125rem; }  /* 21px */
.s-22 { font-size: 1.375rem; }   /* 22px */
.s-23 { font-size: 1.4375rem; }  /* 23px */
.s-24 { font-size: 1.5rem; }     /* 24px */
.s-26 { font-size: 1.625rem; }   /* 26px */
.s-28 { font-size: 1.75rem; }    /* 28px */
.s-30 { font-size: 1.875rem; }   /* 30px */
.s-32 { font-size: 2rem; }       /* 32px */
.s-34 { font-size: 2.125rem; }   /* 34px */
.s-36 { font-size: 2.25rem; }    /* 36px */
.s-38 { font-size: 2.375rem; }   /* 38px */
.s-40 { font-size: 2.5rem; }     /* 40px */
.s-42 { font-size: 2.625rem; }   /* 42px */
.s-43 { font-size: 2.6875rem; }  /* 43px */
.s-44 { font-size: 2.75rem; }    /* 44px */
.s-45 { font-size: 2.8125rem; }  /* 45px */
.s-46 { font-size: 2.875rem; }   /* 46px */
.s-47 { font-size: 2.9375rem; }  /* 47px */
.s-48 { font-size: 3rem; }       /* 48px */
.s-50 { font-size: 3.125rem; }   /* 50px */
.s-52 { font-size: 3.25rem; }    /* 52px */
.s-54 { font-size: 3.375rem; }   /* 54px */
.s-56 { font-size: 3.5rem; }     /* 56px */
.s-58 { font-size: 3.625rem; }   /* 58px */
.s-60 { font-size: 3.75rem; }    /* 60px */
.s-62 { font-size: 3.875rem; }   /* 62px */
.s-64 { font-size: 4rem; }       /* 64px */
.s-66 { font-size: 4.125rem; }   /* 66px */
.s-68 { font-size: 4.25rem; }    /* 68px */
.s-70 { font-size: 4.375rem; }   /* 70px */
.s-72 { font-size: 4.5rem; }     /* 72px */
.s-74 { font-size: 4.625rem; }   /* 74px */
.s-76 { font-size: 4.75rem; }    /* 76px */
.s-78 { font-size: 4.875rem; }   /* 78px */
.s-80 { font-size: 5rem; }       /* 80px */

/*------------------------------------------*/
/*  FONT WEIGHT
/*------------------------------------------*/

.txt-upcase { 
  text-transform: uppercase 
}

.w-200 { font-weight: 200; }
.w-300 { font-weight: 300; }
.w-400 { font-weight: 400; }
.w-500 { font-weight: 500; }
.w-600 { font-weight: 600; }
.w-700 { font-weight: 700; }
.w-800 { font-weight: 800; }
.w-900 { font-weight: 900; }  

/*------------------------------------------*/
/*  LINK SETTINGS
/*------------------------------------------*/

a {
  color: #6c757d;
  text-decoration: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

a:hover {
  color: #353f4f!important;
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
}

a.tra-link {
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
}

a.tra-link span {
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

.tra-link.ico-20 [class*="flaticon-"]:before, 
.tra-link.ico-20 [class*="flaticon-"]:after { 
  position: relative;
  font-size: 0.75rem; 
  line-height: 0.7rem!important;
  top: 2px;
  left: 2px;
}

a.tra-link:hover span {
  color: #353f4f!important;
}

/*------------------------------------------*/
/*  LISTS
/*------------------------------------------*/

ul {
  margin: 0;
  padding: 0;
  list-style: none;
} 

/*------------------------------------------*/
/*  TEXT LIST
/*------------------------------------------*/

ul.simple-list {
  list-style: disc;
  margin-left: 15px;
}

ul.simple-list.long-list p {
  margin-bottom: 8px;
}

/*------------------------------------------*/
/*  DIGIT LIST
/*------------------------------------------*/

ol.digit-list {
  padding: 0;
  margin-left: 15px;
}

ol.digit-list p {
  margin-bottom: 8px;
}

/*------------------------------------------*/
/*  BUTTON SETTINGS
/*------------------------------------------*/

.btn {
  background-color: transparent;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  padding: 0.9rem 1.8rem;
  border: 2px solid transparent;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

.btn.btn-sm {
  font-size: 0.975rem;
  padding: 0.7rem 1.5rem;
}

.btn.ico-30.ico-left,
.btn.ico-30.ico-right {
  padding: 0.37rem 0;
}

/*------------------------------------------*/
/*  Buttons Group
/*------------------------------------------*/

.text-center .btns-group {
  display: inline-block;
}

.btns-group .btn:first-child {
  margin-right: 15px;
}

.btns-group .btn.ico-30.ico-left {
  padding: 0.37rem 0.5rem;
}

.btns-group .btn.ico-30.ico-right {
  padding: 0.37rem 0;
}

/*------------------------------------------*/
/*  Button Icon
/*------------------------------------------*/

.btn.ico-20 [class*="flaticon-"]:before, 
.btn.ico-20 [class*="flaticon-"]:after { 
  position: relative;
  font-size: 1rem; 
  line-height: 0.7rem!important;
  top: 4px;
}

.btn.ico-left span {
  margin-right: 5px;
}

.btn.ico-right span {
  margin-left: 5px;
}

.btn.ico-30.ico-left span {
  position: relative;
  top: 0;
  right: 4px;
}

.btn.ico-30.ico-right span {
  position: relative;
  top: 0;
  left: 4px;
}

.btn.ico-30.ico-left span.ico-30-txt,
.btn.ico-30.ico-right span.ico-30-txt {
  position: relative;
  top: -10px;
  right: 0;
}

.btn.ico-30.ico-right span.ico-30-txt {
  left: 0;
}

/*------------------------------------------*/
/*  Button Text
/*------------------------------------------*/

p.btn-txt {
  margin: 20px 0 0 0;
}

.btn-rating p.btn-txt {
  margin: 0;
}

.star-rating span.rating-txt {
  font-size: 0.9375rem;
}

p.btns-group-txt {
  margin: 14px 0 0 0;
}

.btn-rating {
  margin-top: 18px;
}

.text-center p.btns-group-txt {
  margin-top: 20px;
}

p.btn-txt.ico-15,
p.btns-group-txt.ico-15 {
  padding-left: 5px;
}

.text-center p.btn-txt.ico-15,
.text-center p.btns-group-txt.ico-15 {
  padding-left: 0;
}

p.btn-txt span,
p.btns-group-txt span {
  position: relative;
  top: 0.5px;
  right: 2px;
}

p.btns-group-txt span.txt-data {
  display: block;
  top: 0;
  right: 0;
}

p.btns-group-txt span.txt-data a {
  margin-left: 4px;
  text-decoration: underline;
}

p.btns-group-txt span.txt-data a:hover {
  text-decoration: none;
}

.hero-section .color--white p.btns-group-txt span.txt-data a:hover {
  color: #fff!important;
}

.btn-txt.ico-15 [class*="flaticon-"]:before, 
.btn-txt.ico-15 [class*="flaticon-"]:after,
.btns-group-txt.ico-15 [class*="flaticon-"]:before, 
.btns-group-txt.ico-15 [class*="flaticon-"]:after { font-size: 0.75rem; }

.star-rating.ico-15 [class*="flaticon-"]:before, 
.star-rating.ico-15 [class*="flaticon-"]:after { position: relative; font-size: 1rem; top: 3px; }

/*------------------------------------------*/
/*  TRANSPARENT BUTTON
/*------------------------------------------*/

.btn--tra {
  color: #fff!important;
  border-color: transparent!important;
  background-color: rgba(255, 255, 255, .2)!important;
}

/*------------------------------------------*/
/*  WHITE BUTTON
/*------------------------------------------*/

.btn--transparent {
  color: #353f4f;
  background-color: transparent;
  border-color: transparent;
}

.btn--transparent:hover {
  background-color: transparent!important;
  border-color: transparent!important;
} 

.btn--white,
.hover--white:hover {
  color: #353f4f!important;
  background-color: #fff!important;
  border-color: #fff!important;
}

.btn--tra-white,
.hover--tra-white:hover {
  color: #fff!important;
  background-color: transparent!important;
  border-color: #fff!important;
}

.white-scroll .scroll .hover--tra-white:hover {
  color: #353f4f!important;
  background-color: transparent!important;
  border-color: #353f4f!important;
}

/*------------------------------------------*/
/*  BLACK BUTTON
/*------------------------------------------*/

.btn--black,
.hover--black:hover {
  color: #fff!important;
  background-color: #353f4f!important;
  border-color: #353f4f!important;
}

.btn--tra-black,
.hover--tra-black:hover {
  color: #353f4f!important;
  background-color:transparent!important;
  border-color: #353f4f!important;
}

/*------------------------------------------*/
/*  GREY BUTTON
/*------------------------------------------*/

.btn--tra-grey, 
.hover--tra-grey:hover,
.white-scroll .scroll .hover--tra-grey:hover,
.black-scroll .scroll .hover--tra-grey:hover  {
  color: #353f4f!important;
  background-color: transparent!important;
  border-color: #ccc!important;
}

/*------------------------------------------*/
/*  BLUE BUTTON
/*------------------------------------------*/

.btn--blue-500, .hover--blue-500:hover,
.white-scroll .scroll .hover--blue-500:hover,
.black-scroll .scroll .hover--blue-500:hover {
  color: #fff!important;
  border-color: #185abd!important;
  background-color: #185abd!important;
}

.btn--blue-400, .hover--blue-400:hover,
.white-scroll .scroll .hover--blue-400:hover,
.black-scroll .scroll .hover--blue-400:hover {
  color: #fff!important;
  border-color: #1680fb!important;
  background-color: #1680fb!important;
}

.btn--blue-300, .hover--blue-300:hover,
.white-scroll .scroll .hover--blue-300:hover,
.black-scroll .scroll .hover--blue-300:hover {
  color: #fff!important;
  border-color: #3eb1ff!important;
  background-color: #3eb1ff!important;
}

.btn--blue-200, .hover--blue-200:hover,
.white-scroll .scroll .hover--blue-200:hover,
.black-scroll .scroll .hover--blue-200:hover {
  color: #1680fb!important;
  border-color: #e3ebfe!important;
  background-color: #e3ebfe!important;
}

.btn--blue-100, .hover--blue-100:hover,
.white-scroll .scroll .hover--blue-100:hover,
.black-scroll .scroll .hover--blue-100:hover {
  color: #1680fb!important;
  border-color: #1680fb!important;
  background-color: transparent!important;
}

/*------------------------------------------*/
/*  GREEN BUTTON
/*------------------------------------------*/

.btn--green-500, .hover--green-500:hover,
.white-scroll .scroll .hover--green-500:hover,
.black-scroll .scroll .hover--green-500:hover {
  color: #fff!important;
  border-color: #00a14e!important;
  background-color: #00a14e!important;
}

.btn--green-400, .hover--green-400:hover,
.white-scroll .scroll .hover--green-400:hover,
.black-scroll .scroll .hover--green-400:hover {
  color: #fff!important;
  border-color: #0fbc49!important;
  background-color: #0fbc49!important;
}

.btn--green-300, .hover--green-300:hover,
.white-scroll .scroll .hover--green-300:hover,
.black-scroll .scroll .hover--green-300:hover {
  color: #fff!important;
  border-color: #6fd792!important;
  background-color: #6fd792!important;
}

.btn--green-200, .hover--green-200:hover,
.white-scroll .scroll .hover--green-200:hover,
.black-scroll .scroll .hover--green-200:hover {
  color: #0fbc49!important;
  border-color: #cff2db!important;
  background-color: #cff2db!important;
}

.btn--green-100, .hover--green-100:hover,
.white-scroll .scroll .hover--green-100:hover,
.black-scroll .scroll .hover--green-100:hover {
  color: #0fbc49!important;
  border-color: #0fbc49!important;
  background-color: transparent!important;
}

/*------------------------------------------*/
/*  PINK BUTTON
/*------------------------------------------*/

.btn--pink-500, .hover--pink-500:hover,
.white-scroll .scroll .hover--pink-500:hover,
.black-scroll .scroll .hover--pink-500:hover {
  color: #fff!important;
  border-color: #ff149c!important;
  background-color: #ff149c!important;
}

.btn--pink-400, .hover--pink-400:hover,
.white-scroll .scroll .hover--pink-400:hover,
.black-scroll .scroll .hover--pink-400:hover {
  color: #fff!important;
  border-color: #f74780!important;
  background-color: #f74780!important;
}

.btn--pink-300, .hover--pink-300:hover,
.white-scroll .scroll .hover--pink-300:hover,
.black-scroll .scroll .hover--pink-300:hover {
  color: #fff!important;
  border-color: #fcb5cc!important;
  background-color: #fcb5cc!important;
}

.btn--pink-200, .hover--pink-200:hover,
.white-scroll .scroll .hover--pink-200:hover,
.black-scroll .scroll .hover--pink-200:hover {
  color: #f74780!important;
  border-color: #fee4ec!important;
  background-color: #fee4ec!important;
}

.btn--pink-100, .hover--pink-100:hover,
.white-scroll .scroll .hover--pink-100:hover,
.black-scroll .scroll .hover--pink-100:hover {
  color: #f74780!important;
  border-color: #f74780!important;
  background-color: transparent!important;
}

/*------------------------------------------*/
/*  PURPLE BUTTON
/*------------------------------------------*/

.btn--purple-500, .hover--purple-500:hover,
.white-scroll .scroll .hover--purple-500:hover,
.black-scroll .scroll .hover--purple-500:hover {
  color: #fff!important;
  border-color: #663ae9!important;
  background-color: #663ae9!important;
}

.btn--purple-400, .hover--purple-400:hover,
.white-scroll .scroll .hover--purple-400:hover,
.black-scroll .scroll .hover--purple-400:hover {
  color: #fff!important;
  border-color: #7b5dd6!important;
  background-color: #7b5dd6!important;
}

.btn--purple-300, .hover--purple-300:hover,
.white-scroll .scroll .hover--purple-300:hover,
.black-scroll .scroll .hover--purple-300:hover {
  color: #fff!important;
  border-color: #bdaeea!important;
  background-color: #bdaeea!important;
}

.btn--purple-200, .hover--purple-200:hover,
.white-scroll .scroll .hover--purple-200:hover,
.black-scroll .scroll .hover--purple-200:hover {
  color: #7b5dd6!important;
  border-color: #e5deff!important;
  background-color: #e5deff!important;
}

.btn--purple-100, .hover--purple-100:hover,
.white-scroll .scroll .hover--purple-100:hover,
.black-scroll .scroll .hover--purple-100:hover {
  color: #7b5dd6!important;
  border-color: #7b5dd6!important;
  background-color: transparent!important;
}

/*------------------------------------------*/
/*  RED BUTTON
/*------------------------------------------*/

.btn--red-500, .hover--red-500:hover,
.white-scroll .scroll .hover--red-500:hover,
.black-scroll .scroll .hover--red-500:hover {
  color: #fff!important;
  border-color: #da0f29!important;
  background-color: #da0f29!important;
}

.btn--red-400, .hover--red-400:hover,
.white-scroll .scroll .hover--red-400:hover,
.black-scroll .scroll .hover--red-400:hover {
  color: #fff!important;
  border-color: #ff1e1a!important;
  background-color: #ff1e1a!important;
}

.btn--red-300, .hover--red-300:hover,
.white-scroll .scroll .hover--red-300:hover,
.black-scroll .scroll .hover--red-300:hover {
  color: #fff!important;
  border-color: #ffa5a3!important;
  background-color: #ffa5a3!important;
}

.btn--red-200, .hover--red-200:hover,
.white-scroll .scroll .hover--red-200:hover,
.black-scroll .scroll .hover--red-200:hover {
  color: #ff1e1a!important;
  border-color: #ffd2d1!important;
  background-color: #ffd2d1!important;
}

.btn--red-100, .hover--red-100:hover,
.white-scroll .scroll .hover--red-100:hover,
.black-scroll .scroll .hover--red-100:hover {
  color: #ff1e1a!important;
  border-color: #ff1e1a!important;
  background-color: transparent!important;
}

/*------------------------------------------*/
/*  VIOLET BUTTON
/*------------------------------------------*/

.btn--violet-500, .hover--violet-500:hover,
.white-scroll .scroll .hover--violet-500:hover,
.black-scroll .scroll .hover--violet-500:hover {
  color: #fff!important;
  border-color: #c73e9b!important;
  background-color: #c73e9b!important;
}

.btn--violet-400, .hover--violet-400:hover,
.white-scroll .scroll .hover--violet-400:hover,
.black-scroll .scroll .hover--violet-400:hover {
  color: #fff!important;
  border-color: #be6cbe!important;
  background-color: #be6cbe!important;
}

.btn--violet-300, .hover--violet-300:hover,
.white-scroll .scroll .hover--violet-300:hover,
.black-scroll .scroll .hover--violet-300:hover {
  color: #fff!important;
  border-color: #e2bde2!important;
  background-color: #e2bde2!important;
}

.btn--violet-200, .hover--violet-200:hover,
.white-scroll .scroll .hover--violet-200:hover,
.black-scroll .scroll .hover--violet-200:hover {
  color: #be6cbe!important;
  border-color: #f1cde5!important;
  background-color: #f1cde5!important;
}

.btn--violet-100, .hover--violet-100:hover,
.white-scroll .scroll .hover--violet-100:hover,
.black-scroll .scroll .hover--violet-100:hover {
  color: #be6cbe!important;
  border-color: #be6cbe!important;
  background-color: transparent!important;
}

/*------------------------------------------*/
/*  YELLOW BUTTON
/*------------------------------------------*/

.btn--yellow-500, .hover--yellow-500:hover,
.white-scroll .scroll .hover--yellow-500:hover,
.black-scroll .scroll .hover--yellow-500:hover {
  color: #353f4f!important;
  border-color: #ffba01!important;
  background-color: #ffba01!important;
}

.btn--yellow-400, .hover--yellow-400:hover,
.white-scroll .scroll .hover--yellow-400:hover,
.black-scroll .scroll .hover--yellow-400:hover {
  color: #353f4f!important;
  border-color: #ffbd45!important;
  background-color: #ffbd45!important;
}

.btn--yellow-300, .hover--yellow-300:hover,
.white-scroll .scroll .hover--yellow-300:hover,
.black-scroll .scroll .hover--yellow-300:hover {
  color: #353f4f!important;
  border-color: #ffdf35!important;
  background-color: #ffdf35!important;
}

.btn--yellow-200, .hover--yellow-200:hover,
.white-scroll .scroll .hover--yellow-200:hover,
.black-scroll .scroll .hover--yellow-200:hover {
  color: #353f4f!important;
  border-color: #fef5cd!important;
  background-color: #fef5cd!important;
}

.btn--yellow-100, .hover--yellow-100:hover,
.white-scroll .scroll .hover--yellow-100:hover,
.black-scroll .scroll .hover--yellow-100:hover {
  color: #ffba01!important;
  border-color: #ffba01!important;
  background-color: transparent!important;
}

/*------------------------------------------*/
/*  BUTTON FOCUS
/*------------------------------------------*/

.btn:focus,
.btn.btn--black:focus {
  color: #fff; 
  -webkit-box-shadow: none;
  box-shadow: none; 
}

.btn--transparent:focus {
  background-color: transparent!important;
  border-color: transparent!important;
} 

.btn--grey:focus {
  color: #666; 
  -webkit-box-shadow: none;
  box-shadow: none; 
}

.btn--tra-black:focus, 
.btn--transparent:focus,
.btn.btn--tra-grey:focus {
  color: #353f4f; 
  -webkit-box-shadow: none;
  box-shadow: none; 
}

/*------------------------------------------*/
/*  DOWNLOAD LINKS
/*------------------------------------------*/

.download-links li {
  width: auto!important;
  display: inline-block!important;
  vertical-align: top;
  clear: none !important;
}

.download-links-divider {
  position: relative;
  top: 1.5px;
  transform: rotate(-90deg);
}

.download-links li p {
  display: inline-block;
  float: left;
  margin-bottom: 0;
}

.download-links li p a {
  font-weight: 500;
}

.download-links li p a:hover {
  text-decoration: underline;
}

.color--white .download-links li p a:hover {
  color: #fff!important;
}

/*------------------------------------------*/
/*  OS DOWNLOAD BUTTON
/*------------------------------------------*/ 

.os-btn {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch!important;
  justify-content: flex-start;
  text-align: left;
  width: 330px;
  min-width: 330px;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  padding: 17px 18px 15px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

.os-btn:hover {
  background-color: #f5f6f7;
  border: 1px solid #f5f6f7;
  -webkit-box-shadow: 0px 8px 10px 0px rgba(2, 2, 2, .05);
  -moz-box-shadow: 0px 8px 10px 0px rgba(2, 2, 2, .05);
  box-shadow: 0px 8px 10px 0px rgba(2, 2, 2, .05);
}

.os-btn-ico {
  position: relative;
  margin-right: 20px;
}

.os-btn-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
}

.os-btn-txt p {
  color: #353f4f;
  font-size: 1.05rem;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-top: 6px;
  margin-bottom: 5px;
}

.os-btn-txt span {
  display: block;
  font-size: 0.9rem;
  line-height: 1;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  STORE BADGE ICONS
/*------------------------------------------*/

.stores-badge {
  display: inline-block;
}

.store {
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}

.store:last-child {
  margin-right: 0;
} 

.store img {
  width: auto;
  max-width: inherit;
}

.badge-img-xs .store img { max-height: 2.5rem; }
.badge-img-sm .store img { max-height: 2.625rem; }
.badge-img-md .store img { max-height: 2.875rem; }
.badge-img-lg .store img { max-height: 3rem; }

/*------------------------------------------*/
/*  WATCH VIDEO LINK
/*------------------------------------------*/

.watch-video {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch!important;
  justify-content: flex-start;
  margin-top: 25px;
}

/*------------------------------------------*/
/*  Link Icon
/*------------------------------------------*/

.watch-video-link {
  position: relative;
  text-align: center;
  float: left;
  width: 2.85rem;
  height: 2.85rem; 
  color: #fff;
  border: none;
  margin-right: 12px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; 
}

.watch-video-link:before {
  content: '';
  position: absolute;
  left: -3px;
  right: -3px;
  top: -3px;
  bottom: -3px;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.watch-video-link:hover:before {
  opacity: .75;
  left: -8px;
  right: -8px;
  top: -8px;
  bottom: -8px;
}

.watch-video-link span[class^="flaticon-"]:before, 
.watch-video-link span[class^="flaticon-"]:after {
  font-size: 2rem; 
  line-height: 2.85rem!important;   
  margin-left: 5px;
}

/*------------------------------------------*/
/*  Link Text
/*------------------------------------------*/

.watch-video-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
  text-align: left!important;
}

p.video-txt-lg {
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 5px;
}

p.video-txt-sm {
  font-size: 0.925rem;
  line-height: 1;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  VIDEO POPUP ICON
/*------------------------------------------*/

.video-preview {
  position: relative; 
  text-align: center;
}

.video-btn {
  position: absolute!important;
  top: 50%;
  left: 50%;
  display: inline-block;
  text-align: center;
  color: #fff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; 
}

.video-btn-xl {
  width: 6.25rem;
  height: 6.25rem;
  margin-top: -3.125rem;
  margin-left: -3.125rem;
}

.video-btn-lg {
  width: 5.625rem;
  height: 5.625rem;
  margin-top: -2.8125rem;
  margin-left: -2.8125rem;
}

.video-btn-md {
  width: 5rem;
  height: 5rem;
  margin-top: -2.5rem;
  margin-left: -2.5rem;
}

.video-btn-sm {
  width: 4.375rem;
  height: 4.375rem;
  margin-top: -2.1875rem;
  margin-left: -2.1875rem;
}

.video-block-wrapper {
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.video-btn.video-btn-xl [class^="flaticon-"]:before, 
.video-btn.video-btn-xl [class^="flaticon-"]:after {
  font-size: 5rem;
  line-height: 6.25rem!important;   
  margin-left: 10px;
}

.video-btn.video-btn-lg [class^="flaticon-"]:before, 
.video-btn.video-btn-lg [class^="flaticon-"]:after {
  font-size: 4.5rem;
  line-height: 5.625rem!important;   
  margin-left: 8px;
}

.video-btn.video-btn-md [class^="flaticon-"]:before, 
.video-btn.video-btn-md [class^="flaticon-"]:after {
  font-size: 4.35rem;
  line-height: 5rem!important;   
  margin-left: 8px;
}

.video-btn.video-btn-sm [class^="flaticon-"]:before, 
.video-btn.video-btn-sm [class^="flaticon-"]:after {
  font-size: 3.5rem;
  line-height: 4.375rem!important;   
  margin-left: 5px;
}

.video-btn:hover .video-block-wrapper {
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}

.video-btn:before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.video-btn.video-btn-xl:hover:before {
  opacity: .75;
  left: -1.5rem;
  right: -1.5rem;
  top: -1.5rem;
  bottom: -1.5rem;
}

.video-btn.video-btn-lg:hover:before {
  opacity: .75;
  left: -1.25rem;
  right: -1.25rem;
  top: -1.25rem;
  bottom: -1.25rem;
}

.video-btn.video-btn-md:hover:before {
  opacity: .75;
  left: -1rem;
  right: -1rem;
  top: -1rem;
  bottom: -1rem;
}

.video-btn.video-btn-sm:hover:before {
  opacity: .75;
  left: -0.75rem;
  right: -0.75rem;
  top: -0.75rem;
  bottom: -0.75rem;
}

/*------------------------------------------*/
/*  VECTOR ICONS
/*------------------------------------------*/

.ico-10 [class*="flaticon-"]:before, .ico-10 [class*="flaticon-"]:after { font-size: 0.75rem; }     /* 15px */
.ico-15 [class*="flaticon-"]:before, .ico-15 [class*="flaticon-"]:after { font-size: 0.9375rem; }   /* 15px */
.ico-20 [class*="flaticon-"]:before, .ico-20 [class*="flaticon-"]:after { font-size: 1.25rem; }     /* 20px */
.ico-25 [class*="flaticon-"]:before, .ico-25 [class*="flaticon-"]:after { font-size: 1.5625rem; }   /* 25px */
.ico-30 [class*="flaticon-"]:before, .ico-30 [class*="flaticon-"]:after { font-size: 1.875rem; }    /* 30px */
.ico-35 [class*="flaticon-"]:before, .ico-35 [class*="flaticon-"]:after { font-size: 2.1875rem; }   /* 35px */
.ico-40 [class*="flaticon-"]:before, .ico-40 [class*="flaticon-"]:after { font-size: 2.5rem; }      /* 40px */
.ico-45 [class*="flaticon-"]:before, .ico-45 [class*="flaticon-"]:after { font-size: 2.8125rem; }   /* 45px */
.ico-50 [class*="flaticon-"]:before, .ico-50 [class*="flaticon-"]:after { font-size: 3.125rem; }    /* 50px */
.ico-55 [class*="flaticon-"]:before, .ico-55 [class*="flaticon-"]:after { font-size: 3.4375rem; }   /* 55px */
.ico-60 [class*="flaticon-"]:before, .ico-60 [class*="flaticon-"]:after { font-size: 3.75rem; }     /* 60px */
.ico-65 [class*="flaticon-"]:before, .ico-65 [class*="flaticon-"]:after { font-size: 4.0625rem; }   /* 65px */
.ico-70 [class*="flaticon-"]:before, .ico-70 [class*="flaticon-"]:after { font-size: 4.375rem; }    /* 70px */
.ico-75 [class*="flaticon-"]:before, .ico-75 [class*="flaticon-"]:after { font-size: 4.6875rem; }   /* 75px */
.ico-80 [class*="flaticon-"]:before, .ico-80 [class*="flaticon-"]:after { font-size: 5rem; }        /* 80px */
.ico-85 [class*="flaticon-"]:before, .ico-85 [class*="flaticon-"]:after { font-size: 5.3125rem; }   /* 85px */
.ico-90 [class*="flaticon-"]:before, .ico-90 [class*="flaticon-"]:after { font-size: 5.625rem; }    /* 90px */
.ico-95 [class*="flaticon-"]:before, .ico-95 [class*="flaticon-"]:after { font-size: 5.9375rem; }   /* 95px */
.ico-100 [class*="flaticon-"]:before, .ico-100 [class*="flaticon-"]:after { font-size: 6.25rem; }   /* 100px */
.ico-105 [class*="flaticon-"]:before, .ico-105 [class*="flaticon-"]:after { font-size: 6.5625rem; } /* 105px */
.ico-110 [class*="flaticon-"]:before, .ico-110 [class*="flaticon-"]:after { font-size: 6.875rem; }  /* 110px */
.ico-115 [class*="flaticon-"]:before, .ico-115 [class*="flaticon-"]:after { font-size: 7.1875rem; } /* 115px */
.ico-120 [class*="flaticon-"]:before, .ico-120 [class*="flaticon-"]:after { font-size: 7.5rem; }    /* 120px */
.ico-125 [class*="flaticon-"]:before, .ico-125 [class*="flaticon-"]:after { font-size: 7.8125rem; } /* 125px */

/*------------------------------------------*/
/*  VECTOR ICON BACKGROUND
/*------------------------------------------*/ 

.shape-ico {
  position: relative;
  display: inline-block;
  margin: 0 auto;
}

.shape-ico span {
  position: relative;
  z-index:  2;
}

.shape-ico svg {
  position: absolute;
  z-index:  1;
}

.ico-50 .shape-ico svg {
  width: 130px;
  height: 130px;
  top: -35px;
  left: calc(50% - 60px);
}

.fbox-11 .ico-50 .shape-ico svg {
  top: -40px;
  left: calc(50% - 70px);
}

.ico-55 .shape-ico svg {
  width: 135px;
  height: 135px;
  top: -30px;
  left: calc(50% - 60px);
}

.ico-60 .shape-ico svg {
  width: 140px;
  height: 140px;
  top: -30px;
  left: calc(50% - 65px);
}

.shape-ico.color--blue-300 path,
.shape-ico.color--blue-400 path,
.shape-ico.color--blue-500 path {
  fill: #f4f9fc;
}

.shape-ico.color--green-300 path,
.shape-ico.color--green-400 path,
.shape-ico.color--green-500 path {
  fill: #e9f8f2;
}

.shape-ico.color--pink-300 path,
.shape-ico.color--pink-400 path,
.shape-ico.color--pink-500 path {
  fill: #feecf2;
}

.shape-ico.color--purple-300 path,
.shape-ico.color--purple-400 path,
.shape-ico.color--purple-500 path {
  fill: #f1edff;
}

.shape-ico.color--red-300 path,
.shape-ico.color--red-400 path,
.shape-ico.color--red-500 path {
  fill: #fce7e4;
}

.shape-ico.color--violet-300 path,
.shape-ico.color--violet-400 path,
.shape-ico.color--violet-500 path {
  fill: #f7e4f1;
}

/*------------------------------------------*/
/*  VECTOR ROUNDED ICONS
/*------------------------------------------*/ 

.ico-rounded,
.ico-rounded-md,
.ico-rounded-lg {
  display: inline-block;
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.ico-rounded { width: 80px; height: 80px; }
.ico-rounded-md { width: 90px; height: 90px; }
.ico-rounded-lg { width: 100px; height: 100px; }

.ico-rounded [class*="flaticon-"]:before, 
.ico-rounded [class*="flaticon-"]:after { font-size:2.15rem; line-height:80px!important; }

.ico-rounded-md [class*="flaticon-"]:before, 
.ico-rounded-md [class*="flaticon-"]:after { font-size: 2.4rem; line-height: 90px!important; }

.ico-rounded-lg [class*="flaticon-"]:before, 
.ico-rounded-lg [class*="flaticon-"]:after { font-size: 2.65rem; line-height: 100px!important; }

/*------------------------------------------*/
/*  VECTOR SQUARE ICONS
/*------------------------------------------*/ 

.ico-square { 
  display: inline-block;
  text-align: center;
  width: 80px; 
  height: 80px; 
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  border-radius: 12px;
}

.ico-square [class*="flaticon-"]:before, 
.ico-square [class*="flaticon-"]:after { font-size: 2.4rem; line-height: 80px!important; }

/*------------------------------------------*/
/*   PNG ICONS SETTINGS
/*------------------------------------------*/ 

.ico-145 img { width: 145px; height: 145px; }
.ico-140 img { width: 140px; height: 140px; }
.ico-135 img { width: 135px; height: 135px; }
.ico-130 img { width: 130px; height: 130px; }
.ico-125 img { width: 125px; height: 125px; }
.ico-120 img { width: 120px; height: 120px; }
.ico-115 img { width: 115px; height: 115px; }
.ico-110 img { width: 110px; height: 110px; }
.ico-105 img { width: 105px; height: 105px; }
.ico-100 img { width: 100px; height: 100px; }
.ico-95 img { width: 95px; height: 95px; }
.ico-90 img { width: 90px; height: 90px; }
.ico-85 img { width: 85px; height: 85px; }
.ico-80 img { width: 80px; height: 80px; }
.ico-75 img { width: 75px; height: 75px; }
.ico-70 img { width: 70px; height: 70px; }
.ico-65 img { width: 65px; height: 65px; }
.ico-60 img { width: 60px; height: 60px; }
.ico-55 img { width: 55px; height: 55px; }
.ico-50 img { width: 50px; height: 50px; }
.ico-45 img { width: 45px; height: 45px; }
.ico-40 img { width: 40px; height: 40px; }
.ico-35 img { width: 35px; height: 35px; }
.ico-30 img { width: 30px; height: 30px; }
.ico-25 img { width: 25px; height: 25px; }
.ico-20 img { width: 20px; height: 20px; }

/*------------------------------------------*/
/*   SECTION ID
/*------------------------------------------*/

.section-id {
  display: block;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 35px;   
}

.section-id.rounded-id {
  display: inline-block;
  padding: 10px 24px;
  font-size: 0.85rem;
  text-transform: uppercase;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  margin-bottom: 40px; 
}

.section-id.border-id {
  border-width: 2px;
  border-style: solid;
}

.section-id.border-id.border--grey {
  border-color: #757575;
}

.section-id.border-id.border--blue {
  border-color: #1680fb;
}

.font--jakarta .section-id  {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.font--inter .section-id  {
  font-family: 'Inter', sans-serif;
}

/*------------------------------------------*/
/*  SECTION TITLE
/*------------------------------------------*/

.section-title {
  text-align: center;
}

.section-title h2 {
  margin-bottom: 0;
}

.section-title h2.w-700 {
  letter-spacing: -0.5px;
}

.section-title p.p-md,
.section-title p.p-lg,
.section-title p.p-xl,
.section-title p.s-20,
.section-title p.s-21 {
  margin-top: 18px;
  margin-bottom: 0;
}

.section-title p.p-md,
.section-title p.p-lg {
  padding: 0 3%;
}

.section-title a.contact-link {
  font-weight: 500;
  text-decoration: underline;
}

.section-title .btns-group .btn {
  margin-top: 0;
}

.section-title .advantages,
.section-title .btns-txt,
.section-title .btns-group-txt {
  margin-top: 20px;
}

/*------------------------------------------*/
/*  TEXT COLORS
/*------------------------------------------*/

.color--white,
.color--white h1,
.color--white h2, 
.color--white h3, 
.color--white h4, 
.color--white h5, 
.color--white h6, 
.color--white p, 
.color--white a, 
.color--white li,
.color--white i, 
.color--white span { color: #fff; }

.color--snow,
.color--snow h1, 
.color--snow h2, 
.color--snow h3, 
.color--snow h4, 
.color--snow h5, 
.color--snow h6, 
.color--snow p, 
.color--snow a, 
.color--snow li,
.color--snow i, 
.color--snow span,
.color--white .color--snow { color: #c2c3cb; }

.color--black,
.color--black h1, 
.color--black h2, 
.color--black h3, 
.color--black h4, 
.color--black h5, 
.color--black h6, 
.color--black p, 
.color--black a, 
.color--black li,
.color--black i, 
.color--black span { color: #353f4f; }

.color--coal,
.color--coal h1, 
.color--coal h2, 
.color--coal h3, 
.color--coal h4, 
.color--coal h5, 
.color--coal h6, 
.color--coal p, 
.color--coal a, 
.color--coal li,
.color--coal i, 
.color--coal span { color: #52595d; }

.color--grey,
.color--grey h1, 
.color--grey h2, 
.color--grey h3, 
.color--grey h4, 
.color--grey h5, 
.color--grey h6, 
.color--grey p, 
.color--grey a, 
.color--grey li,
.color--grey i, 
.color--grey span,
.color--white .color--grey { color: #757575; }

.color--blue-500, 
.color--blue-500 h1, 
.color--blue-500 h2, 
.color--blue-500 h3, 
.color--blue-500 h4, 
.color--blue-500 h5, 
.color--blue-500 h6, 
.color--blue-500 p, 
.color--blue-500 a, 
.color--blue-500 li, 
.color--blue-500 i, 
.color--blue-500 span,
.color--white .color--blue-500 { color: #185abd!important; }

.color--blue-400, 
.color--blue-400 h1, 
.color--blue-400 h2, 
.color--blue-400 h3, 
.color--blue-400 h4, 
.color--blue-400 h5, 
.color--blue-400 h6, 
.color--blue-400 p, 
.color--blue-400 a, 
.color--blue-400 li, 
.color--blue-400 i, 
.color--blue-400 span,
.color--white .color--blue-400 { color: #1680fb!important; }

.color--blue-300, 
.color--blue-300 h1, 
.color--blue-300 h2, 
.color--blue-300 h3, 
.color--blue-300 h4, 
.color--blue-300 h5, 
.color--blue-300 h6, 
.color--blue-300 p, 
.color--blue-300 a, 
.color--blue-300 li, 
.color--blue-300 i, 
.color--blue-300 span,
.color--white .color--blue-300 { color: #3eb1ff!important; }

.color--green-500, 
.color--green-500 h1, 
.color--green-500 h2, 
.color--green-500 h3, 
.color--green-500 h4, 
.color--green-500 h5, 
.color--green-500 h6, 
.color--green-500 p, 
.color--green-500 a, 
.color--green-500 li, 
.color--green-500 i, 
.color--green-500 span,
.color--white .color--green-500 { color: #00a14e!important; }

.color--green-400, 
.color--green-400 h1, 
.color--green-400 h2, 
.color--green-400 h3, 
.color--green-400 h4, 
.color--green-400 h5, 
.color--green-400 h6, 
.color--green-400 p, 
.color--green-400 a, 
.color--green-400 li, 
.color--green-400 i, 
.color--green-400 span,
.color--white .color--green-400 { color: #0fbc49!important; }

.color--green-300, 
.color--green-300 h1, 
.color--green-300 h2, 
.color--green-300 h3, 
.color--green-300 h4, 
.color--green-300 h5, 
.color--green-300 h6, 
.color--green-300 p, 
.color--green-300 a, 
.color--green-300 li, 
.color--green-300 i, 
.color--green-300 span,
.color--white .color--green-300 { color: #6fd792!important; }

.color--pink-500, 
.color--pink-500 h1, 
.color--pink-500 h2, 
.color--pink-500 h3, 
.color--pink-500 h4, 
.color--pink-500 h5, 
.color--pink-500 h6, 
.color--pink-500 p, 
.color--pink-500 a, 
.color--pink-500 li, 
.color--pink-500 i, 
.color--pink-500 span,
.color--white .color--pink-500 { color: #ff149c!important; }

.color--pink-400, 
.color--pink-400 h1, 
.color--pink-400 h2, 
.color--pink-400 h3, 
.color--pink-400 h4, 
.color--pink-400 h5, 
.color--pink-400 h6, 
.color--pink-400 p, 
.color--pink-400 a, 
.color--pink-400 li, 
.color--pink-400 i, 
.color--pink-400 span,
.color--white .color--pink-400 { color: #f74780!important; }

.color--pink-300, 
.color--pink-300 h1, 
.color--pink-300 h2, 
.color--pink-300 h3, 
.color--pink-300 h4, 
.color--pink-300 h5, 
.color--pink-300 h6, 
.color--pink-300 p, 
.color--pink-300 a, 
.color--pink-300 li, 
.color--pink-300 i, 
.color--pink-300 span,
.color--white .color--pink-300 { color: #fcb5cc!important; }

.color--purple-500, 
.color--purple-500 h1, 
.color--purple-500 h2, 
.color--purple-500 h3, 
.color--purple-500 h4, 
.color--purple-500 h5, 
.color--purple-500 h6, 
.color--purple-500 p, 
.color--purple-500 a, 
.color--purple-500 li, 
.color--purple-500 i, 
.color--purple-500 span,
.color--white .color--purple-500 { color: #663ae9!important; }

.color--purple-400, 
.color--purple-400 h1, 
.color--purple-400 h2, 
.color--purple-400 h3, 
.color--purple-400 h4, 
.color--purple-400 h5, 
.color--purple-400 h6, 
.color--purple-400 p, 
.color--purple-400 a, 
.color--purple-400 li, 
.color--purple-400 i, 
.color--purple-400 span,
.color--white .color--purple-400 { color: #7b5dd6!important; }

.color--purple-300, 
.color--purple-300 h1, 
.color--purple-300 h2, 
.color--purple-300 h3, 
.color--purple-300 h4, 
.color--purple-300 h5, 
.color--purple-300 h6, 
.color--purple-300 p, 
.color--purple-300 a, 
.color--purple-300 li, 
.color--purple-300 i, 
.color--purple-300 span,
.color--white .color--purple-300 { color: #bdaeea!important; }

.color--red-500, 
.color--red-500 h1, 
.color--red-500 h2, 
.color--red-500 h3, 
.color--red-500 h4, 
.color--red-500 h5, 
.color--red-500 h6, 
.color--red-500 p, 
.color--red-500 a, 
.color--red-500 li, 
.color--red-500 i, 
.color--red-500 span,
.color--white .color--red-500 { color: #da0f29!important; }

.color--red-400, 
.color--red-400 h1, 
.color--red-400 h2, 
.color--red-400 h3, 
.color--red-400 h4, 
.color--red-400 h5, 
.color--red-400 h6, 
.color--red-400 p, 
.color--red-400 a, 
.color--red-400 li, 
.color--red-400 i, 
.color--red-400 span,
.color--white .color--red-400 { color: #ff1e1a!important; }

.color--red-300, 
.color--red-300 h1, 
.color--red-300 h2, 
.color--red-300 h3, 
.color--red-300 h4, 
.color--red-300 h5, 
.color--red-300 h6, 
.color--red-300 p, 
.color--red-300 a, 
.color--red-300 li, 
.color--red-300 i, 
.color--red-300 span,
.color--white .color--red-300 { color: #ffa5a3!important; }

.color--violet-500, 
.color--violet-500 h1, 
.color--violet-500 h2, 
.color--violet-500 h3, 
.color--violet-500 h4, 
.color--violet-500 h5, 
.color--violet-500 h6, 
.color--violet-500 p, 
.color--violet-500 a, 
.color--violet-500 li, 
.color--violet-500 i, 
.color--violet-500 span,
.color--white .color--violet-500 { color: #c73e9b!important; }

.color--violet-400, 
.color--violet-400 h1, 
.color--violet-400 h2, 
.color--violet-400 h3, 
.color--violet-400 h4, 
.color--violet-400 h5, 
.color--violet-400 h6, 
.color--violet-400 p, 
.color--violet-400 a, 
.color--violet-400 li, 
.color--violet-400 i, 
.color--violet-400 span,
.color--white .color--violet-400 { color: #be6cbe!important; }

.color--violet-300, 
.color--violet-300 h1, 
.color--violet-300 h2, 
.color--violet-300 h3, 
.color--violet-300 h4, 
.color--violet-300 h5, 
.color--violet-300 h6, 
.color--violet-300 p, 
.color--violet-300 a, 
.color--violet-300 li, 
.color--violet-300 i, 
.color--violet-300 span,
.color--white .color--violet-300 { color: #e2bde2!important; }

.color--yellow-500, 
.color--yellow-500 h1, 
.color--yellow-500 h2, 
.color--yellow-500 h3, 
.color--yellow-500 h4, 
.color--yellow-500 h5, 
.color--yellow-500 h6, 
.color--yellow-500 p, 
.color--yellow-500 a, 
.color--yellow-500 li, 
.color--yellow-500 i, 
.color--yellow-500 span,
.color--white .color--yellow-500 { color: #ffba01!important; }

.color--yellow-400, 
.color--yellow-400 h1, 
.color--yellow-400 h2, 
.color--yellow-400 h3, 
.color--yellow-400 h4, 
.color--yellow-400 h5, 
.color--yellow-400 h6, 
.color--yellow-400 p, 
.color--yellow-400 a, 
.color--yellow-400 li, 
.color--yellow-400 i, 
.color--yellow-400 span,
.color--white .color--yellow-400 { color: #ffbd45!important; }

.color--yellow-300, 
.color--yellow-300 h1, 
.color--yellow-300 h2, 
.color--yellow-300 h3, 
.color--yellow-300 h4, 
.color--yellow-300 h5, 
.color--yellow-300 h6, 
.color--yellow-300 p, 
.color--yellow-300 a, 
.color--yellow-300 li, 
.color--yellow-300 i, 
.color--yellow-300 span,
.color--white .color--yellow-300 { color: #ffdf35!important; }




/* ==========================================================================
  03. PRELOAD ANIMATION
  ========================================================================== */

#loading {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  z-index: 99999999;
}

#loading { 
  background-color: #f5f5f9;
}

#loading-center {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100px;
  width: 100px;
  margin-top: -50px;
  margin-left: -50px;
  -webkit-animation: loading-center-absolute 1s infinite;
  animation: loading-center-absolute 1s infinite;
}

.loader {
  border: 2px solid;
  border-color: transparent #888;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loading--blue .loader { 
  border-color: transparent #1680fb; 
}

.loading--green .loader { 
  border-color: transparent #0fbc49; 
}

.loading--pink .loader { 
  border-color: transparent #f74780; 
}

.loading--purple .loader { 
  border-color: transparent #7b5dd6; 
}

.loading--red .loader { 
  border-color: transparent #ff1e1a; 
}

.loading--violet .loader { 
  border-color: transparent #c73e9b; 
}

.loading--yellow .loader { 
  border-color: transparent #ffba01; 
}

.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  border: 50px solid;
  border-color: transparent rgba(30, 30, 30, 0.15);
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.loading--blue .loader::after {
  border-color: transparent rgba(22, 128, 251, .15); 
}

.loading--green .loader::after {
  border-color: transparent rgba(14, 188, 73, .15); 
}

.loading--pink .loader::after {
  border-color: transparent rgba(247, 71, 128, .15); 
}

.loading--purple .loader::after {
  border-color: transparent rgba(123, 93, 214, .15); 
}

.loading--red .loader::after {
  border-color: transparent rgba(255, 30, 26, .15); 
}

.loading--violet .loader::after {
  border-color: transparent rgba(1900, 108, 190, .15); 
}

.loading--yellow .loader::after {
  border-color: transparent rgba(255, 186, 1, .15); 
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 




/* ==========================================================================
  04. HEADER & NAVIGATION
  =========================================================================== */

#header {
  width: 100%;
  display: block;
  padding-top: 0px;
}

.header-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.headerwp {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0;
}

.posrlt {
  position: relative;
}

/*------------------------------------------*/
/*  HEADER LOGO
/*------------------------------------------*/

.desktoplogo img {
  width: auto;
  max-width: inherit;
  max-height: 38px;
}

.blog-header .desktoplogo img {
  max-height: 30px;
}

/*------------------------------------------*/
/*  NAVIGATION MENU
/*------------------------------------------*/

.wsmainfull {
  width: 100%;
  height: auto;
  z-index: 1031;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out; 
}

.tra-menu .wsmainfull {
  padding: 20px 0;
  background-color: transparent!important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.white-menu .wsmainfull {
  padding: 6px 0;
  background-color: #fff!important;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
}

.dark-menu .wsmainfull {
  padding: 6px 0;
  background-color: #1d222f!important;
  -webkit-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  -moz-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
}

#header.hiddden-header {
  display: none;
}

.hidden-nav .wsmainfull {
  margin-top: -140px;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  HEADER LINK
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > a {
  font-family: 'Plus Jakarta Sans', sans-serif;
  display: block;
  font-size: 1rem;
  line-height: 50px;
  font-weight: 600;
  letter-spacing: 0;
  text-decoration: none;  
  margin: 0 7px;
  padding: 10px 15px;
}

.navbar-dark .wsmenu > .wsmenu-list > li > a.h-link {
  color: #353f4f;
}

.navbar-light .wsmenu > .wsmenu-list > li > a.h-link {
  color: #fff;
}

.wsmenu > .wsmenu-list > li > a.h-link.last-link {
  padding: 10px 0px;
  margin: 0;
}

.wsmenu > .wsmenu-list > li > a .wsarrow:after {
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-top: 4px solid;
  content: "";
  float: right;
  right: 0;
  height: 0;
  margin: 0 0 0 14px;
  position: absolute;
  text-align: right;
  top: 35px;
  width: 0;
}

.wsmenu > .wsmenu-list > li.reg-fst-link > a {
  margin-left: 30px;
}

/*------------------------------------------*/
/*  HEADER LINK INFO
/*------------------------------------------*/

span.sm-info {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 0.65rem;
  line-height: 19px;
  font-weight: 700;
  margin-left: 5px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
}

.nav-blue-500 .sm-info { background-color: #185abd; }
.nav-blue-400 .sm-info { background-color: #1680fb; }
.nav-blue-300 .sm-info { background-color: #3eb1ff; }

.nav-green-500 .sm-info { background-color: #00a14e; }
.nav-green-400 .sm-info { background-color: #0fbc49; }
.nav-green-300 .sm-info { background-color: #6fd792; }

.nav-pink-500 .sm-info { background-color: #ff149c; }
.nav-pink-400 .sm-info { background-color: #f74780; }
.nav-pink-300 .sm-info { background-color: #fcb5cc; }

.nav-purple-500 .sm-info { background-color: #663ae9; }
.nav-purple-400 .sm-info { background-color: #7b5dd6; }
.nav-purple-300 .sm-info { background-color: #bdaeea; }

.nav-red-500 .sm-info { background-color: #da0f29; }
.nav-red-400 .sm-info { background-color: #ff1e1a; }
.nav-red-300 .sm-info { background-color: #ffa5a3; }

.nav-violet-500 .sm-info { background-color: #c73e9b; }
.nav-violet-400 .sm-info { background-color: #be6cbe; }
.nav-violet-300 .sm-info { background-color: #e2bde2; }

.nav-yellow-500 .sm-info { background-color: #ffba01; }
.nav-yellow-400 .sm-info { background-color: #ffbd45; }
.nav-yellow-300 .sm-info { background-color: #ffdf35; }

/*------------------------------------------*/
/*  HEADER BUTTONS
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li a.btn {
  font-size: 1rem;
  line-height: 30px;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0;
  margin-top: 14px;
  padding: 4px 28px;
}

.wsmenu > .wsmenu-list > li a.btn.reg-btn {
  padding: 4px 22px;
}

.wsmenu > .wsmenu-list > li a.btn.fst-btn-link {
  margin-left: 12px;
}

.wsmenu > .wsmenu-list > li a.store.header-store {
  line-height: 1;
  margin-top: 13px;
  margin-left: 10px;
  margin-bottom: 0;
  padding: 0;
}

.store.header-store img {
  max-height: 44px;
}

/*------------------------------------------*/
/*  HEADER SOCIAL LINKS  
/*------------------------------------------*/

.header-socials {
  margin: 0;
  display: inline-block; 
  text-align: center;
}

.header-socials span {
  float: left;
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0;
}

.header-socials a {
  display: block; 
  margin-left: 18px;
}

.navbar-light .header-socials a {
  color: #ddd;
}

.navbar-light .header-socials a:hover {
  color: #ccc!important;
}

.header-socials.ico-20 [class*="flaticon-"]:before, 
.header-socials.ico-20 [class*="flaticon-"]:after { line-height: 70px!important; } 

/*------------------------------------------*/
/*  HEADER SUBMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > ul.sub-menu {
  width: auto;
  min-width: 200px;
  top: 62px;
  padding: 12px 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
  background-color: rgba(244, 244, 249, 0);
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #353f4f;
  font-size: 1rem;
  font-weight: 600;
  padding: 11px 14px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover {
  background-color: rgba(244, 244, 249, 1);
  padding: 11px 14px;
}

/*------------------------------------------*/
/*  HALFMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > .wsmegamenu {
  top: 62px;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
  width: 38%;
  padding: 12px 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu { 
  right: 2%; 
}

.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li {
  border-bottom: none;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
  font-family: 'Plus Jakarta Sans', sans-serif;
  background-color: rgba(244, 244, 249, 0);
  color: #353f4f;
  font-size: 1rem;
  font-weight: 600;
  padding: 11px 14px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  background-color: rgba(244, 244, 249, 1);
  padding: 11px 14px;
}

/*------------------------------------------*/
/*  MEGAMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > .wsmegamenu {
  padding: 12px 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 {
  width: 76%!important;
  left: 23%;
  padding: 20px 10px;
}

.wsmegamenu.w-75 .col-lg-3 {
  padding: 0 6px;
}

/*------------------------------------------*/
/*  SUBMENU TITLE
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > .wsmegamenu .title,
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu .title {
  color: #929394;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0;
  margin: 10px 0 15px 13px;
}

/*------------------------------------------*/
/*  DARK HEADER SUBMENU / MEGAMENU
/*------------------------------------------*/

.dark-menu .wsmenu > .wsmenu-list > li > ul.sub-menu,
.dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu,
.dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > ul.sub-menu,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
  background-color: #2c3142!important; 
  border: solid 1px #2c3142!important; 
  -webkit-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  -moz-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
}

.dark-menu .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
.dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
.dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
  color: #ddd;
  background-color: rgba(255, 255, 255, 0)!important;
}

.dark-menu .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
.dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  background-color: rgba(255, 255, 255, .05)!important;
}

.dark-menu .title, .black-scroll .scroll .title,
.dark-menu .icons-menu-txt span, .black-scroll .scroll .icons-menu-txt span {
  color: #fff!important;
}

/*------------------------------------------*/
/*  ICONS MENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > ul.sub-menu.icons-menu {
  max-width: 275px;
  min-width: 275px;
  padding: 12px;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu {
  width: 52%;
  padding: 12px 6px;
}

.wsmegamenu.halfmenu.icons-menu .col-lg-6 {
  padding: 0 6px;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 {
  width: 82%!important;
  left: 17%;
  padding: 12px 6px;
}

.wsmegamenu.icons-menu.w-75 .col-lg-4 {
  padding: 0 6px;
}

.icons-menu .icons-menu-item {
  display: flex!important;
  position: relative!important;
  flex-flow: row wrap!important;
  align-items: stretch!important;
  justify-content: flex-start!important;
}

.icons-menu-ico {
  position: relative;
  margin-right: 14px;
}

.wsmegamenu.ico-30 .icons-menu-ico span {
  position: relative;
  top: 6px!important;
}

.icons-menu-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
}

.icons-menu-txt h6 {
  color: inherit;
  font-size: 1rem;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 0;
}

.icons-menu-txt span {
  display: block;
  color: #898989;
  font-size: 0.925rem;
  font-weight: 400;
  margin-bottom: 0;
}

.sub-menu.ico-30 .icons-menu-txt h6 {
  line-height: 30px;
}

/*------------------------------------------*/
/*  NAVBAR HOVER
/*------------------------------------------*/

.navbar-light .wsmenu-list > li > a.h-link:hover {
  color: #eee!important;
}

.navbar-dark .wsmenu-list > li > a.h-link:hover {
  color: #1d222f!important;
}

.wsmenu > .wsmenu-list.nav-blue-500 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-blue-500 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-blue-500 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #185abd!important;
}

.wsmenu > .wsmenu-list.nav-blue-400 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-blue-400 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-blue-400 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #1680fb!important;
}

.wsmenu > .wsmenu-list.nav-blue-300 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-blue-300 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-blue-300 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #3eb1ff!important;
}

.wsmenu > .wsmenu-list.nav-green-500 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-green-500 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-green-500 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #00a14e!important;
}

.wsmenu > .wsmenu-list.nav-green-400 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-green-400 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-green-400 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #0fbc49!important;
}

.wsmenu > .wsmenu-list.nav-green-300 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-green-300 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-green-300 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #6fd792!important;
}

.wsmenu > .wsmenu-list.nav-pink-500 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-pink-500 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-pink-500 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #ff149c!important;
}

.wsmenu > .wsmenu-list.nav-pink-400 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-pink-400 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-pink-400 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #f74780!important;
}

.wsmenu > .wsmenu-list.nav-pink-300 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-pink-300 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-pink-300 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #fcb5cc!important;
}

.wsmenu > .wsmenu-list.nav-purple-500 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-purple-500 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-purple-500 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #663ae9!important;
}

.wsmenu > .wsmenu-list.nav-purple-400 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-purple-400 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-purple-400 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #7b5dd6!important;
}

.wsmenu > .wsmenu-list.nav-purple-300 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-purple-300 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-purple-300 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #bdaeea!important;
}

.wsmenu > .wsmenu-list.nav-red-500 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-red-500 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-red-500 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #da0f29!important;
}

.wsmenu > .wsmenu-list.nav-red-400 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-red-400 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-red-400 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #ff1e1a!important;
}

.wsmenu > .wsmenu-list.nav-red-300 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-red-300 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-red-300 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #ffa5a3!important;
}

.wsmenu > .wsmenu-list.nav-violet-500 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-violet-500 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-violet-500 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #c73e9b!important;
}

.wsmenu > .wsmenu-list.nav-violet-400 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-violet-400 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-violet-400 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #be6cbe!important;
}

.wsmenu > .wsmenu-list.nav-violet-300 > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-violet-300 > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-violet-300 > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #e2bde2!important;
}

/*------------------------------------------*/
/*  HIDDEN NAVBAR SCROLL
/*------------------------------------------*/

.hidden-nav .wsmainfull.scroll {
  margin-top: 0;
}

/*------------------------------------------*/
/*  NAVBAR WHITE SCROLL
/*------------------------------------------*/

.tra-menu.white-scroll .wsmainfull.scroll,
.white-menu.white-scroll .wsmainfull.scroll {
  padding: 0;
  background-color: rgba(255, 255, 255, .7)!important;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
}

.tra-menu.navbar-dark.white-scroll .scroll .wsmenu > .wsmenu-list > li > a.h-link, 
.tra-menu.navbar-light.white-scroll .scroll .wsmenu > .wsmenu-list > li > a.h-link {
  color: #353f4f;
}

.navbar-light.white-scroll .scroll .wsmenu-list > li > a.h-link:hover {
  color: #1d222f!important;
}

/*------------------------------------------*/
/*  NAVBAR BLACK SCROLL
/*------------------------------------------*/

.tra-menu.black-scroll .wsmainfull.scroll,
.dark-menu.black-scroll .wsmainfull.scroll {
  padding: 0;
  background-color: #1d222f!important;
  -webkit-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  -moz-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
}

.tra-menu.navbar-dark.black-scroll .scroll .wsmenu > .wsmenu-list > li > a.h-link {
  color: #fff;
}

.tra-menu.navbar-dark.black-scroll .scroll .wsmenu > .wsmenu-list > li > a.h-link:hover {
  color: #ccc!important;
}

/*------------------------------------------*/
/*  WHITE SCROLL BUTTONS
/*------------------------------------------*/

.white-scroll .scroll .btn--tra-white {
  color: #353f4f!important;
  border-color: #353f4f!important;
}

/*------------------------------------------*/
/*  BLACK SCROLL BUTTONS
/*------------------------------------------*/

.black-scroll .scroll .btn--tra-black {
  color: #fff!important;
  border-color: #fff!important;
}

/*------------------------------------------*/
/*  LOGO IMAGE
/*------------------------------------------*/

.logo-white, 
.logo-black {
  display: block;
}

.navbar-light .logo-black,
.navbar-dark .logo-white,
.tra-menu.navbar-light .scroll .logo-white,
.tra-menu.navbar-dark.black-scroll .scroll .logo-black {
  display: none;
}

.tra-menu.navbar-light .scroll .logo-black,
.tra-menu.navbar-dark.black-scroll .scroll .logo-white {
  display: block;
}




/* ==========================================================================
  05. HERO
  ========================================================================== */

.hero-section,
.hero-section .container {
  position: relative;
  z-index: 3;
}

.hero-section.bg--img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
}

.hero-section.blur--purple:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 100%;
  height: 100%;
  top: -30%;
  left: 0;
  background-image: url(../images/blur-purple.png);
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
  background-attachment: scroll;
  opacity: 0.2;
}

/*------------------------------------------*/
/*  HERO LOGO
/*------------------------------------------*/

.hero-logo {
  margin-bottom: 40px;
}

.hero-square-logo {
  margin-bottom: 20px;
}

#hero-22 .hero-logo {
  margin-bottom: 30px;
}

.hero-logo img {
  width: auto;
  max-width: inherit;
  max-height: 36px;
}

.hero-square-logo img {
  width: auto;
  max-width: inherit;
  max-height: 90px;
}

/*------------------------------------------*/
/*  HERO APP LOGO
/*------------------------------------------*/

.hero-app-logo {
  margin-bottom: 35px;
}

#hero-25 .hero-app-logo {
  margin-bottom: 25px;
}

.hero-app-logo img {
  width: auto;
  max-width: inherit;
  max-height: 100px;
}

#hero-25 .hero-app-logo img {
  max-height: 110px;
}

/*------------------------------------------*/
/*  HERO DIGITS
/*------------------------------------------*/

#hd-1-1, #hd-1-2 {
  display: inline-block;
  float: left;
  padding-right: 20px;
} 

#hd-1-1 { width: 45%; }
#hd-1-2 { width: 55%; }

.hero-digits-block {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch!important;
  justify-content: flex-start;
}

.hero-digits .block-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
  padding-left: 12px;
}

.hero-digits h2.statistic-number {
  line-height: 1;
  margin-bottom: 0;
} 

.hero-digits h2.statistic-number span {
  font-size: 1.85rem;
}

.hero-digits .block-txt p.p-sm {
  line-height: 1.35;
  margin-top: 5px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  HERO TYPOGRAPHY
/*------------------------------------------*/

.hero-section h2 {
  margin-bottom: 28px;
}

.hero-section h2.s-48,
.hero-section h2.s-50 {
  margin-bottom: 40px;
}

.hero-section h2.s-62 {
  margin-bottom: 24px;
}

.hero-section p.p-sm { 
  padding: 0!important; 
}

.hero-section p.p-md {
  margin-bottom: 30px;
}

.hero-section p.p-lg,
.hero-section p.p-xl {
  margin-bottom: 32px;
}

.hero-section p.s-20,
.hero-section p.s-22,
.hero-section p.s-24 {
  margin-bottom: 36px;
}

.hero-section p.p-lg span,
.hero-section p.p-xl span {
  font-weight: 600;
  letter-spacing: -0.35px;
}

/*------------------------------------------*/
/*  HERO-1
/*------------------------------------------*/

#hero-1 {
  background-image: url(../images/hero-1.jpg);
  padding-top: 130px;
  padding-bottom: 100px;
}

.hero-1-txt p {
  padding-right: 2%;
}

/*------------------------------------------*/
/*  HERO-2
/*------------------------------------------*/

#hero-2 {
  background-image: url(../images/hero-2.jpg);
  padding-top: 150px;
  padding-bottom: 100px;
}

.hero-2-txt p {
  padding-right: 3%;
}

.hero-2-img {
  text-align: center;
  padding-right: 25px;
}

/*------------------------------------------*/
/*  HERO-3
/*------------------------------------------*/

#hero-3 {
  position: relative;
  padding-top: 160px;
  background-image: url(../images/hero-3.jpg);
}

#hero-3:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 100%;
  height: 5%;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.hero-3-txt {
  margin-top: -70px;
}

.hero-3-txt p.p-lg {
  padding-right: 2%;
}

.hero-3-img {
  position: relative;
  text-align: center;
  margin: 0 -90% 0 -60px;
}

/*------------------------------------------*/
/*  HERO-4
/*------------------------------------------*/

#hero-4 {
  overflow: hidden; 
  background-image: url(../images/hero-4.jpg);
  padding-top: 180px;
}

#hero-4:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 100%;
  height: 10%;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.hero-4-txt h2 {
  padding: 0 3%;
}

.hero-4-txt p { 
  padding: 0 5%; 
}

.hero-4-txt p.btns-group-txt {
  margin-top: 25px;
}

.hero-4-img {
  margin-top: 60px;
}

/*------------------------------------------*/
/*  HERO-5
/*------------------------------------------*/

#hero-5 .wave-shape-bottom path {
  fill: #fff;
}

#hero-5 {
  background-image: url(../images/hero-5.jpg);
  padding-top: 140px;
  padding-bottom: 50px;
}

.hero-5-txt h2 {
  line-height: 1.3;
  margin-bottom: 25px;
}

.hero-5-txt p.p-lg {
  padding-right: 10%;
  margin-bottom: 30px;
}

.hero-5-img {
  margin: 0 0 -50px;
}

/*------------------------------------------*/
/*  HERO-6
/*------------------------------------------*/

#hero-6 {
  padding-top: 130px;
}

.hero-6-wrapper {
  overflow: hidden;
  background-image: linear-gradient(90deg, #f6f4fd, #f4f5fd);
  padding: 75px 80px;
}

.hero-6-img {
  position: relative;
  text-align: center;
}

/*------------------------------------------*/
/*  HERO-7
/*------------------------------------------*/

#hero-7 {
  padding-top: 140px;
  padding-bottom: 100px;
}

#hero-7 .quick-form {
  margin-right: 4%;
}

.hero-7-img {
  padding-left: 30px;
}

/*------------------------------------------*/
/*  HERO-8
/*------------------------------------------*/

#hero-8 .wave-shape-bottom path {
  fill: #fff;
}

#hero-8 {
  position: relative;
  background-image: url(../images/hero-8.jpg);
  padding-top: 170px;
  padding-bottom: 30px;
}

.hero-8-txt {
  margin-top: -70px;
}

.hero-8-txt p {
  padding-right: 10%;
}

.hero-8-txt .hero-digits {
  padding-right: 15%;
}

#hero-8-form {
  position: relative;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #f8f8f8;
  padding: 45px 35px;
  -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, .08);
  -moz-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, .08);
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, .08);
}

#hero-8-form h4 {
  line-height: 1.4;
  margin-bottom: 20px;
}

#hero-8-form p {
  color: #757575;
  margin-bottom: 25px;
}

#hero-8-form p.p-sm {
  text-align: center;
  padding: 0 5%!important;
  margin-top: 5px;
  margin-bottom: 0;
}

#hero-8-form p a {
  color: #1e90ff;
  text-decoration: underline;
}

/*------------------------------------------*/
/*  HERO-9
/*------------------------------------------*/

#hero-9 {
  padding-top: 180px;
}

.hero-9-txt h2 {
  line-height: 1;
}

.hero-9-txt h2 img {
  position: relative;
  width: auto;
  max-width: inherit;
  max-height: 100px;
  margin: 0 3px;
  top: -8px;
}

.hero-9-txt p {
  padding: 0 5%;
  margin-top: 40px;
}

#hero-9 .quick-form {
  margin-left: 7%;
  margin-right: 7%;
}

.hero-9-img {
  margin-top: 60px;
}

/*------------------------------------------*/
/*  HERO-10
/*------------------------------------------*/

#hero-10 {
  position: relative;
  background-image: url(../images/hero-10.jpg);
  padding-top: 170px;
  padding-bottom: 100px;
}

.hero-10-txt h4 {
  line-height: 1.45;
  font-weight: 300;
  padding-right: 5%;
  margin-bottom: 35px;
}

.hero-10-img {
  position: relative;
  text-align: center;
  margin: 0 -95% 0 15px;
}

/*------------------------------------------*/
/*  HERO-11
/*------------------------------------------*/

#hero-11 {
  overflow: hidden;
  background-image: url(../images/hero-11.jpg);
  padding-top: 50px;
}

.hero-11-txt {
  margin-top: 40px;
}

.hero-11-txt p {
  padding-right: 5%;
}

.hero-11-img {
  position: relative;
  margin: -60px -12% -100px -15px;
}

/*------------------------------------------*/
/*  HERO-12
/*------------------------------------------*/

#hero-12 {
  overflow: hidden; 
  background-image: url(../images/hero-12.jpg);
  padding-top: 180px;
}

.hero-12-txt h2 {
  padding: 0 3%;
}

.hero-12-txt p { 
  padding: 0 5%; 
}

.hero-12-txt p.btns-group-txt {
  margin-top: 25px;
}

.hero-12-img {
  margin-top: 60px;
  margin-bottom: -100px;
}

.hero-12-img .video-btn {
  top: calc(50% - 30px);
}

/*------------------------------------------*/
/*  HERO-13
/*------------------------------------------*/

#hero-13 {
  /* background-image: linear-gradient(180deg, #f1edff, #f1edff); */
  padding-top: 130px;
  padding-bottom: 80px;
}

#hero-13:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 43%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  -moz-box-shadow: 0 0 55px 35px #fff;
  -webkit-box-shadow: 0 0 35px 35px #fff;
  box-shadow: 0 0 35px 35px #fff;
}

.hero-13-txt .section-id {
  font-weight: 600;
  letter-spacing: 1.5px;
}

.hero-13-txt p {
  padding-right: 5%;
}

.hero-13-img {
  text-align: center;
}

/*------------------------------------------*/
/*  HERO-14
/*------------------------------------------*/

#hero-14 .wave-shape-bottom path {
  fill: #fff;
}

#hero-14 {
  background-image: url(../images/hero-14.jpg);
  padding-top: 170px;
} 

.hero-14-txt p.s-21 {
  font-size: 1.3125rem;
  margin-bottom: 40px;
}

#hero-14 .quick-form {
  margin-left: 6%;
  margin-right: 6%;
}

.hero-14-txt .btn-txt {
  margin-top: 40px;
}

.hero-14-img {
  margin-top: 70px;
}

/*------------------------------------------*/
/*  HERO-15
/*------------------------------------------*/

#hero-15 {
  position: relative;
  z-index: 3;
  background-image: url(../images/hero-15.jpg);
  padding-top: 130px;
}

.hero-15-txt {
  margin-top: -20px;
}

.hero-15-txt h2 {
  padding-right: 5%;
}

.hero-15-txt p {
  padding-right: 2%;
}

/*------------------------------------------*/
/*  HERO-16
/*------------------------------------------*/

#hero-16 .wave-shape-bottom path {
  fill: #fff;
}

#hero-16 {
  background-image: url(../images/hero-16.jpg);
  padding-top: 170px;
  margin-bottom: 80px;
} 

.hero-16-img {
  margin-top: 60px;
  margin-bottom: -80px;
}

/*------------------------------------------*/
/*  HERO-17
/*------------------------------------------*/

#hero-17 {
  text-align: center;
  padding-top: 170px;
  background-image: url(../images/hero-17.jpg);
  background-position: top center;
}

#hero-17 #brands-1 {
  padding: 80px 6% 0;
}

#hero-17 #brands-1 .brands-carousel-5 .brand-logo {
  padding: 0 20px;
} 

#hero-17 .quick-form {
  margin-left: 8%;
  margin-right: 8%;
}

/*------------------------------------------*/
/*  HERO-18
/*------------------------------------------*/

#hero-18 {
  padding-top: 140px;
  padding-bottom: 80px;
}

#hero-18:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 74%;
  height: 100%;
  top: 0;
  left: 26%;
  background-image: url(../images/hero-18.jpg);
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
  border-radius: 0 0 0 60px;
}

.hero-18-txt p {
  padding-right: 3%;
}

#hero-18 .quick-form {
  margin-right: 4%;
}

.hero-18-img {
  text-align: center;
  padding-right: 15px;
}

/*------------------------------------------*/
/*  HERO-19
/*------------------------------------------*/

#hero-19 {
  padding-top: 170px;
}

.hero-19-txt p {
  padding: 0 10%;
}

/*------------------------------------------*/
/*  HERO-20
/*------------------------------------------*/

#hero-20 {
  background-image: url(../images/hero-20.jpg);
  padding-top: 100px;
  padding-bottom: 100px;
}

#hero-20:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 51%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, #f4f4f9, #fbfbfd);
}

.hero-20-txt {
  padding: 0 8% 0 15px;
}

.hero-section .hero-20-txt h2.s-48 {
  margin-bottom: 28px;
}

/*------------------------------------------*/
/*  HERO-21
/*------------------------------------------*/

#hero-21 {
  padding-top: 170px;
}

.hero-21-txt {
  margin-bottom: 60px;
}

.hero-21-txt h2 {
  margin-bottom: 35px;
}

.hero-21-wrapper {
  overflow: hidden;
}

#hero-21 .hero-overlay {
  background-image: url(../images/hero-21.jpg);
  padding: 90px 70px 0;
}

.hero-21-img {
  position: relative;
  margin-bottom: -25%;
}

.hero-21-img .video-btn {
  top: calc(50% - 80px);
}

/*------------------------------------------*/
/*  HERO-22
/*------------------------------------------*/

#hero-22 .wave-shape-bottom path {
  fill: #fff;
}

#hero-22 {
  position: relative;
  background-image: url(../images/hero-22.jpg);
  padding-top: 160px;
}

#hero-22-form {
  position: relative;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #f8f8f8;
  padding: 45px 35px 35px;
  -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, .08);
  -moz-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, .08);
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, .08);
}

#hero-22-form h4 {
  line-height: 1.4;
  margin-bottom: 20px;
}

#hero-22-form p {
  color: #757575;
  margin-bottom: 25px;
}

#hero-22-form p.p-sm {
  text-align: center;
  padding: 0 5%!important;
  margin-top: 5px;
  margin-bottom: 0;
}

#hero-22-form p a {
  color: #1e90ff;
  text-decoration: underline;
}

/*------------------------------------------*/
/*  HERO-23
/*------------------------------------------*/

#hero-23 .wave-shape-bottom path {
  fill: #fff;
}

#hero-23 {
  position: relative;
  background-image: url(../images/hero-23.jpg);
  padding-top: 100px;
  margin-bottom: 25px;
}

.hero-23-txt p {
  padding: 0 5%;
}

.hero-23-img {
  margin-top: 40px;
  margin-bottom: -25px;
}

/*------------------------------------------*/
/*  HERO-24
/*------------------------------------------*/

#hero-24 {
  background-image: url(../images/hero-24.jpg);
  padding-top: 200px;
  padding-bottom: 150px;
}

#hero-24 .quick-form {
  margin-left: 7%;
  margin-right: 7%;
}

/*------------------------------------------*/
/*  HERO-25
/*------------------------------------------*/

#hero-25 {
  background-image: url(../images/hero-25.jpg);
  padding-top: 120px;
  padding-bottom: 100px;
}

.hero-25-txt h2 {
  margin-bottom: 22px;
}

.hero-25-txt p.p-lg {
  padding-right: 10%;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  HERO-26
/*------------------------------------------*/

#hero-26 .wave-shape-bottom path {
  fill: #fff;
}

#hero-26 {
  position: relative;
  background-image: url(../images/hero-26.jpg);
  padding-top: 160px;
  z-index: 3;
}

.hero-26-txt {
  margin-top: -80px;
}

.hero-26-txt h2 {
  padding-right: 2%;
}

#hero-26 .quick-form {
  margin-right: 4%;
}

.hero-26-img {
  position: relative;
  text-align: center;
  margin: 0 -90% 0 -60px;
}

/*------------------------------------------*/
/*  HERO-27
/*------------------------------------------*/

#hero-27 .hero-overlay {
  background-image: url(../images/hero-27.png);
  background-position: top center;
  padding-top: 170px;
}

.hero-27-txt h2 { 
  margin-bottom: 26px!important;
}

.hero-27-txt p.s-20 { 
  padding: 0 6%;
  margin-bottom: 30px!important;
}

.hero-27-txt p.s-21 { 
  padding: 0 5%;
}

/*------------------------------------------*/
/*  HERO QUICK FORM
/*------------------------------------------*/

.hero-section .quick-form .input-group {
  border: none;
}

/*------------------------------------------*/
/*  Hero Quick Form Input
/*------------------------------------------*/

.hero-section .quick-form .form-control {
  height: 64px;
  background-color: #fff;
  border: 2px solid transparent;
  color: #666;
  font-size: 1.125rem;
  font-weight: 400;
  padding: 0 20px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;  
}

.hero-section .quick-form.form-shadow .form-control {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
}

.hero-section .quick-form.form-stroke .form-control {
  border-color: #353f4f;
}

.hero-section .quick-form.form-half .form-control {
  height: 58px;
 font-size: 1.0625rem;
}

/*------------------------------------------*/
/*  Hero Quick Form Placeholder
/*------------------------------------------*/

.hero-section .quick-form .form-control::-moz-placeholder { color: #999; } 
.hero-section .quick-form .form-control:-ms-input-placeholder { color: #999; } 
.hero-section .quick-form .form-control::-webkit-input-placeholder { color: #999; } 

/*------------------------------------------*/
/*  Hero Quick Form Focus
/*------------------------------------------*/

.hero-section .quick-form .form-control:focus {
  background-color: #fff;
  border-color: #1680fb;
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Hero Quick Form Button
/*------------------------------------------*/

.hero-section .quick-form .btn {
  height: 64px;
  font-size: 1.0625rem;
  padding: 12px 45px;
  margin-left: 15px;
}

.hero-section .quick-form.form-half .btn {
  height: 58px;
  font-size: 1rem;
  padding: 13px 40px;
  margin-left: 14px;
}

/*------------------------------------------*/
/*  Hero Quick Form Message
/*------------------------------------------*/

.hero-section .quick-form-msg {
  width: 100%!important;
  display: block;
  margin: 20px 0 15px 5px;
}

.hero-section .text-center .quick-form-msg {
  margin: 20px 0 -15px 0;
}

.hero-section .quick-form-msg .loading {
  color: #3eb1ff;
  font-size: 1.05rem;
  line-height: 1;
  font-weight: 500;
}

.hero-section .text-center .quick-form-msg .loading {
  font-size: 1.125rem;
}

/*------------------------------------------*/
/*  HERO REQUEST FORM
/*------------------------------------------*/

/*------------------------------------------*/
/*  Hero Request Form Input
/*------------------------------------------*/

.request-form .form-control {
  height: 58px;
  background-color: #f5f6f8;
  border: 1px solid transparent;
  color: #666;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 22px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  Hero Request Form Placeholder
/*------------------------------------------*/

.hero-form-wrapper .form-control::-moz-placeholder { color: #999; } 
.hero-form-wrapper .form-control:-ms-input-placeholder { color: #999; }
.hero-form-wrapper .form-control::-webkit-input-placeholder { color: #999; }

.request-form .form-control:focus {
  background-color: #fff;
  border-color: #1680fb;
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Hero Request Form Button
/*------------------------------------------*/

#hero-8-form .btn,
#hero-22-form .btn {
  width: 100%;
  height: 58px;
  padding: 16px;
}

/*------------------------------------------*/
/*  Hero Request Form Message
/*------------------------------------------*/

.request-form-msg {
  width: 100%!important;
  display: block;
  text-align: center;
  margin-top: 15px;
}

.request-form-msg .loading {
  color: #00b2e4;
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 500;
}

.request-form .error {
  color: #e74c3c;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 10px;
}




/* ==========================================================================
  06. ABOUT
  ========================================================================== */

.a2-txt {
  padding: 20px 30px 35px;
}

.a2-txt-quote { 
  position: relative;
  margin-bottom: -45px; 
 }

.a2-txt-quote.ico-40 [class*="flaticon-"]:before, 
.a2-txt-quote.ico-40 [class*="flaticon-"]:after { font-size: 4.25rem; }

/*------------------------------------------*/
/*  ABOUT TYPOGRAPHY
/*------------------------------------------*/ 

#about-1 .txt-block p {
  margin-bottom: 0;
}

#about-4 .txt-block p {
  margin-top: 25px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  ABOUT 2 AUTHOR
/*------------------------------------------*/ 

.a2-txt-author {
  line-height: 1.45;
  font-weight: 500;
  margin-top: 18px;
  margin-bottom: 0;
}

.a2-txt-author span {
  display: block;
  font-weight: 300;
}

/*------------------------------------------*/
/*  ABOUT 2 IMAGES
/*------------------------------------------*/ 

.about-3-images {
  padding: 0 5%;
}

.about-2-img {
  overflow: hidden;
}

.about-3-img {
  overflow: hidden;
  height: 460px;
}

.a-2-1 {
  margin-left: 13%;
  margin-bottom: 30px;
}

.a-2-2 {
  margin-left: -10%;
}

.a-2-3 {
  margin-right: -10%;
  margin-bottom: 30px;
}

.a-2-4 {
  margin-right: -10%;
}

#a4-1 {
  position: relative;
  padding-right: 30px;
  margin-top: -30px;
}

#a4-2 {
  position: relative;
  padding-left: 30px;
  margin-bottom: -30px;
}




/* ==========================================================================
  07. FEATURES
  ========================================================================== */

#features-12.py-100.shape--bg:after,
#features-12.pt-100.shape--bg:after,
#features-13.py-100.shape--bg:after,
#features-13.pt-100.shape--bg:after {
  position: absolute;
  content: '';
  z-index: -1;
}

#features-12.py-100.shape--bg:after { width: 48%; height: 64%; top: 18%; left: 52%; }
#features-12.pt-100.shape--bg:after { width: 48%; height: 72%; top: 21%; left: 52%; }
#features-13.py-100.shape--bg:after { width: 48%; height: 64%; top: 18%; }
#features-13.pt-100.shape--bg:after { width: 48%; height: 72%; top: 21%; }

.rows-2 .fb-1, 
.rows-2 .fb-2, 
.rows-2 .fb-3 {
 margin-bottom: 50px; 
}

.rows-2 .fbox-7.fb-1, 
.rows-2 .fbox-7.fb-2, 
.rows-2 .fbox-7.fb-3, 
.rows-2 .fbox-9.fb-1, 
.rows-2 .fbox-9.fb-2, 
.rows-2 .fbox-9.fb-3,
.rows-3 .fb-1, .rows-3 .fb-2, 
.rows-3 .fb-3, .rows-3 .fb-4 { margin-bottom: 40px; }

.rows-3 .fb-3.fbox-11-lst, 
.rows-3 .fb-4.fbox-11-lst { margin-bottom: 0; }

.fbox-14-wrapper {
  padding: 80px 70px;
}

.fbox-14-wrapper .col {
  padding: 0 10px;
}

/*------------------------------------------*/
/*  FEATURE BOX
/*------------------------------------------*/ 

.row-cols-md-3 .fbox-4 { padding: 50px 35px; }
.row-cols-md-2 .fbox-4 { padding: 35px 20px; } 

.fbox-5 { padding: 60px; }   

.fbox-7 {
  border: 2px solid #eee;
  padding: 55px 40px 50px;
}

.row-cols-md-2 .fbox-7 {
  padding: 35px 20px 30px;
}

.fbox-8 { padding: 55px 40px; }
.fbox-8.bg--white-100 { padding: 60px 40px; }

.fbox-9 {
  border: 2px solid #eee;
  padding: 40px 40px 35px;
} 

.fbox-14 {
  border: 2px solid #eee;
  padding: 40px 30px 35px;
} 

/*------------------------------------------*/
/*  FEATURE BOX IMAGE
/*------------------------------------------*/ 

.fbox-img img {
  width: auto;
  max-width: inherit;
}

.fbox-img.h-135 img { max-height: 135px; }
.fbox-img.h-140 img { max-height: 140px; }
.fbox-img.h-145 img { max-height: 145px; }
.fbox-img.h-150 img { max-height: 150px; }
.fbox-img.h-155 img { max-height: 155px; }
.fbox-img.h-160 img { max-height: 160px; }
.fbox-img.h-165 img { max-height: 165px; }
.fbox-img.h-170 img { max-height: 170px; }
.fbox-img.h-175 img { max-height: 175px; }
.fbox-img.h-180 img { max-height: 180px; }

/*------------------------------------------*/
/*  FBOX-1 IMAGE
/*------------------------------------------*/ 

.fbox-1 .fbox-img { 
  margin-bottom: 35px; 
}

.row-cols-md-2 .fbox-1 .fbox-img { 
  margin-bottom: 28px; 
}

/*------------------------------------------*/
/*  FBOX-2 IMAGE
/*------------------------------------------*/ 

.fbox-2 .fbox-img {
  -webkit-border-radius: 12px 12px 0 0;
  -moz-border-radius: 12px 12px 0 0;
  -o-border-radius: 12px 12px 0 0;
  border-radius: 12px 12px 0 0;
} 

.fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, 
.fbox-2 .fbox-img.h-150, .fbox-2 .fbox-img.h-155 { padding-top: 35px; margin-bottom: 60px; } 

.fbox-2 .fbox-img.h-160 { padding-top: 35px; margin: 0 20px 60px; }
.fbox-2 .fbox-img.h-165 { padding-top: 38px; margin: 0 15px 60px; } 
.fbox-2 .fbox-img.h-170 { padding-top: 40px; margin: 0 10px 60px; } 
.fbox-2 .fbox-img.h-175 { padding-top: 42px; margin: 0 8px 60px; }
.fbox-2 .fbox-img.h-180 { padding-top: 44px; margin: 0 5px 60px; }  

.fbox-2 .fbox-img img { 
  margin-bottom: -25px; 
} 

/*------------------------------------------*/
/*  FBOX-3 IMAGE
/*------------------------------------------*/ 

.fbox-3 .fbox-img {
  overflow: hidden;
} 

.fbox-3 .fbox-img.h-135 { padding-top: 30px; margin: 0 0 35px; }
.fbox-3 .fbox-img.h-160 { padding-top: 32px; margin: 0 18px 35px; }
.fbox-3 .fbox-img.h-170 { padding-top: 40px; margin: 0 10px 35px; }
.fbox-3 .fbox-img.h-175, .fbox-3 .fbox-img.h-180 { padding-top: 40px; margin: 0 5px 35px; }

.row-cols-md-2 .fbox-3 .fbox-img { 
  margin-bottom: 28px; 
}

/*------------------------------------------*/
/*  FBOX-4 IMAGE
/*------------------------------------------*/ 

.fbox-4 .fbox-img { 
  margin-bottom: 35px; 
}

.row-cols-md-2 .fbox-4 .fbox-img { 
  margin-bottom: 28px; 
}

/*------------------------------------------*/
/*  FBOX-5 IMAGE
/*------------------------------------------*/ 

.fbox-5.fb-1 .fbox-5-img,
.fbox-5.fb-5 .fbox-5-img,
.fbox-5.fb-6 .fbox-5-img {
  margin: 40px 5% 0;
}

.fbox-5.fb-2 .fbox-5-img,
.fbox-5.fb-3 .fbox-5-img,
.fbox-5.fb-4 .fbox-5-img {
   margin: 0 5% 40px;
}

/*------------------------------------------*/
/*  FEATURE BOX VECTOR ICON
/*------------------------------------------*/ 

.fbox-ico,
.fbox-ico.ico-rounded {
  margin-bottom: 20px;
}

.fbox-ico.ico-rounded-md,
.fbox-ico.ico-rounded-lg {
  margin-bottom: 25px;
}

.fbox-11 {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch!important;
  justify-content: flex-start;
}

.fbox-ico-wrap {
  position: relative;
  margin-right: 25px;
}

.rows-2 .fbox-ico-wrap {
  margin-right: 20px;
}

.fbox-11 .fbox-ico {
  margin-top: 4px;
}

/*------------------------------------------*/
/*  FEATURE BOX
/*------------------------------------------*/ 

.fbox-11 .fbox-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
}

/*------------------------------------------*/
/*  FEATURE BOX TITLE
/*------------------------------------------*/ 

.fbox-wrapper .row-cols-lg-3 .fbox-title {
  margin-bottom: 10px;
}

.fbox-title span {
  float: left;
  line-height: 1!important;
  margin-right: 15px; 
}

.fbox-title h6 {  
  line-height: 2.1875rem!important;
  margin-bottom: 0;
}

.fbox-title.ico-30 h6 {  
  line-height: 1.875rem!important;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  FEATURE BOX TYPOGRAPHY
/*------------------------------------------*/ 

.fbox-wrapper .row-cols-md-3 h6 {
  margin-bottom: 15px;
}

.rows-2 .fbox-11 h6,
.fbox-wrapper .row-cols-md-2 h6 {
  margin-bottom: 10px;
}

.fbox-wrapper p {
  margin-bottom: 0;
}

.fbox-5 h5 {
  margin-bottom: 15px;
}

.fbox-14 h5 {
  margin-bottom: 15px;
}

/*------------------------------------------*/
/*  FEATURE-12
/*------------------------------------------*/ 

.fbox-12-wrapper { 
  padding-left: 45px;
}

.fbox-13-wrapper { 
  padding-right: 45px;
}

.fbox-12,
.fbox-13 {
  padding: 32px 35px;
} 

.fbox-12 h5,
.fbox-13 h5 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.fbox-12 p,
.fbox-13 p,
.fbox-14 p {
  margin-bottom: 0;
} 




/* ==========================================================================
  08. CONTENT
  ========================================================================== */

.ct-06 {
  margin-bottom: 80px;
}

.ct-07 {
  margin-top: 80px;
}

.ct-10 .section-overlay {
  margin-bottom: 100px;
}

.ct-11, 
.ct-12 {
  padding: 60px 0;
}

.ct-11:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
}

.ct-12:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
  background-color: #fff;
}

/*------------------------------------------*/
/*  CONTENT BOX WRAPPER
/*------------------------------------------*/

.bc-1-wrapper .section-overlay,
.bc-4-wrapper .section-overlay,
.bc-6-wrapper .section-overlay {
  padding: 80px 70px;
}

.bc-2-wrapper .section-overlay,
.bc-3-wrapper .section-overlay,
.bc-5-wrapper .section-overlay {
  padding: 80px 70px 0;
}

.bc-6 {
  padding: 40px 35px;
}

.cbox-6-wrapper {
  padding: 60px 40px;
}

.content-section .cbox-6-wrapper {
  padding: 80px 70px;
}

.bc-5-wrapper .section-title p {
  padding: 0;
}

/*------------------------------------------*/
/*  TEXT BLOCK
/*------------------------------------------*/ 

.txt-box { 
  margin-bottom: 20px;
}

.txt-box:last-child { 
  margin-bottom: 0; 
}

.bc-3-txt,
.ct-06 .txt-block,
.bc-2-wrapper .txt-block {
  margin-top: -40px;
}

.bc-2-txt,
.ct-07 .txt-block {
  margin-top: 40px;
}

.bc-1-wrapper .txt-block.pr-25.left-column,
.bc-2-wrapper .txt-block.pr-25.left-column {
  padding-right: 25px;
}

.bc-1-wrapper .txt-block.pl-25.right-column,
.bc-2-wrapper .txt-block.pl-25.right-column {
  padding-left: 25px;
}

.ct-11 .txt-block {
  padding-right: 50px;
}

.ct-12 .txt-block {
  padding-left: 70px;
}

.ct-13 .txt-block h5 {
  padding-right: 6%;
}

/*------------------------------------------*/
/*  TEXT BLOCK TYPOGRAPHY
/*------------------------------------------*/ 

.bc-2-txt h2,
.bc-3-txt h2,
.bc-4-txt h2,
.txt-block h3,
.txt-block h2 {
  margin-bottom: 26px;
}

.txt-block h5 {
  margin-bottom: 20px;
}

h5.h5-title {
  margin-top: 20px;
  margin-bottom: 20px;
}

.txt-block .btn,
.txt-block .btns-group .btn.btn--transparent {
  margin-top: 25px;
}

.txt-block .btn.btn--transparent {
  margin-top: 0;
}

/*------------------------------------------*/
/*  CONTENT BOX
/*------------------------------------------*/

.cbox-1,
.cbox-2,
.cbox-3 {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch!important;
  justify-content: flex-start;
}

.cbox-5 {
  position: relative;
}

.cbox-5-content {
  background-color: #fff;
  border: 1px solid #f5f6f6;
  padding: 40px;
  margin: 0 12%;
  z-index: 1;
  -webkit-box-shadow: 0px 15px 20px 0px rgba(5, 5, 5, .05);
  -moz-box-shadow: 0px 15px 20px 0px rgba(5, 5, 5, .05);
  box-shadow: 0px 15px 20px 0px rgba(5, 5, 5, .05);
}

.cbox-5-content .divider {
  margin-top: 25px;
  margin-bottom: 25px;
}

#cb-6-1, #cb-6-2 {
  margin-bottom: 35px;
}

/*------------------------------------------*/
/*  CONTENT BOX TITLE
/*------------------------------------------*/ 

.box-title h6,
.box-title h5 {  
  display: inline-block;
  line-height: 2.35rem!important;
  margin-bottom: 15px;
}

.box-title span {   
  position: relative;
  top: 12px;
  margin-right: 10px;
}

.box-title span[class*="flaticon-"]:before,
.box-title span[class*="flaticon-"]:after {   
  font-size: 2.35rem;
}

.txt-block .cbox-5-title h2 {
  font-size: 3.5rem;
  letter-spacing: -1px;
  line-height: 1;
  margin-bottom: 10px;
}

.cbox-5-title .statistic-number sup {
  font-size: 3rem;
  top: -5px;
  right: 6px;
}

.cbox-5-title .ico-15 [class*="flaticon-"]:before, 
.cbox-5-title .ico-15 [class*="flaticon-"]:after {
  font-size: 0.875rem;
  line-height: 1!important; 
}

.cbox-5-title p.p-lg {
  margin-bottom: 10px;
}

/*------------------------------------------*/
/*  CONTENT BOX ICON
/*------------------------------------------*/

.cbox-1 .ico-wrap { 
  margin-left: 8px; 
}

.cbox-1.ico-10 span {
  position: relative;
  top: 0;
  right: 7px;
}

.cbox-1.ico-15 span {
  position: relative;
  top: 1px;
  right: 8px;
}

.cbox-1.ico-10 [class*="flaticon-"]:before,
.cbox-1.ico-10 [class*="flaticon-"]:after {  
  font-size: 0.6rem;
}

.cbox-1.ico-15 [class*="flaticon-"]:before,
.cbox-1.ico-15 [class*="flaticon-"]:after {   
  font-size: 0.75rem;
}

/*------------------------------------------*/
/*  CBOX #2
/*------------------------------------------*/

.cbox-2 .ico-wrap {
  position: relative;
  margin-right: 1.625rem;
}

.cbox-2-ico {
  position: relative;
  width: 2.625rem;
  height: 2.625rem;
  text-align: center;
  border-radius: 100%;
  font-size: 1.1875rem;
  font-weight: 500;
  line-height: 2.375rem;
  border: 2px solid transparent;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out; 
}

.ct-03 .cbox-2-ico {
  position: relative;
  width: 2.35rem;
  height: 2.35rem;
  font-size: 1.15rem;
  line-height: 2.15rem;
}

.cbox-2-line {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 2px;
  background-color: #e4e4e4;
  height: calc(100% - 90px);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.cbox-2:hover .cbox-2-ico {
  background-color: transparent;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.cbox-2:hover .cbox-2-ico.bg--blue-500 { color: #185abd; border-color: #185abd; }
.cbox-2:hover .cbox-2-ico.bg--blue-400 { color: #1680fb; border-color: #1680fb; }
.cbox-2:hover .cbox-2-ico.bg--blue-300 { color: #3eb1ff; border-color: #3eb1ff; }

.cbox-2:hover .cbox-2-ico.bg--green-500 { color: #00a14e; border-color: #00a14e; }
.cbox-2:hover .cbox-2-ico.bg--green-400 { color: #0fbc49; border-color: #0fbc49; }
.cbox-2:hover .cbox-2-ico.bg--green-300 { color: #6fd792; border-color: #6fd792; }

.cbox-2:hover .cbox-2-ico.bg--pink-500 { color: #ff149c; border-color: #ff149c; }
.cbox-2:hover .cbox-2-ico.bg--pink-400 { color: #f74780; border-color: #f74780; }
.cbox-2:hover .cbox-2-ico.bg--pink-300 { color: #fcb5cc; border-color: #fcb5cc; }

.cbox-2:hover .cbox-2-ico.bg--purple-500 { color: #663ae9; border-color: #663ae9; }
.cbox-2:hover .cbox-2-ico.bg--purple-400 { color: #7b5dd6; border-color: #7b5dd6; }
.cbox-2:hover .cbox-2-ico.bg--purple-300 { color: #bdaeea; border-color: #bdaeea; }

.cbox-2:hover .cbox-2-ico.bg--red-500 { color: #da0f29; border-color: #da0f29; }
.cbox-2:hover .cbox-2-ico.bg--red-400 { color: #ff1e1a; border-color: #ff1e1a; }
.cbox-2:hover .cbox-2-ico.bg--red-300 { color: #ffa5a3; border-color: #ffa5a3; }

.cbox-2:hover .cbox-2-ico.bg--violet-500 { color: #c73e9b; border-color: #c73e9b; }
.cbox-2:hover .cbox-2-ico.bg--violet-400 { color: #be6cbe; border-color: #be6cbe; }
.cbox-2:hover .cbox-2-ico.bg--violet-300 { color: #e2bde2; border-color: #e2bde2; }

.cbox-2:hover .cbox-2-ico.bg--yellow-500 { color: #ffba01; border-color: #ffba01; }
.cbox-2:hover .cbox-2-ico.bg--yellow-400 { color: #ffbd45; border-color: #ffbd45; }
.cbox-2:hover .cbox-2-ico.bg--yellow-300 { color: #ffdf35; border-color: #ffdf35; }

/*------------------------------------------*/
/*  CBOX #3
/*------------------------------------------*/

.cbox-3 .ico-wrap {
  position: relative;
  text-align: center;
  margin-top: 0;
  margin-right: 1.15rem;
}

.cbox-3 .cbox-3-ico {
  position: relative;
  top: 3px;
}

.cbox-3-ico span[class*="flaticon-"]:before,
.cbox-3-ico span[class*="flaticon-"]:after {  
  font-size: 2.85rem;
}

/*------------------------------------------*/
/*  CONTENT BOX TEXT
/*------------------------------------------*/

.cbox-1-txt,
.cbox-2-txt,
.cbox-3-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
}

.cbox-2-txt,
.cbox-3-txt {
  margin-bottom: 35px;
}

.ct-03 .cbox-2-txt {
  margin-bottom: 30px;
}

.cbox-4-txt {
  margin-bottom: 25px;
}

.cbox-2:last-child .cbox-2-txt,
.cbox-3:last-child .cbox-3-txt,
.cbox-4:last-child .cbox-4-txt {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  CONTENT BOX FEATURE
/*------------------------------------------*/  

.cbox-5-fea {
  padding: 20px 25px;
  margin-top: 25px;
  -webkit-box-shadow: 0px 15px 20px 0px rgba(5, 5, 5, .05);
  -moz-box-shadow: 0px 15px 20px 0px rgba(5, 5, 5, .05);
  box-shadow: 0px 15px 20px 0px rgba(5, 5, 5, .05);
}

.txt-block .cbox-5-fea h4 {
  line-height: 1;
  margin-bottom: 3px;
}

.cbox-5-fea p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  CONTENT BOX TYPOGRAPHY
/*------------------------------------------*/

.cbox-2-txt h5,
.cbox-3-txt h5 {
  margin-bottom: 15px;
}

.ct-03 .cbox-2-txt h5 {
  margin-bottom: 10px;
}

.cbox-6 h6 {
  margin-bottom: 15px;
}

.cbox-1-txt p { 
  margin-bottom: 8px;
}

.cbox-1-last .cbox-1-txt p { 
  margin-bottom: 1rem;
}

.cbox-6 p,
.cbox-2-txt p,
.cbox-3-txt p,
.cbox-4-txt p {
  margin-bottom: 0;
}

.bc-6-txt {
  padding: 0 10px;
}

.bc-6-txt h5 {
  margin-bottom: 15px;
}

/*------------------------------------------*/
/*  ACCORDION  
/*------------------------------------------*/

.txt-block .accordion-1 .accordion-item {
  background-color: transparent;
  padding: 25px 30px;
  border: none;
}

.txt-block .accordion-2 .accordion-item {
  background-color: transparent;
  padding: 15px 0 25px;
  border: none;
  border-top: 1px solid #ccc;
  border-radius: 0 0;
}

.txt-block .accordion-2 .accordion-item:last-child {
  border-bottom: 1px solid #ccc;
}

.txt-block .accordion-item.r-02:first-of-type { border-top-left-radius: 2px; border-top-right-radius: 2px; }
.txt-block .accordion-item.r-04:first-of-type { border-top-left-radius: 4px; border-top-right-radius: 4px; }
.txt-block .accordion-item.r-06:first-of-type { border-top-left-radius: 6px; border-top-right-radius: 6px; }
.txt-block .accordion-item.r-08:first-of-type { border-top-left-radius: 8px; border-top-right-radius: 8px; }
.txt-block .accordion-item.r-10:first-of-type { border-top-left-radius: 10px; border-top-right-radius: 10px; }
.txt-block .accordion-item.r-12:first-of-type { border-top-left-radius: 12px; border-top-right-radius: 12px; }
.txt-block .accordion-item.r-14:first-of-type { border-top-left-radius: 14px; border-top-right-radius: 14px; }
.txt-block .accordion-item.r-16:first-of-type { border-top-left-radius: 16px; border-top-right-radius: 16px; }

.txt-block .accordion-item.r-02:last-of-type { border-bottom-right-radius: 2px; border-bottom-left-radius: 2px; }
.txt-block .accordion-item.r-04:last-of-type { border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; }
.txt-block .accordion-item.r-06:last-of-type { border-bottom-right-radius: 6px; border-bottom-left-radius: 6px; }
.txt-block .accordion-item.r-08:last-of-type { border-bottom-right-radius: 8px; border-bottom-left-radius: 8px; }
.txt-block .accordion-item.r-10:last-of-type { border-bottom-right-radius: 10px; border-bottom-left-radius: 10px; }
.txt-block .accordion-item.r-12:last-of-type { border-bottom-right-radius: 12px; border-bottom-left-radius: 12px; }
.txt-block .accordion-item.r-14:last-of-type { border-bottom-right-radius: 14px; border-bottom-left-radius: 14px; }
.txt-block .accordion-item.r-16:last-of-type { border-bottom-right-radius: 16px; border-bottom-left-radius: 16px; }

.txt-block .accordion-thumb {
  margin: 0;
  padding: 0;
  cursor: pointer;
  position: relative;
}

.txt-block .accordion-1 .accordion-item .accordion-thumb:before,
.txt-block .accordion-2 .accordion-item .accordion-thumb:after,
.txt-block .accordion-1 .accordion-item.is-active .accordion-thumb:before,
.txt-block .accordion-2 .accordion-item.is-active .accordion-thumb:after {
  color: #6c757d;
  font-family: Flaticon;
  position: absolute;
  color: #353f4f;
  font-weight: 400; 
}

.txt-block .accordion-1 .accordion-item .accordion-thumb:before {
  content: "\f11a";
  font-size: 0.95rem;
  top: 1px;
  left: 0;
}

.txt-block .accordion-2 .accordion-item .accordion-thumb:after,
.txt-block .accordion-2 .accordion-item.is-active .accordion-thumb:after {
  content: "\f110";
  font-size: 0.95rem;
  top: 12px;
  right: 0;
}

.txt-block .accordion-1 .accordion-item.is-active {
  background-color: #f4f4f9;
  padding: 35px 30px 19px 30px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.txt-block .accordion-2 .accordion-item.is-active {
  background-color: transparent;
  margin-bottom: 0;
  margin-top: 0;
}

.txt-block .accordion-1 .accordion-item.is-active .accordion-thumb:before {
  content: "\f11b";
}

.txt-block .accordion-2 .accordion-item.is-active .accordion-thumb:after {
  content: "\f111";
}

.txt-block .accordion-1 .accordion-panel {
  color: #6c757d;
  margin: 0;
  padding: 0 0 0 25px;
  display: none;
}

.txt-block .accordion-2 .accordion-panel {
  color: #6c757d;
  margin: 15px 0 0 0;
  display: none;
}

.txt-block .accordion-1 .accordion-thumb h5 {
  color: #353f4f;
  line-height: 1;
  padding-left: 28px;
  margin-bottom: 0;
}

.txt-block .accordion-2 .accordion-thumb h5 {
  color: #353f4f;
  display: inline-block;
  line-height: 1;
  padding-left: 10px;
  margin-bottom: 0;
}

.txt-block .accordion-1 .accordion-item.is-active .accordion-thumb h5 {
  margin-bottom: 20px;
}

.accordion-2 .accordion-thumb span[class*="flaticon-"]:before,
.accordion-2 .accordion-thumb span[class*="flaticon-"]:after {  
  color: #6c757d;
  position: relative;
  font-size: 2.1875rem;
  top: 10px;
}

.txt-block .accordion-2 .accordion-panel .simple-list li {
  margin-bottom: 1rem;
}

.txt-block .accordion-2 .accordion-panel p,
.txt-block .accordion-2 .accordion-panel .simple-list li:last-child {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  TABS
/*------------------------------------------*/

#tabs-1 .tabs-nav { 
  text-align: center;
}

.tabs-1 { 
  display: inline-block;
  margin: 0 auto 60px;
}

#tabs-1 .tab-content {
  display: none;
}

#tabs-1 .tab-content.current {
  display: inherit;
}

.tabs-1 li {
  display: inline-block; 
  margin: 0 20px;
  color: #aaa;
  font-size: 1.05rem;
  line-height: 1;
  font-weight: 500;
  padding: 0 2px 10px;
  background-color: transparent;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

/*------------------------------------------*/
/*  TAB LINK HOVER / CURRENT
/*------------------------------------------*/

.tabs-1 li:hover {
  color: #888;
  border-color: #ccc;
}

.tabs-1 li.current,
.tabs-1 li.current:hover {
  color: #2b3648;
  border-color: #2b3648;
}

.tabs-1.tabs--blue-500, .tabs-1.tabs--blue-500 li.current:hover { color: #185abd; border-color: #185abd; }
.tabs-1.tabs--blue-400, .tabs-1.tabs--blue-500 li.current:hover { color: #1680fb; border-color: #1680fb; }
.tabs-1.tabs--blue-300, .tabs-1.tabs--blue-500 li.current:hover { color: #3eb1ff; border-color: #3eb1ff; }

.tabs-1.tabs--green-500, .tabs-1.tabs--green-500 li.current:hover { color: #00a14e; border-color: #00a14e; }
.tabs-1.tabs--green-400, .tabs-1.tabs--green-400 li.current:hover { color: #0fbc49; border-color: #0fbc49; }
.tabs-1.tabs--green-300, .tabs-1.tabs--green-300 li.current:hover { color: #6fd792; border-color: #6fd792; }

.tabs-1.tabs--pink-500, .tabs-1.tabs--pink-500 li.current:hover { color: #ff149c; border-color: #ff149c; }
.tabs-1.tabs--pink-400, .tabs-1.tabs--pink-400 li.current:hover { color: #f74780; border-color: #f74780; }
.tabs-1.tabs--pink-300, .tabs-1.tabs--pink-300 li.current:hover { color: #fcb5cc; border-color: #fcb5cc; }

.tabs-1.tabs--purple-500, .tabs-1.tabs--purple-500 li.current:hover { color: #663ae9; border-color: #663ae9; }
.tabs-1.tabs--purple-400, .tabs-1.tabs--purple-400 li.current:hover { color: #7b5dd6; border-color: #7b5dd6; }
.tabs-1.tabs--purple-300, .tabs-1.tabs--purple-300 li.current:hover { color: #bdaeea; border-color: #bdaeea; }

.tabs-1.tabs--red-500, .tabs-1.tabs--red-500 li.current:hover { color: #da0f29; border-color: #da0f29; }
.tabs-1.tabs--red-400, .tabs-1.tabs--red-400 li.current:hover { color: #ff1e1a; border-color: #ff1e1a; }
.tabs-1.tabs--red-300, .tabs-1.tabs--red-300 li.current:hover { color: #ffa5a3; border-color: #ffa5a3; }

.tabs-1.tabs--violet-500, .tabs-1.tabs--violet-500 li.current:hover { color: #c73e9b; border-color: #c73e9b; }
.tabs-1.tabs--violet-400, .tabs-1.tabs--violet-400 li.current:hover { color: #be6cbe; border-color: #be6cbe; }
.tabs-1.tabs--violet-300, .tabs-1.tabs--violet-300 li.current:hover { color: #e2bde2; border-color: #e2bde2; }

.tabs-1.tabs--yellow-500, .tabs-1.tabs--yellow-500 li.current:hover { color: #ffba01; border-color: #ffba01; }
.tabs-1.tabs--yellow-400, .tabs-1.tabs--yellow-400 li.current:hover { color: #ffbd45; border-color: #ffbd45; }
.tabs-1.tabs--yellow-300, .tabs-1.tabs--yellow-300 li.current:hover { color: #ffdf35; border-color: #ffdf35; }

/*------------------------------------------*/
/*  ADVANTAGES LIST
/*------------------------------------------*/

.advantages {
  margin-top: 15px;
}

.text-center .advantages {
  margin-top: 20px;
}

.advantages li {
  width: auto!important;
  display: inline-block!important;
  vertical-align: top;
  clear: none !important;
}

.advantages-links-divider {
  position: relative;
  top: 0.5px;
  transform: rotate(-90deg);
}

.advantages li p {
  display: inline-block;
  float: left;
  margin-bottom: 0;
}

.advantages.ico-15 li p span {
  position: relative;
  top: 2px;
  right: -1px;
}

.advantages.ico-15 [class*="flaticon-"]:before, 
.advantages.ico-15 [class*="flaticon-"]:after { font-size: 0.75rem; }

.advantages li p a {
  font-weight: 400;
  text-decoration: underline;
}

.advantages li p a:hover {
  text-decoration: underline;
}

.white--color .advantages li p a:hover {
  color: #fff!important;
}

/*------------------------------------------*/
/*  COMPATABILITY
/*------------------------------------------*/

.compatibility {
  display: inline-block;
  padding: 3px 20px;
  margin-top: 15px;
  margin-bottom: 0;
}

.compatibility span {
  position: relative;
  top: -1.5px;
  right: 2px;
}

.compatibility.ico-10 [class*="flaticon-"]:before, 
.compatibility.ico-10 [class*="flaticon-"]:after { font-size: 0.5rem; }

/*------------------------------------------*/
/*  TOOLS LIST / PAYMENT METHODS
/*------------------------------------------*/

.tools-list,
.payment-methods {
  margin-top: 25px;
}

.tools-list p,
.payment-methods p {
  font-weight: 400;
  margin-bottom: 12px;
}

.tools-list.ico-35 [class*="flaticon-"]:before, 
.tools-list.ico-35 [class*="flaticon-"]:after { 
  line-height: 2.1875rem!important;
  margin-right: 6px;
}

/*------------------------------------------*/
/*  Payment Icons
/*------------------------------------------*/

.payment-icons li {
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0;
  padding-right: 5px;
}

.payment-icons li:last-child {
  padding-right: 0;
}

.payment-icons img {
  width: auto;
  max-width: inherit;
  max-height: 32px;
}

/*------------------------------------------*/
/*  QUOTE
/*------------------------------------------*/ 

.quote p {
  color: #666;
  border-left: 3px solid #0195ff;
  font-style: italic;
  padding-left: 20px;
  margin-bottom: 12px;
}

/*------------------------------------------*/
/*  Quote Avatar 
/*------------------------------------------*/ 

.quote-avatar {
  display: inline-block;
  margin: 0 auto; 
}

.quote-avatar img {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

/*------------------------------------------*/
/*  Quote Author 
/*------------------------------------------*/ 

.quote-author {
  position: relative;
  display: inline-block;
  padding: 0 0 0 15px;
  text-align: left;
  top: 16px;
} 
   
.quote-author h6 {
  margin-bottom: 0;
}

.quote-author span {
  font-size: 1rem;
}

/*------------------------------------------*/
/*  CONTENT BOX SHAPES
/*------------------------------------------*/ 

.cbox-5-shape-1 {
  position: absolute;
  right: 30px;
  top: -35px;
  z-index: -1;
}

.cbox-5-shape-2 {
  position: absolute;
  left: 30px;
  bottom: -25px;
  z-index: -1;
}

.cbox-5-shape-1 img,
.cbox-5-shape-2 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/*------------------------------------------*/
/*  IMG BLOCK IMAGE
/*------------------------------------------*/ 

.grey-img {
  filter: grayscale(100%);
}

.img-block {
  text-align: center;
}

.img-block.img-block-bkg {
  overflow: hidden;
}

.img-block.img-block-bkg img {
  padding: 60px 45px;
}

.ct-10 .img-block,
.img-block-hidden,
.bc-5-img.bc-5-dashboard.img-block-hidden {
  margin-bottom: -100px;
}

.ct-05 .img-block-hidden {
  margin-bottom: -30px;
}

.bc-5-img.bc-5-tablet.img-block-hidden {
  margin-bottom: -200px;
}

.img-block-hidden .video-btn,
.bc-5-img.bc-5-dashboard.img-block-hidden .video-btn {
  top: calc(50% - 30px);
}

.bc-5-img.bc-5-tablet.img-block-hidden .video-btn {
  top: calc(50% - 70px);
}

.ct-01 .img-block,
.ct-04 .img-block {
  margin-left: 30px;
}

.ct-02 .img-block {
  margin-right: 30px;
}

.ct-03 .img-block.right-column {
  margin-left: 20px;
}

.ct-03 .img-block.left-column {
  margin-right: 20px;
}

.ct-04 .img-block img {
  max-width: none;
  display: inline-block;
}

.ct-06 .img-block {
  margin-bottom: -80px;
}

.ct-07 .img-block {
  margin-top: -80px;
}

.img-block-txt,
.img-block-btn {
  margin-top: 60px;
}

.bc-1-wrapper .img-block.left-column,
.bc-2-wrapper .img-block.left-column {
  padding-right: 10px;
}

.bc-1-wrapper .img-block.right-column,
.bc-2-wrapper .img-block.right-column {
  padding-left: 10px;
}

.bc-2-img {
  margin-left: 15px;
}

.bc-3-img {
  margin: 0 -65% -25px 15px;
}

.bc-4-img {
  margin-left: 15px;
  margin-right: -65%;
}

.bc-6-img {
  padding: 50px 30px 0;
  margin-bottom: 35px;
}

.ct-11 .img-block {
  position: relative;
  text-align: center;
  padding-left: 70px;
  z-index: 2;
}

.ct-12 .img-block {
  position: relative;
  text-align: center;
  padding-right: 70px;
  z-index: 2;
}

.ct-11 .img-block .tra-bkg,
.ct-12 .img-block .tra-bkg {
  opacity: 0;
  -moz-opacity: 0; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
} 




/* ==========================================================================
  09. STATISTIC
  =========================================================================== */

.sb-rounded .statistic-block {
  padding: 35px 25px;
}

/*------------------------------------------*/
/*  STATISTIC BLOCK
/*------------------------------------------*/

#sb-2-1,
#sb-2-2 {
  width: 48%;
  display: inline-block;
  float: left;
}

#sb-2-3, #sb-2-4 {
  display: inline-block;
  float: left;
  padding-right: 15px;
} 

#sb-2-3 { width: 45%; }
#sb-2-4 { width: 55%; }

#sb-2-3 .statistic-block,
#sb-2-4 .statistic-block {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch!important;
  justify-content: flex-start;
}

#sb-2-3 .statistic-block-txt,
#sb-2-4 .statistic-block-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
  padding-left: 15px;
}

/*------------------------------------------*/
/*  STATISTIC BLOCK TYPOGRAPHY
/*------------------------------------------*/

.statistic-1-wrapper .statistic-block-digit {   
  width: 35%;
  display: inline-block!important;  
  float: left;
}

.statistic-1-wrapper .statistic-block-txt {
  width: 65%;
  display: inline-block!important;
  padding-left: 20px;
}

#statistic-5 .statistic-digit {
  padding-bottom: 20px;
  margin: 0 100px 20px 0;
  border-bottom: 1px solid #999;
}

#statistic-5 .color--white .statistic-digit {
  border-bottom: 1px solid rgba(255, 255, 255, .8);
}

/*------------------------------------------*/
/*  STATISTIC BLOCK TYPOGRAPHY
/*------------------------------------------*/

.statistic-ico {
  margin-bottom: 10px;
}

h2.statistic-number {
  line-height: 1; 
  font-weight: 700;
  letter-spacing: -1px;
  margin-bottom: 0;
}

#sb-2-1 h2.statistic-number,
#sb-2-2 h2.statistic-number {
  margin-bottom: 15px;
}

#sb-2-3 h2.statistic-number span,
#sb-2-4 h2.statistic-number span {
  font-size: 2rem;
}

#statistic-5 .statistic-digit h2 {
  letter-spacing: -1.5px;
}

#statistic-5 .statistic-txt h5 {
  line-height: 1;
  margin-bottom: 12px;
}

.statistic-block-txt p.p-md {
  line-height: 1.35;
  margin-bottom: 0;
}

#sb-2-1 .statistic-block p.p-sm,
#sb-2-2 .statistic-block p.p-sm {
  line-height: 1.4;
  margin-bottom: 0;
}

#sb-2-3 .statistic-block p.p-sm,
#sb-2-4 .statistic-block p.p-sm {
  margin-top: 3px;
  line-height: 1.5;
  margin-bottom: 0;
}

.statistic-3-wrapper p {
  line-height: 1;
  margin-top: 15px;
  margin-bottom: 0;
}

.statistic-3-wrapper p.p-md {
  line-height: 1;
  margin-top: 22px;
  margin-bottom: 0;
}

.statistic-4-wrapper p {
  line-height: 1;
  margin-bottom: 14px;
}

#statistic-5 .statistic-txt p {
  padding-right: 3%;
  margin-bottom: 0;
}

.txt-block-stat .statistic-block .txt-block-rating {
  margin-top: -4px;
  margin-bottom: -3px;
}

.txt-block-stat .ico-15 [class*="flaticon-"]:before, 
.txt-block-stat .ico-15 [class*="flaticon-"]:after { font-size: 0.8125rem; }




/* ==========================================================================
  10. PROJECTS
  ========================================================================== */

.projects-wrapper {
  padding: 0 10px;
}

/*------------------------------------------*/
/*  PROJECT DETAILS
/*------------------------------------------*/

#pt-1-1, #pt-1-2,
.inner-page-hero #pt-1-3, 
.inner-page-hero #pt-1-4 {
  margin-bottom: 50px;
}

.project-details h5 {
  line-height: 1;
  margin-bottom: 30px;
}

.project-preview {
  position: relative;
  overflow: hidden;
}

/*------------------------------------------*/
/*  Image Link
/*------------------------------------------*/

.project-link {
  width: 100%;
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  opacity: 0;
  text-align: center;
  color: #fff;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  Image Hover Overlay
/*------------------------------------------*/

.hover-overlay {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative; 
}

.hover-overlay img {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  overflow: hidden;
  -webkit-transition: transform 400ms;
  -moz-transition: transform 400ms;
  -o-transition: transform 400ms;
  transition: transform 400ms;
}

/*------------------------------------------*/
/*    Overlay Background 
/*------------------------------------------*/ 

.item-overlay {
  opacity: 0;
  -moz-opacity: 0; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 20, 20, .25);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  Image Hover Effect 
/*------------------------------------------*/ 

.project-preview:hover img {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.project-preview:hover .project-link {
  top: 50%;
  opacity: 1; 
  -moz-opacity: 1; 
}

.project-preview:hover .item-overlay {
  opacity: 1; 
  -moz-opacity: 1; 
}

/*------------------------------------------*/
/*  MORE PROJECTS BUTTON
/*------------------------------------------*/ 

.more-projects {
  text-align: center;
  margin-top: 70px;
}




/* ==========================================================================
  11. PROJECT DETAILS
  ========================================================================== */

.single-project .project-title {
  margin-bottom: 80px;
}

.project-title h2 {
  padding-right: 5%;
  padding-bottom: 40px;
  margin-bottom: 35px;
  border-bottom: 1px solid #ddd;
}

.project-data p {
  margin-bottom: 4px;
}

.project-data p span {
  color: #353f4f;
  font-weight: 500;
}

/*------------------------------------------*/
/*  MORE PROJECTS BUTTON
/*------------------------------------------*/

.project-description .more-projects {
  margin-top: 65px;
}

.project-description .more-projects h3 {
  display: inline-block;
  text-decoration: underline;
  margin-bottom: 0;
}

.project-description .more-projects span {
  color: #353f4f;
  position: relative;
  top: 2px;
  left: 5px;
}




/* ==========================================================================
  12. RATING
  =========================================================================== */

.rating-section .rating-1-wrapper {
  padding: 0 16%;
}

.rating-section .rating-2-wrapper {
  padding: 0 4%;
}

.rating-title {
  text-align: center;
}

.rating-title h5 {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  RATING BOX
/*------------------------------------------*/

.rbox-1,
.rbox-2 {
  text-align: center;
}

/*------------------------------------------*/
/*  RATING BOX LOGO
/*------------------------------------------*/

.rbox-1-img {
  margin-bottom: 20px;
}

.rbox-1-img img {
  width: auto;
  max-width: inherit;
  max-height: 38px;
}

.rbox-2-txt img {
  position: relative;
  display: inline-block;
  width: auto;
  max-width: inherit;
  max-height: 30px;
  top: -1px;
  left: 4px;
}

/*------------------------------------------*/
/*  RATING BOX TYPOGRAPHY
/*------------------------------------------*/

.rbox-2-txt {
  margin-top: 20px;
}

.rbox-1 .star-rating {
  display: inline-block;
  font-size: 17px;
  line-height: 15px;
  font-weight: 500;
  vertical-align: inherit;
  border: 1px solid #f5f5f5;
  padding: 5px 18px;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
}

.rbox-1 .star-rating span {
  position: relative;
  color: #ffb30c;
  top: 1.5px;
}

.rbox-1 .star-rating { 
  font-size: 0.86rem; 
  line-height: 1; 
}

p.rbox-2-txt {
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: 0;
}

p.rbox-2-txt span {
  font-weight: 500;
}




/* ==========================================================================
  13. TESTIMONIALS
  =========================================================================== */

.reviews-2-wrapper {
  padding: 0 10px;
}

.reviews-3-wrapper {
  padding: 0 20px;
}

.reviews-4-wrapper,
.reviews-5-wrapper {
  padding-left: 40px;
}

/*------------------------------------------*/
/*  TESTIMONIAL
/*------------------------------------------*/

.review-1 { 
  padding: 40px 40px 30px;
  margin: 0 10px 30px;                                
}

.review-1.block-border {
  border-color: #d2d2d2;
}

.review-2 { 
  padding: 45px 50px 35px;
}

.review-3 { 
  padding: 45px 50px 40px;
}

.review-4 { 
  padding: 40px 45px 30px;
}

.review-5 { 
  padding: 45px 45px 40px;
}

#rw-2-1, #rw-2-2 {
  margin-bottom: 20px;
}

.inner-page-hero #rw-2-3, .inner-page-hero #rw-2-4 {
  margin-bottom: 20px;
}

#rw-3-2 {
  margin-bottom: 45px;
}

#rw-3-2, #rw-3-3 {
  margin-left: 15px;
}

#rw-4-1,
#rw-5-1 {
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  TESTIMONIAL TEXT
/*------------------------------------------*/

.review-ico {   
  position: relative;
  margin-top: -25px;
  margin-left: -10px;
  opacity: .1;
}

.review-txt {
  position: relative;
  margin-top: -45px;
}

.review-txt h6 {
  margin-bottom: 15px;
}

/*------------------------------------------*/
/*  TESTIMONIAL DATA
/*------------------------------------------*/ 

.review-1 .author-data {
  margin-top: 22px;
}

.review-2 .author-data {
  margin-top: 15px;
}

.review-3 .author-data {
  margin-top: 20px;
}

.review-avatar {
  margin: 0 auto; 
  float: left;
}

.review-avatar img,
.owl-item .review-avatar img {
  width: 54px;
  height: 54px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.review-2 .review-avatar img {
  width: 64px;
  height: 64px;
}

.review-3 .review-avatar img,
.review-5 .review-avatar img {
  width: 62px;
  height: 62px;
}

/*------------------------------------------*/
/*  TESTIMONIAL AUTHOR
/*------------------------------------------*/ 

.review-author {
  position: relative;
  display: inline-block;
  text-align: left;
  padding: 0 0 0 15px;
}

.review-1 .review-author {
  margin-top: 4px;
}

.review-2 .review-author {
  margin-top: 10px;
}

.review-3 .review-author {
  margin-top: 8px;
} 

.review-5 .review-author {
  margin-top: 8px;
}   

.review-4 .review-author {
  padding-left: 0;
  margin-top: 0;
} 

.review-author h6 {
  line-height: 1;
  margin-bottom: 8px;
}

.review-1 .review-author h6 {
  font-size: 0.975rem;
}

.review-author p {
  line-height: 1;
  font-weight: 300;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  TESTIMONIAL CAROUSEL DOTS
/*------------------------------------------*/

.reviews-section button {
  background-color: transparent;
  border: none;
  padding: 0 2px;
}

.owl-theme .owl-dots .owl-dot span {
  margin: 0 7px;
}

.owl-dots button:focus {
  outline: none;
  text-decoration: none;
}




/* ==========================================================================
  14. PRESS
  =========================================================================== */

/*------------------------------------------*/
/*  PRESS LOGO
/*------------------------------------------*/ 

.press-logo img {
  position: relative;
  width: auto;
  max-width: inherit;
  max-height: 82px;
}

/*------------------------------------------*/
/*  PRESS TEXT
/*------------------------------------------*/ 

.press-txt {
  position: relative;
  margin-top: 15px;
}

.press-txt p {
  color: #999;
  font-weight: 400;
  margin-bottom: 0;
}

.press-txt span {   
  position: relative;
  top: 3px;
  left: -1px;
  opacity: .6;
}

.press-txt.ico-20 [class^="flaticon-"]:before, 
.press-txt.ico-20 [class^="flaticon-"]:after {
  font-size: 1.375rem;
}




/* ==========================================================================
  15. BRANDS
  =========================================================================== */

#brands-3 {
  position: relative;
}

#brands-3:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  top: 0;
  left: 0;
  z-index: -1;
}

/*------------------------------------------*/
/*  BRANDS SECTION TITLE
/*------------------------------------------*/ 

.brands-title {
  text-align: center;
  padding: 0 5%;
}

.brands-title h4,
.brands-title h3,
.brands-title h2 {
  margin-bottom: 8px;
}

.brands-title p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  BRANDS LOGO WRAPPER
/*------------------------------------------*/ 

.brands-3-wrapper {
  padding: 50px 30px;
  background-color: #fff;
  border: 1px solid #e9ecef;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; 
}

/*------------------------------------------*/
/*  BRANDS LOGO IMAGE
/*------------------------------------------*/ 

.brand-logo {
  overflow: hidden;
  position: relative;
  top: 0;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
} 

#brands-1 .brands-carousel-5 .brand-logo {
  padding: 0 30px;
} 

#brands-1 .brands-carousel-6 .brand-logo {
  padding: 0 20px;
}   

#brands-1 .justify-content-center .brand-logo {
  padding: 0 15px;
} 

#brands-2 .brand-logo {
  padding: 0;
} 

#brands-3 .brand-logo {
  padding: 0 25px;
}

#brands-3 .brands-3-static .brand-logo {
  padding: 0 15px;
}

#brands-4 .brand-logo {
  padding: 0 20px;
} 

.brand-logo:hover {
  top: -6px;
}

.brands-section .more-btn {
  text-align: center;
  margin-top: 40px;
}

.hero-section .owl-dots,
.brands-section .owl-dots {
  display: none;
}




/* ==========================================================================
  16. INTEGRATIONS
  =========================================================================== */

.integrations-2-wrapper {
  padding: 80px 70px;
}

.in_tool_logo {
  padding: 50px 30px;
  margin-bottom: 25px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

.integrations-1-wrapper .in_tool-logo {
  position: relative;
  margin-right: 20px;
}

.integrations-1-wrapper .in_tool {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch!important;
  justify-content: flex-start;
  padding: 22px 20px;
  border: 1px solid #ccc;
}

.integrations-1-wrapper .in_tool-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
}

.in_tool h6 {
  line-height: 1;
  margin-bottom: 0;
}

.integrations-1-wrapper .in_tool-txt h6 {
  margin-top: 5px;
}

.integrations-1-wrapper .in_tool-txt p {
  margin-top: 5px;
  margin-bottom: 0;
}

.integrations-1-wrapper .in_tool:hover {
  border-color: transparent;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
}




/* ==========================================================================
  17. PRICING
  =========================================================================== */

/*------------------------------------------*/
/*  Price Table
/*------------------------------------------*/

.p-table {
  margin-bottom: 40px;
}

.pricing-1-table { 
  padding: 50px 38px 45px; 
}

.text-center .pricing-1-table {
  padding: 60px 35px 50px; 
}

.pricing-2-table { 
  padding: 45px 38px 50px; 
}

.pricing-3-table { 
  padding: 50px 40px; 
}

.pricing-4-table {
  position: relative;
  padding: 45px;
}

#pt-4-1 { margin: 0 10px 40px 50px; }
#pt-4-2 { margin: 0 50px 40px 10px; }

/*------------------------------------------*/
/*  TABLE HEADER 
/*------------------------------------------*/

.pricing-table-header {
  position: relative;
}

.pricing-table-header h5 {
  margin-bottom: 30px;
}

.pricing-table-header h4 {
  margin-bottom: 25px;
}

/*------------------------------------------*/
/*  PRICING TABLE DISCOUNT
/*------------------------------------------*/

.pricing-discount {
  position: absolute;
  top: -2px;
  right: -10px;
  padding: 8px 18px;
}

.pricing-1-table .pricing-discount {
  top: -2px;
  right: -5px;
  padding: 7px 13px;
}

.text-center .pricing-1-table .pricing-discount {
  top: -35px;
  right: -8px;
}

.pricing-discount h6 {
  font-size: 1.1rem;
  line-height: 1;
  margin-bottom: 0;
}

.pricing-1-table .pricing-discount h6 {
  font-size: 0.915rem;
}

.price.color--black .white--color {
  color: #fff;
}

.comp-table .table thead th {
  border-color: #ccc;
}

.comp-table tbody th {
  border-bottom: 1px solid #ccc!important;
}

.comp-table .table tr td {
  border-bottom: 2px solid #ccc;
}

/*------------------------------------------*/
/*  PRICE
/*------------------------------------------*/

.price span {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 3rem;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -2px;
} 

.pricing-2-table.highlight .price span {
  font-size: 2.4rem;
  letter-spacing: -1px;
} 

.pricing-3-table .price span {
  font-size: 4.35rem;
} 

.pricing-4-table .price span {
  font-size: 3.15rem;
} 

/*------------------------------------------*/
/*  DOLLAR SIGN
/*------------------------------------------*/

.price sup {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 2.05rem;
  font-weight: 600;
  top: -5px;
  right: 2px;
  letter-spacing: -1px;
}

.pricing-3-table .price sup {
  font-size: 2.35rem;
  top: -23px;
  right: 3px;
}

.pricing-4-table .price sup {
  font-size: 2.05rem;
  top: -10px;
  right: 2px;
}

/*------------------------------------------*/
/*  COINS
/*------------------------------------------*/

.pricing-3-table .price sup.coins {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 2.35rem;
  font-weight: 600;
  top: -29px;
  left: 3px;
  letter-spacing: -2px;
}

/*------------------------------------------*/
/*  Validity
/*------------------------------------------*/

.price sup.validity {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  top: -2px;
  left: 0;
}

.pricing-3-table .price sup.validity {
  display: block;
  font-size: 1.1875rem;
  top: 0;
  margin-top: 20px;
  margin-bottom: 30px;
  letter-spacing: 0;
  margin-bottom: 40px;
}

/*------------------------------------------*/
/*  Text
/*------------------------------------------*/

.price p {
  margin-top: 25px;
  margin-bottom: 25px;
}

.pricing-2-table .price p {
  margin-bottom: 15px;
}

.text-center .price p {
  padding: 0 2%;
}

.pricing-section .btn-txt a {
  text-decoration: underline;
}

/*------------------------------------------*/
/*  PRICING TABLE HORIZONTAL LINE
/*------------------------------------------*/

.pricing-1-table hr {
  margin-top: 35px;
}

.pricing-2-table hr {
  margin-top: 25px;
}

/*------------------------------------------*/
/*  PRICING FEATURES
/*------------------------------------------*/

.pricing-features li {
  padding: 11px 8px;
}

.pricing-4-table .pricing-features li {
  padding: 10px;
} 

.pricing-features li p {
  line-height: 1;
  font-weight: 400;
  margin-bottom: 0;
}

.pricing-features li p span {
  position: relative;
  top: -2px;
  right: 5px;
}

.pricing-features.ico-10 li p span {
  top: 0;
  right: 4px;
}

.pricing-features.ico-5 [class*="flaticon-"]:before, 
.pricing-features.ico-5 [class*="flaticon-"]:after { font-size: 0.5rem; }

.pricing-features li.disabled-option p,
.pricing-features li.disabled-option span {
  color: #b5b5b5!important;
}

/*------------------------------------------*/
/*  PRICING TABLE BUTTON
/*------------------------------------------*/

.pt-btn {
  display: block;
  width: 100%;
  margin-top: 25px;
}

.p-table p.btn-txt {
  margin: 14px 0 0 0;
}

/*------------------------------------------*/
/*  PRICING TOGGLE BUTTON
/*------------------------------------------*/

.ext-toggle-btn {
  text-align: center;
}

.toggle-btn.inn-toggle-btn {
  position: absolute;
}

#pricing-4 .ext-toggle-btn {
  margin: 0 50px 35px 10px;
}

/*------------------------------------------*/
/*  TOGGLE BUTTON TYPOGRAPHY
/*------------------------------------------*/

.toggle-btn-md .toggler-txt {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 36px;
}

.toggle-btn-lg .toggler-txt {
  font-size: 1.15rem;
  font-weight: 400;
  line-height: 40px;
}

.toggle-btn p {
  margin-top: 10px;
  letter-spacing: -0.25px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  TOGGLE BUTTON SWITCHER
/*------------------------------------------*/

.switch-wrap {
  position: relative;
  display: inline-block;
  min-width: 50px;
  height: 32px;
  vertical-align: middle;
}

.toggle-btn-md .switch-wrap {
  min-width: 65px;
  height: 36px;
  margin: 0 8px;
}

.toggle-btn-lg .switch-wrap {
  min-width: 70px;
  height: 40px;
  margin: 0 14px;
}

.switcher {
  display: block;
  cursor: pointer;
  color: #fff;
  font-size: 0.925rem;
  font-weight: 500;
  height: 32px;
  padding: 0 15px 0 35px;
  position: relative;
  border-radius: 32px;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

.toggle-btn-md .switcher {
  height: 36px;
  padding: 0 20px 0 40px;
  border-radius: 36px;
}

.toggle-btn-lg .switcher {
  height: 40px;
  padding: 0 20px 0 40px;
  border-radius: 40px;
}

.switcher.bg--tra-white {
  background-color: #fff;
  border: 2px solid #ccc;
}

.switcher.bg--ghost-white {
  background-color: rgba(128, 143, 174, .45);
  border: 2px solid rgba(128, 143, 174, .15);
}

.switcher.bg--grey {
  background-color: #ccc;
  border: 2px solid #ccc;
}

.switcher .show-annual, 
.switcher .show-monthly {
  line-height: 32px;
}

.toggle-btn-md .switcher .show-annual,
.toggle-btn-md .switcher .show-monthly {
  line-height: 36px;
}

.toggle-btn-lg .switcher .show-annual,
.toggle-btn-lg .switcher .show-monthly {
  line-height: 40px;
}

.switcher .show-monthly {
  display: block;
}

.switcher .show-annual {
  display: none;
}

.switcher:before {
  position: absolute;
  left: 4px;
  bottom: 4px;
  content: " ";
  background-color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.toggle-btn-md .switcher:before {
  left: 5px;
  bottom: 5px;
  width: 26px;
  height: 26px;
}

.toggle-btn-lg .switcher:before {
  left: 5px;
  bottom: 5px;
  width: 30px;
  height: 30px;
}

.switcher.bg--grey:before,
.switcher.bg--tra-white:before,
.switcher.bg--ghost-white:before {
  background-color: #ccc;
  left: 4px;
  bottom: 3.5px;
  -webkit-box-shadow: 0 1px 1px 0 #aaa;
  -moz-box-shadow: 0 1px 1px 0 #aaa;
  box-shadow: 0 1px 1px 0 #aaa;
}

.switcher.bg--grey:before,
.switcher.bg--ghost-white:before {
  background-color: #fff;
}

.switch-wrap input {
  display: none;
}

.switch-wrap input:checked + .switcher {
  padding: 0 35px 0 15px;
}

.toggle-btn-md .switch-wrap input:checked + .switcher,
.toggle-btn-lg .switch-wrap input:checked + .switcher {
  padding: 0 40px 0 20px;
}

.switch-wrap input:checked + .switcher.switcher--blue-500 { background-color: #185abd; border-color: #185abd; }
.switch-wrap input:checked + .switcher.switcher--blue-400 { background-color: #1680fb; border-color: #1680fb; }
.switch-wrap input:checked + .switcher.switcher--blue-300 { background-color: #3eb1ff; border-color: #3eb1ff; }

.switch-wrap input:checked + .switcher.switcher--green-500 { background-color: #00a14e; border-color: #00a14e; }
.switch-wrap input:checked + .switcher.switcher--green-400 { background-color: #0fbc49; border-color: #0fbc49; }
.switch-wrap input:checked + .switcher.switcher--green-300 { background-color: #6fd792; border-color: #6fd792; }

.switch-wrap input:checked + .switcher.switcher--pink-500 { background-color: #ff149c; border-color: #ff149c; }
.switch-wrap input:checked + .switcher.switcher--pink-400 { background-color: #f74780; border-color: #f74780; }
.switch-wrap input:checked + .switcher.switcher--pink-300 { background-color: #fcb5cc; border-color: #fcb5cc; }

.switch-wrap input:checked + .switcher.switcher--purple-500 { background-color: #663ae9; border-color: #663ae9; }
.switch-wrap input:checked + .switcher.switcher--purple-400 { background-color: #7b5dd6; border-color: #7b5dd6; }
.switch-wrap input:checked + .switcher.switcher--purple-300 { background-color: #bdaeea; border-color: #bdaeea; }

.switch-wrap input:checked + .switcher.switcher--red-500 { background-color: #da0f29; border-color: #da0f29; }
.switch-wrap input:checked + .switcher.switcher--red-400 { background-color: #ff1e1a; border-color: #ff1e1a; }
.switch-wrap input:checked + .switcher.switcher--red-300 { background-color: #ffa5a3; border-color: #ffa5a3; }

.switch-wrap input:checked + .switcher.switcher--violet-500 { background-color: #c73e9b; border-color: #c73e9b; }
.switch-wrap input:checked + .switcher.switcher--violet-400 { background-color: #be6cbe; border-color: #be6cbe; }
.switch-wrap input:checked + .switcher.switcher--violet-300 { background-color: #e2bde2; border-color: #e2bde2; }

.switch-wrap input:checked + .switcher.switcher--yellow-500 { background-color: #ffba01; border-color: #ffba01; }
.switch-wrap input:checked + .switcher.switcher--yellow-400 { background-color: #ffbd45; border-color: #ffbd45; }
.switch-wrap input:checked + .switcher.switcher--yellow-300 { background-color: #ffdf35; border-color: #ffdf35; }

.switch-wrap input:checked + .switcher:before {
  left: auto;
  right: 4px;
  -webkit-transition: all 350ms ease-in-out;
  -moz-transition: all 350ms ease-in-out;
  -o-transition: all 350ms ease-in-out;
  -ms-transition: all 350ms ease-in-out;
  transition: all 350ms ease-in-out;
}

.switch-wrap input:checked + .switcher.bg--tra-white:before {
  bottom: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0 0;
  -moz-box-shadow: 0 0;
  box-shadow: 0 0;
}

.switch-wrap input:checked + .switcher .show-annual {
  display: block;
}

.switch-wrap input:checked + .switcher .show-monthly {
  display: none;
}

/*------------------------------------------*/
/*  PRICING NOTICE TEXT
/*------------------------------------------*/

.pricing-notice {
  text-align: center;
  margin-top: 20px;
}

.pricing-notice p {
  padding: 0 8%;
  margin-bottom: 0;
}

.pricing-notice p span {
  font-weight: 500;
}

/*------------------------------------------*/
/*  COMPARE PRICING TABLE
/*------------------------------------------*/

.table>:not(caption)>*>* {
  padding-top: 12px;
  padding-bottom: 12px;
}

.comp-table .pricing-header {
  max-width: 700px;
}

.comp-table .table-responsive thead th {
  font-size: 1.15rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
}

.comp-table .table-responsive tbody th {
  font-size: 1rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
}

.comp-table .table-responsive tbody td {
  position: relative;
  font-size: 1rem;
  line-height: inherit;
  top: 1px;
}

.comp-table .table-responsive tbody td span {
  position: relative;
  top: 1px;
}

.table-responsive .ico-15 [class*="flaticon-"]:before, 
.table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.75rem; }

.table-responsive .ico-20 [class*="flaticon-"]:before, 
.table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.95rem; }

.comp-table .table-responsive tbody tr {
  background-color: #fff;
  -moz-transition: all 350ms ease-in-out;
  -o-transition: all 350ms ease-in-out;
  -ms-transition: all 350ms ease-in-out;
  transition: all 350ms ease-in-out;
}

.comp-table .table tbody tr.table-last-tr {
  border-color: transparent;
}

.comp-table .table-responsive tbody tr:hover {
  background-color: #f7f7f9;
}

.comp-table .disabled-option {
  color: #999;
}

/*------------------------------------------*/
/*  PRICING COMPARE PAYMENT
/*------------------------------------------*/

.comp-table-payment h6 {
  margin-bottom: 20px;
}

.comp-table-payment p {
  margin-bottom: 0;
}




/* ==========================================================================
  18. TEAM
  =========================================================================== */

/*------------------------------------------*/
/*  TEAM MEMBER
/*------------------------------------------*/

#tm-2-1, #tm-2-4, #tm-2-7, #tm-2-10 { margin-right: 14px; }
#tm-2-2, #tm-2-5, #tm-2-8, #tm-2-11 { margin-left: 7px; margin-right: 7px; }
#tm-2-3, #tm-2-6, #tm-2-9, #tm-2-12 { margin-left: 14px; }

/*------------------------------------------*/
/*  TEAM MEMBER PHOTO
/*------------------------------------------*/

.team-member-photo {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  PHOTO HOVER ZOOM
/*------------------------------------------*/ 

.team-member:hover img {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.team-member:hover .item-overlay {
  opacity: .45; 
  -moz-opacity: .45; 
}

/*------------------------------------------*/
/*  TEAM MEMBER TYPOGRAPHY
/*------------------------------------------*/

.team-member-data {
  position: relative;
  margin-left: 5px;
}

.team-member-data h6.s-20 {
  margin-bottom: 8px;
}

.team-member-data p {
  font-family: 'Plus Jakarta Sans', sans-serif;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Team Member Social Links
/*------------------------------------------*/

ul.tm-social {
  position: absolute;
  top: -1px;
  right: 8px;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;  
}

#team-1 ul.tm-social {
  right: 5px;
}

ul.tm-social li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  padding: 0;
  margin-left: 10px;
  opacity: .6;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;  
}

.tm-social.ico-20 [class*="flaticon-"]:before, 
.tm-social.ico-20 [class*="flaticon-"]:after { font-size: 1.15rem; }




/* ==========================================================================
  19. CAREERS
  =========================================================================== */

/*------------------------------------------*/
/*  ROLE BOX
/*------------------------------------------*/

.role-box {
  border: 1px solid #d2d2d2;
  padding: 25px 35px;
  margin-bottom: 30px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

.role-box.bg--white-200,
.role-box.bg--white-300 {
  border: 1px solid transparent;
}

.role-box:hover {
  border-color: transparent;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
}

/*------------------------------------------*/
/*  ROLE BOX TEXT
/*------------------------------------------*/

.role-box span {
  font-family: 'Plus Jakarta Sans', sans-serif;
  display: block;
  font-size: 0.8rem;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 14px;
}

.role-box h6 {
  margin-bottom: 6px;
}

.role-box p {
  margin-bottom: 0;
}




/* ==========================================================================
  20. CAREER ROLE
  =========================================================================== */

#role-page .inner-page-title {
  text-align: left;
  margin-bottom: 50px;
}

#role-page .inner-page-title span {
  font-family: 'Plus Jakarta Sans', sans-serif;
  display: block;
  font-size: 0.9rem;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 35px;
}

.role-info hr {
  margin: 50px 0;
}

#role-page .cbox-6-wrapper {
  margin: 45px 0;
}

.role-info h5 {
  margin-bottom: 25px;
}

.role-info p a {
  font-weight: 500;
  text-decoration: underline;
}

.txt-block.role-info .btn {
  display: block;
  margin-top: 50px;
}




/* ==========================================================================
  21. BANNER
  =========================================================================== */

.banner-overlay {
  width: 100%;
  height: 100%;
}

#banner-11 {
  margin-top: 50px;
}

#banner-12 {
  margin-top: 40px;
  margin-bottom: 10px;
}

/*------------------------------------------*/
/*  BANNER WRAPPER
/*------------------------------------------*/

.banner-1-wrapper,
.banner-2-wrapper,
.banner-3-wrapper {
  position: relative;
  overflow: hidden;
  text-align: center;
} 

.banner-1-wrapper .banner-overlay {
  padding: 75px 15% 85px;
}

.banner-2-wrapper .banner-overlay {
  padding: 80px 15% 90px;
}

.banner-3-wrapper .banner-overlay {
  padding: 75px 20% 85px;
}

.banner-11-wrapper .banner-overlay {
  padding: 10px 70px 0;
}

.banner-12-wrapper .banner-overlay {
  padding-left: 80px;
}

.banner-13-wrapper {
  overflow: hidden;
}

.banner-13-wrapper .banner-overlay {
  overflow: hidden;
  padding-left: 80px;
}

.banner-16-wrapper .banner-overlay {
  padding: 45px 22%;
}

.banner-16-wrapper.bg--white-100.block-border {
  border-color: #ccc;
}

/*------------------------------------------*/
/*  BANNER TEXT
/*------------------------------------------*/

.banner-12-txt {
  margin-top: -10px;
  padding-right: 15%;
}

.banner-13-txt {
  padding-right: 15%;
}

/*------------------------------------------*/
/*  BANNER TYPOGRAPHY
/*------------------------------------------*/

.banner-section .section-id {
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: 30px;
}

.banner-1-txt h2 {
  margin-bottom: 15px;
}

.banner-2-txt h2 {
  margin-bottom: 35px;
}

.banner-4-txt h2 {
  margin-bottom: 30px;
}

.banner-7-txt h2 {
  margin-bottom: 35px;
}

.banner-10-txt h6 {
  font-weight: 400;
  margin-bottom: 12px;
}

.banner-10-txt h2 {
  margin-bottom: 25px;
}

.banner-11-txt h2,
.banner-12-txt h2,
.banner-13-txt h2 {
  margin-bottom: 22px;
}

.banner-15-txt h2 {
  margin-bottom: 45px;
}

.banner-16-txt h4 {
  margin-bottom: 14px;
}

.banner-section p.p-lg {
  font-size: 1.15rem; 
  margin-bottom: 30px;
}

.banner-section p.p-xl {
  margin-bottom: 25px;
}

.sm-title .banner-1-txt p.p-lg,
.sm-title .banner-1-txt p.p-xl {
  margin-top: 12px;
  margin-bottom: 22px!important;
}

.banner-3-txt p.p-lg,
.banner-3-txt p.p-xl,
.banner-5-txt p.p-lg,
.banner-5-txt p.p-xl {
  margin-top: 20px;
}

.banner-5-txt p.s-21,
.banner-5-txt p.s-22 {
  margin-top: 20px;
  margin-bottom: 26px;
}

.banner-6-txt p.p-lg,
.banner-6-txt p.p-xl {
  margin-top: 15px;
  margin-bottom: 25px!important;
}

.banner-8-txt p.p-lg,
.banner-8-txt p.p-xl {
  margin-top: 10px;
  margin-bottom: 0!important;
}

.banner-9-txt p.p-lg,
.banner-9-txt p.p-xl {
  margin-top: 20px;
  margin-bottom: 25px!important;
}

.banner-11-txt p {
  padding-right: 8%;
}

.banner-14-txt p.p-lg {
  font-size: 1.25rem;
  margin-top: 35px;
  margin-bottom: 40px;
}

.banner-15-txt p a {
  text-decoration: underline;
}

/*------------------------------------------*/
/*  BANNER IMAGE
/*------------------------------------------*/

.banner-11-img {
  margin-top: -60px;
  margin-left: -40px;
}

.banner-12-img {
  margin: -40px -20px -30px -40px;
}

.banner-13-img {
  transform: rotate(-20deg);
  margin: -60px -120px -70px -40px;
}




/* ==========================================================================
  22. CALL TO ACTION / DOWNLOAD
  =========================================================================== */

.version-release {
  margin-bottom: 80px;
}

.release-data {
  padding-bottom: 30px;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 30px;
}

.release-data h5 {
  margin-bottom: 40px;
}

span.version-data {
  color: #353f4f;
  font-size: 2rem;
  line-height: 1;
  margin-right: 20px;
  margin-bottom: 0;
}

span.release-date {
  color: #999;
  font-size: 1.175rem;
  line-height: 1;
  margin-bottom: 0;
}

span.release-date span {
  color: #353f4f;
  font-weight: 500;
  margin-left: 20px;
}

.release-download {
  position: absolute;
  right: 5px;
  bottom: 0;
}

.release-highlights .simple-list p {
  margin-bottom: 2px;
}




/* ==========================================================================
  23. FAQS
  =========================================================================== */

/*------------------------------------------*/
/*  FAQS SECTION TITLE 
/*------------------------------------------*/

#faqs-1 .section-title {
  margin-bottom: 50px;
}

#faqs-2 .inner-page-title {
  margin-bottom: 55px;
}

#faqs-3 .section-title {
  margin-bottom: 70px;
}

.faqs-3-questions .questions-holder {
  padding: 0 10px;
}

/*------------------------------------------*/
/*  FAQS ACCORDION  
/*------------------------------------------*/

.faqs-section .accordion-item {
  background-color: transparent!important;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

#faqs-2.faqs-section .accordion-item {
  border-bottom: 1px solid #ddd;
}

.faqs-section .accordion-item.acc-last-item,
#faqs-2.faqs-section .accordion-item.acc-last-item {
  border-bottom: none;
}

/*------------------------------------------*/
/*  Question
/*------------------------------------------*/

.faqs-section .accordion-thumb {
  margin: 0;
  padding: 25px 0;
  cursor: pointer;
  position: relative;
}

#faqs-2.faqs-section .accordion-thumb {
  padding: 25px 0 30px;
}

.faqs-section .accordion-item .accordion-thumb:after,
.faqs-section .accordion-item.is-active .accordion-thumb:after {
  font-family: Flaticon;
  font-weight: 300; 
  content: "\f11a";
  position: absolute;
  font-size: 1rem;
  top: 22px;
  right: 3px;
}

#faqs-2.faqs-section .accordion-item .accordion-thumb:after,
#faqs-2.faqs-section .accordion-item.is-active .accordion-thumb:after {
  font-size: 1rem;
  top: 26px;
  right: 2px;
}

.faqs-section .accordion-item.is-active .accordion-thumb:after {
  content: "\f11b";
}

#faqs-2.faqs-section .accordion-item.is-active .accordion-thumb {
 border-bottom: 1px solid #e5e5e5;
}

/*------------------------------------------*/
/*  Answer
/*------------------------------------------*/

.accordion-panel {
  margin: 0;
  padding: 0 0 15px 0;
  display: none;
}

#faqs-2 .accordion-panel {
  padding: 45px 0 15px 0;
}

.accordion-thumb h5,
.accordion-thumb h4 {
  line-height: 1;
  margin-bottom: 0;
}

.question h5,
.accordion-panel-item h5 {
  margin-bottom: 20px;
}

.question h5 span,
.accordion-thumb h5 span,
.accordion-panel-item h5 span {
  margin-right: 5px;
}

.question p a, 
.accordion-panel p a {
  font-weight: 500;
  text-decoration: underline;
}

.question p.color--grey a.color--blue, 
.accordion-panel.color--grey a.color--blue {
  color: #1680fb!important;
}

/*------------------------------------------*/
/*  FAQS TABS 
/*------------------------------------------*/

.faqs-section .tabs-nav { 
  text-align: center;
}

.faqs-section .tabs-1 { 
  display: inline-block;
  margin: 0 auto 60px;
}

.faqs-section .tab-content {
  display: none;
}

.faqs-section .tab-content.current {
  display: inherit;
}

.faqs-section .tabs-1 li {
  display: inline-block; 
  min-width: 230px;
  background-color: transparent;
  border: 2px solid #ddd;
  cursor: pointer;
  padding: 30px 0;
  margin: 0 6px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

.faqs-section .tabs-1 li span {
  color: #c3c4c5;
}

.faqs-section .tabs-1 li p {
  line-height: 1;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  TAB LINK CURRENT
/*------------------------------------------*/

.faqs-section .tabs-1 li.current,
.faqs-section .tabs-1 li.current:hover {
  -webkit-box-shadow: 0px 15px 20px 0px rgba(5, 5, 5, .2);
  -moz-box-shadow: 0px 15px 20px 0px rgba(5, 5, 5, .2);
  box-shadow: 0px 15px 20px 0px rgba(5, 5, 5, .2);
}

.faqs-section .tabs-1 li.current p,
.faqs-section .tabs-1 li.current span {
  color: #fff;
}

.faqs-section .tabs--blue .tabs-1 li.current,
.faqs-section .tabs--blue .tabs-1 li.current:hover {
  background-color: #1680fb;
  border-color: #1680fb!important;
}

.faqs-section .tabs--purple .tabs-1 li.current,
.faqs-section .tabs--purple .tabs-1 li.current:hover {
  background-color: #7b5dd6;
  border-color: #7b5dd6!important;
}

/*------------------------------------------*/
/*  MORE QUESTIONS LINK
/*------------------------------------------*/

.more-questions {
  text-align: center;
}

#faqs-2 .more-questions {
  margin: 70px auto 0; 
}

.more-questions-txt {
  display: inline-block;
  padding: 22px 46px;
}

.more-questions-txt p {
  line-height: 1;
  margin-bottom: 0;
}

.more-questions-txt a {
  font-weight: 500;
  text-decoration: underline;
}




/* ==========================================================================
  24. NEWSLETTER
  =========================================================================== */

#newsletter-1 .newsletter-overlay {
  padding-top: 60px;
  padding-bottom: 60px;
}  

#newsletter-2 .container {
  padding-left: 0;
  padding-right: 0;
}

#newsletter-2 .newsletter-overlay {
  padding: 60px 50px;
}

#newsletter-3 {
  margin-top: 50px;
  margin-bottom: 50px;
}

#newsletter-4 .newsletter-overlay,
#newsletter-5 .newsletter-overlay {
  padding-top: 80px;
  padding-bottom: 70px;
}

/*------------------------------------------*/
/*  NEWSLETTER TYPOGRAPHY
/*------------------------------------------*/ 

.newsletter-txt h2 {
  margin-bottom: 35px;
}

#newsletter-4 .newsletter-txt h2 {
  margin-bottom: 40px;
}

.newsletter-txt h4,
.newsletter-txt h3,
#newsletter-5 .newsletter-txt h2 {
  margin-bottom: 0;
}

.newsletter-txt p.p-sm {
  margin-bottom: 0;
}

#newsletter-4 .newsletter-txt p {
  padding: 0 10%;
}

#newsletter-5 .newsletter-txt p {
  margin-top: 20px;
  margin-bottom: 0;
}

.newsletter-txt a {
  font-weight: 500;
  text-decoration: underline;
}

/*------------------------------------------*/
/*  NEWSLETTER FORM
/*------------------------------------------*/ 

#newsletter-1 .newsletter-form {
  padding-left: 15%;
  margin-top: 30px;
}

#newsletter-2 .newsletter-form {
  margin-top: 25px;
  padding-left: 12%;
}

#newsletter-4 .newsletter-form {
  padding: 0 5%;
}

#newsletter-5 .newsletter-form {
  margin-top: 35px;
  padding: 0 10%;
}

/*------------------------------------------*/
/*   Newsletter Form Input
/*------------------------------------------*/

.newsletter-section .form-control {
  height: 56px;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 1rem;
  color: #353f4f;
  font-weight: 400;
  padding: 0 15px;  
  margin-right: 12px;
  box-shadow: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
}

#newsletter-1.newsletter-section .form-control {
  height: 54px;
  font-size: 0.975rem;
}

#newsletter-5.newsletter-section .form-control {
  height: 62px;
  font-size: 1.0625rem;
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

/*------------------------------------------*/
/*  Newsletter Form Input Focus
/*------------------------------------------*/

.newsletter-section .form-control:focus {
  background-color: #fff;
  outline: 0;
  box-shadow: none;
  border-color: #1680fb;
}

.newsletter-section .purple-focus .form-control:focus {
  border-color: #8863ee;
}

/*------------------------------------------*/
/*  Newsletter Form Input Placeholder
/*------------------------------------------*/

.newsletter-section .form-control::-moz-placeholder { color: #aaa; } 
.newsletter-section .form-control:-ms-input-placeholder { color: #aaa; } 
.newsletter-section .form-control::-webkit-input-placeholder { color: #aaa; } 

/*------------------------------------------*/
/*  Newsletter Form Button
/*------------------------------------------*/

.newsletter-section .btn {
  display: block; 
  width: 100%!important;
  height: 56px;
}

#newsletter-1 .btn {
  height: 54px;
  font-size: 0.925rem;
  padding: 0.7rem 1.3rem;
}

#newsletter-5.newsletter-section .btn {
  height: 62px;
}

/*------------------------------------------*/
/*  Newsletter Form Notification
/*------------------------------------------*/

.newsletter-section .form-notification {
  color: #3eb1ff;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 15px;
  margin-left: 5px;
}

.newsletter-section .text-center .form-notification {
  margin-left: 0;
}

.bg--color.newsletter-section .form-notification {
  color: #fff;
}

.newsletter-section .form-notification.valid,
.newsletter-section .form-notification.error {
  color: #fc2f4b;
}

.newsletter-section .newsletter-form.valid {
  color: #0fbc49;
}

/*------------------------------------------*/
/*  NEWSLETTER IMAGE
/*------------------------------------------*/

#newsletter-3 .newsletter-img {
  margin-top: -50px;
  margin-bottom: -50px;
}

#newsletter-3 .col-lg-6 .newsletter-img {
  padding-left: 40px;
}



/* ==========================================================================
  25. BLOG
  =========================================================================== */

/*------------------------------------------*/
/*  BLOG POST TAG
/*------------------------------------------*/

.post-tag {
  display: block;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: 20px;
}

#single-post .post-tag {
  font-size: 0.85rem;
}

/*------------------------------------------*/
/*  BLOG POST META
/*------------------------------------------*/

.post-meta-list li {
  width: auto!important;
  display: inline-block!important;
  vertical-align: top;
  clear: none !important;
}

.post-meta-list p {
  color: #353f4f;
  line-height: 1;
  margin-bottom: 0;
}

.bg--black-400 .post-meta-list p {
  color: #f8f9f9;
}

.meta-list-divider {
  position: relative;
  top: 0px;
  transform: rotate(-90deg);
  padding: 0 1px;
}

/*------------------------------------------*/
/*  BLOG POST TYPOGRAPHY
/*------------------------------------------*/

.blog-post-txt h6 {
  margin-bottom: 15px;
}

.blog-post-txt p {
  margin-bottom: 0;
}

.blog-post-txt a {
  color: #353f4f;
}

.blog-post-txt a:hover {
  text-decoration: underline;
}

.bg--black-400 .blog-post-txt a {
  color: #f8f9f9;
}

.bg--black-400 .blog-post-txt a:hover {
  color: #fff!important;
}




/* ==========================================================================
  26. BLOG LISTING
  =========================================================================== */

.wide-post {
  margin-bottom: 100px;
}

.wide-post .blog-post-img {
  padding-right: 15px;
}

.wide-post .blog-post-txt {
  padding-left: 15px;
}

.square-post {
  margin-top: 60px;
  margin-bottom: 40px;
}

.square-post-wrapper {
  overflow: hidden;
}

.square-post .blog-post-txt {
  padding: 0 30px 0 60px;
}

/*------------------------------------------*/
/*  BLOG POSTS CATEGORY
/*------------------------------------------*/

.posts-category {
  margin-top: 40px;
  margin-bottom: 60px;
}

.posts-category h4 {
  line-height: 1;
  margin-bottom: 0;
}

.posts-category span {
  position: relative;
  top: 1px;
}




/* ==========================================================================
  27. SINGLE BLOG POST
  =========================================================================== */

/*------------------------------------------*/
/*  Single Post Title
/*------------------------------------------*/

.single-post-title h3,
.single-post-title h2 {
  padding: 0 10px;
}

/*------------------------------------------*/
/*  Single Post Text
/*------------------------------------------*/

.single-post-txt h5,
.single-post-txt h4 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.single-post-txt h5 span {
  display: block;
}

/*------------------------------------------*/
/*  Single Post Code
/*------------------------------------------*/

.txt-code {
  background-color: #f8f9fa;
  padding: 20px 18px;
  margin: 30px 0;
}

.txt-code p {
  color: #383a42; 
  font-size: 1rem;
  line-height: 1.75;
  margin-bottom: 0;
}

.single-post-txt p a {
  font-weight: 500;
  text-decoration: underline;
}

.txt-code span.code-property,
.txt-code p code.code-language-js {
  color: #151515;
}

.txt-code span.code-variable,
.txt-code span.code-attribute {
  color: #986801;
}

.txt-code span.code-string {
  color: #50a14f;
}

/*------------------------------------------*/
/*  Single Post Blockquote
/*------------------------------------------*/

.single-post-txt figure {
  margin-bottom: 40px;
}

.single-post-txt .blockquote p {
  font-size: 1.5rem;
  line-height: 1.45;
  margin-bottom: 40px;
}

.single-post-txt .blockquote-footer {
  font-size: 1.125rem;
}

/*------------------------------------------*/
/*  Single Post Image
/*------------------------------------------*/

.post-inner-img {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

/*------------------------------------------*/
/*  POST SHARE LINKS
/*------------------------------------------*/ 

.post-share-list {
  margin-top: 50px;
  padding-bottom: 50px;
}

/*------------------------------------------*/
/*  POST SHARE ICONS
/*-----------------------------------------*/

.share-social-icons {
  display: inline-block; 
  padding-left: 0;
}

.share-social-icons li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  padding: 0;
}

.share-social-icons a.share-ico span {
  opacity: .75;
  margin-right: 25px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.share-social-icons a.share-ico:hover span {
  opacity: 1;
}

/*------------------------------------------*/
/*  POST AUTHOR
/*------------------------------------------*/ 

.post-author {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch!important;
  justify-content: flex-start;
}

.post-author-avatar {
  position: relative;
  text-align: center;
  margin-right: 35px;
}

.post-author-avatar img {  
  width: 100px;
  height: 100px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;  
}

.post-author-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
}

.post-author-txt h5 {
  margin-bottom: 20px;
}

.post-author-txt p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Follow Button
/*------------------------------------------*/ 

.author-follow-btn {
  display: inline-block;
  position: absolute;
  padding: 6px 15px;
  line-height: 1;
  top: 80px;
  right: 10px;
  border: 2px solid #ddd;
}

.author-follow-btn a {
  color: #666;
  font-size: 0.935rem;
  font-weight: 400;
}

/*------------------------------------------*/
/*  POST COMMENTS
/*------------------------------------------*/

.post-comments {
  padding-top: 100px;
}

.post-comments hr {
  margin-top: 24px; 
  margin-bottom: 40px; 
}

.post-comments h5 {
  margin-bottom: 60px;
}

.post-comments img {
  width: 68px;
  height: 68px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.comment-body {
  margin-left: 25px;
}

.comment-meta {
  margin-bottom: 10px;
}

.comment-meta h6 {
  line-height: 1;
  margin-bottom: 0;
}

.comment-date {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}

/*------------------------------------------*/
/*  Comment Reply 
/*------------------------------------------*/

.btn-reply {
  margin-left: 3px;
}

.btn-reply a {
  font-size: 17px;
  font-weight: 400; 
}

.btn-reply span {
  position: relative;
  top: 3px;
  margin-right: 4px;
  left: 3px;
}

.btn-reply.ico-20 [class*="flaticon-"]:before, 
.btn-reply.ico-20 [class*="flaticon-"]:after { font-size: 0.975rem; }

.btn-reply a:hover {
  color: #222;
}

/*------------------------------------------*/
/*  SINGLE POST COMMENT FORM
/*------------------------------------------*/

.post-comments #leave-comment h5 {
  padding-bottom: 0;
  margin-top: 80px;
  margin-bottom: 10px;
  border: none;
}

.comment-form {
  position: relative;
  margin-top: 60px;
}

.comment-form p {
  position: relative;
  color: #353f4f;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 15px;
  padding-left: 8px;
  display: block;
}

.comment-form p span {
  position: relative;
  color: #ff3366;
  top: -3px;
}

.comment-form .form-control {
  height: 62px;
  background-color: #f5f6f8;
  border: 1px solid transparent;
  box-shadow: 0 0 0 0;
  color: #353f4f;
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
  padding: 0 20px;
  margin-bottom: 30px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.comment-form textarea { min-height: 220px; }
.comment-form textarea.form-control { 
  padding: 25px 20px;
}

/*------------------------------------------*/
/*  Comment Form Input Focus
/*------------------------------------------*/

.comment-form .form-control:focus {
  outline: 0px none;
  box-shadow: none;
  background-color: #fcfdfd;
  border-color: #1680fb;
}

/*------------------------------------------*/
/*  Comment Form Button
/*------------------------------------------*/

.comment-form .btn {
  margin-top: 15px;
}

.comment-form .btn:focus {
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Comment Form Message
/*------------------------------------------*/

.comment-form-msg {
  width: 100%!important;
  display: block;
  margin-top: 15px;
  padding-left: 0;
}

.comment-form .loading {
  color: #00b2e4;
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 500;
}

.comment-form .error {
  color: #e74c3c;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 10px;
}




/* ==========================================================================
  28. CONTACTS
  =========================================================================== */

#contacts-1 .contact-form p {
  color: #353f4f;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 8px;
}

#contacts-1 .contact-form .col-md-12 span {
  display: block;
  font-size: 0.95rem;
  line-height: 1;
  font-weight: 300;
  margin-bottom: 20px;
  padding-left: 8px;
}

#contacts-1 .contact-form .contact-form-notice p {
  font-size: 0.835rem;
  font-weight: 300;
  line-height: 1.5555;
  padding-left: 0;
  margin-top: 40px;
}

.contact-form-notice p a {
  color: #353f4f;
  font-weight: 500;
  text-decoration: underline;
}

/*------------------------------------------*/
/*  CONTACT FORM INPUT
/*------------------------------------------*/

.contact-form .form-control,
.contact-form .form-select {
  height: 62px;
  background-color: #f5f6f8;
  border: 1px solid transparent;
  box-shadow: 0 0 0 0;
  color: #3a4a56;
  font-size: 1rem;
  font-weight: 400;
  padding: 0 15px;
  margin-bottom: 30px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.bg--snow .contact-form .form-control,
.bg--snow .contact-form .form-select {
  background-color: #fff;
  border: 1px solid #ccc;
}

.contact-form .form-select {
  display: inline-block;
  width: 100%;
  color: #998da0;
  box-shadow: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

/*------------------------------------------*/
/*  Contact Form Textarea
/*------------------------------------------*/

.contact-form textarea { min-height: 220px; }
.contact-form textarea.form-control { 
  padding: 20px 15px;
}

/*------------------------------------------*/
/*  Contact Form Placeholder
/*------------------------------------------*/

.contact-form .form-control::-moz-placeholder { color: #999; } 
.contact-form .form-control:-ms-input-placeholder { color: #999; }
.contact-form .form-control::-webkit-input-placeholder { color: #999; }

/*------------------------------------------*/
/*  Contact Form Input Focus
/*------------------------------------------*/

.contact-form .form-control:focus,
.contact-form .form-select:focus {
  outline: 0px none;
  box-shadow: none;
  background-color: #fcfdfd;
  border-color: #1680fb;
}

/*------------------------------------------*/
/*  Contact Form Message
/*------------------------------------------*/

.contact-form-msg {
  width: 100%!important;
  display: block;
  margin-top: 20px;
  padding-left: 0;
}

.contact-form .loading {
  color: #00b2e4;
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 500;
  padding-left: 15px;
}

.contact-form  .error {
  color: #e74c3c;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 10px;
}




/* ==========================================================================
  29. FOOTER
  =========================================================================== */

.footer {
  padding-bottom: 50px;
}

.footer-info,
.footer-links {
  margin-bottom: 40px;
}

#footer-10 .footer-info {
  margin-bottom: 40px;
  padding-bottom: 55px;
  border-bottom: 1px solid #dbdddf;
}

#footer-10 .footer-links {
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  FOOTER LOGO
/*------------------------------------------*/

img.footer-logo,
img.footer-logo-dark {
  width: auto;
  max-width: inherit;
  max-height: 40px;
}

#footer-10 img.footer-logo {
  max-height: 46px;
}

#footer-1 .footer-logo,
#footer-5 .footer-logo,
#footer-6 .footer-logo,
#footer-7 .footer-logo {
  margin-bottom: 25px;
}

/*------------------------------------------*/
/*  FOOTER TYPOGRAPHY
/*------------------------------------------*/

.footer h6 {
  line-height: 1;
  margin-bottom: 25px;
}

#footer-9.footer h6 {
  line-height: 1.5;
}

.bg--black.footer h6 {
  color: #fff;
}

/*------------------------------------------*/
/*  FOOTER INFO
/*------------------------------------------*/

.footer-info-copyright p {
  margin-bottom: 0;
}

.footer-info-copyright p span {
  position: relative;
  top: 2px;
  padding: 0 2px;
}

.footer-info-copyright.ico-15 [class*="flaticon-"]:before, 
.footer-info-copyright.ico-15 [class*="flaticon-"]:after { font-size: 0.85rem; }

/*------------------------------------------*/
/*  FOOTER MAIL LINK
/*------------------------------------------*/

.footer .footer-mail-link {
  margin-left: 3px;
  margin-bottom: 0;
}

#footer-1 .footer-info .footer-mail-link {
  margin-top: 10px;
}

#footer-2 .footer-links .footer-mail-link,
#footer-3 .footer-links .footer-mail-link {
  margin-left: 0;
  margin-bottom: 20px;
}

.footer-mail-link a {
  font-weight: 400;
}

.footer-mail-link span {
  position: relative;
  top: 4px;
  right: 3px;
}

.footer-mail-link.ico-20 [class*="flaticon-"]:before, 
.footer-mail-link.ico-20 [class*="flaticon-"]:after { font-size: 1.125rem; }

.bg--black.footer .footer-mail-link a {
  color: #a0a7b1;
}

.bg--black.footer .footer-mail-link a:hover {
  color: #fff!important;
}

/*------------------------------------------*/
/*  FOOTER LINKS
/*------------------------------------------*/

#footer-2 .footer-links {
  padding-left: 10%;
}

.foo-links li {
  width: auto!important;
  display: block!important;
  vertical-align: top;
  clear: none !important;
  margin: 0;
  padding: 0;
}

.foo-links.ico-10 li span {
  position: relative;
  top: 1.5px;
  right: 3px;
}

#footer-8 .foo-links li {
  display: inline-block!important;
  padding-left: 20px;
}

#footer-8 .foo-links li.first-li {
  padding-left: 0;
}

#footer-10 .foo-links li {
  display: inline-block!important;
  padding: 0 10px;
}

.footer .foo-links li p {
  font-weight: 400;
  margin-bottom: 10px;
}

.footer .foo-links li:last-child p {
  margin-bottom: 0;
}

.foo-links li a span.ext-link {
  display: inline-block;
  position: relative;
  left: 3px;
  top: 1.5px;
  transform: rotate(-60deg);
}

.foo-links.ico-15 [class*="flaticon-"]:before, 
.foo-links.ico-15 [class*="flaticon-"]:after { font-size: 0.75rem; }

.bg--black.footer .foo-links li p a {
  color: #a0a7b1;
}

.bg--black.footer .foo-links li p a:hover {
  color: #fff!important;
}

/*------------------------------------------*/
/*  FOOTER SOCIAL
/*------------------------------------------*/

.footer-socials {
  margin: 0;
  display: inline-block; 
}

.footer-socials li {
  float: left;
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0;
}

.footer-socials a {
  display: block;
  margin-right: 15px;
}

.footer-socials.ico-20 a,
.footer-socials.ico-25 a { 
  margin-right: 16px; 
} 

.footer-socials.ico-25 [class*="flaticon-"]:before, 
.footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.325rem; }

.bg--black.footer .footer-socials li a {
  color: #a0a7b1;
}

.bg--black.footer .footer-socials li a:hover {
  color: #fff!important;
}

/*------------------------------------------*/
/*  FOOTER STORE BAGES
/*------------------------------------------*/

.footer .store {
  float: none;
  margin-right: 0;
  margin-bottom: 15px;
}

/*------------------------------------------*/
/*  FOOTER NEWSLETTER FORM
/*------------------------------------------*/

.footer-form {
  margin-bottom: 20px;
}

#footer-7 .footer-form {
  padding-left: 8%;
}

.footer-form .newsletter-form .input-group {
  background-color: #f4f4f4;
  border: 1px solid transparent;
  padding: 9px 2px;
}

.footer-form .form-control {
  height: 32px;
  background-color: transparent;
  border: none;
  color: #666;
  font-size: 1rem;
  font-weight: 400;
  padding: 0 10px;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Newsletter Form Input Focus
/*------------------------------------------*/

.footer-form .form-control:focus {
  border: none;
  border-right: none;
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Footer Form Placeholder
/*------------------------------------------*/

.footer-form .form-control::-moz-placeholder { color: #6c757d; } 
.footer-form .form-control:-ms-input-placeholder { color: #6c757d; } 
.footer-form .form-control::-webkit-input-placeholder { color: #6c757d; } 

/*------------------------------------------*/
/*  Newsletter Form Button
/*------------------------------------------*/

.footer-form .btn {
  height: 32px;
  padding: 0 10px;
}

.footer-form .ico-15 [class*="flaticon-"]:before, 
.footer-form .ico-15 [class*="flaticon-"]:after { line-height: 28px!important; }

/*------------------------------------------*/
/*  Footer Form Notification
/*------------------------------------------*/

.footer-form .form-notification {
  color: #1680fb;
  font-size: 0.85rem;
  line-height: 1.25;
  font-weight: 400;
  margin-top: 12px;
  margin-left: 5px;
}

.footer-form .form-notification.valid,
.footer-form .form-notification.error {
  color: #fc2f4b;
}

.footer-form .newsletter-form.valid {
  color: #0fbc49;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER
/*------------------------------------------*/

.footer hr {
  margin-top: 30px;
  margin-bottom: 50px;
}

#footer-8 .bottom-footer,
#footer-9 .bottom-footer {
  margin-top: 20px;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER COPYRIGHT
/*------------------------------------------*/

.footer-copyright p {
  margin-bottom: 0;
}

.bg--black.footer .footer-copyright p {
  color: #a0a7b1;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER SECONDARY LINK
/*------------------------------------------*/

.bottom-secondary-link p {
  margin-bottom: 0;
}

.bottom-secondary-link p span {
  position: relative;
  top: 1px;
  padding: 0 2px;
}

.bottom-secondary-link.ico-15 [class*="flaticon-"]:before, 
.bottom-secondary-link.ico-15 [class*="flaticon-"]:after { font-size: 0.75rem; }

/*------------------------------------------*/
/*  BOTTOM FOOTER LINKS
/*------------------------------------------*/

.bottom-footer-list li,
.bottom-footer-socials li,
.bottom-footer-payment li {
  width: auto!important;
  display: inline-block!important;
  vertical-align: top;
  clear: none !important;
}

.bottom-footer-socials li {
  padding-left: 14px; 
}

#footer-4 .bottom-footer-list li,
#footer-9 .bottom-footer-list li {
  padding-left: 15px; 
}

#footer-4 .bottom-footer-list li.first-li,
#footer-9 .bottom-footer-list li.first-li {
  padding-left: 0; 
}

#footer-10 .bottom-footer-socials li {
  padding: 0 15px; 
}

.footer-list-divider {
  position: relative;
  top: -0.5px;
  transform: rotate(-90deg);
  margin-left: 4px;
}

.bottom-footer-list.ico-15 [class*="flaticon-"]:before, 
.bottom-footer-list.ico-15 [class*="flaticon-"]:after { font-size: 0.8rem; }

.bottom-footer-list li p {
  display: inline-block;
  float: left;
  margin-bottom: 0;
}

.bottom-footer-socials li span { 
  position: relative;
  top: 4px; 
  opacity: .8;
}

.bottom-footer-payment li span {
  line-height: 1!important;
  position: relative;
  top: 4px; 
  opacity: .8;
}

#footer-4 .bottom-footer-list li span,
#footer-9 .bottom-footer-list li span {
  margin-top: 1px;
  margin-right: 2px; 
}

.bottom-footer-socials.ico-20 [class*="flaticon-"]:before, 
.bottom-footer-socials.ico-20 [class*="flaticon-"]:after { font-size: 1.115rem; }

.bottom-footer-socials.ico-25 [class*="flaticon-"]:before, 
.bottom-footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.25rem; }

.bg--black.footer .bottom-footer-list li p a {
  color: #a0a7b1;
}

.bg--black.footer .bottom-footer-list li p a:hover {
  color: #fff!important;
}




/* ==========================================================================
  30. INNER PAGE WRAPPER
  =========================================================================== */

.inner-page-hero {
  padding-top: 180px;
}

.page-hero-section-overlay {
  position: relative;
  width: 100%;
  padding-top: 150px;
}

.page-hero-section-overlay .txt-block {
  margin-top: -40px;
}

.page-hero-section .wave-shape-bottom path {
  fill: #fff;
}

/*------------------------------------------*/
/*  INNER PAGE TITLE
/*------------------------------------------*/

.inner-page-title {
  text-align: center;
  margin-bottom: 80px;
}

.inner-page-title h2 {
  margin-bottom: 0;
}

.inner-page-title p {
  font-size: 1.1875rem;
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 0;
}

.page-hero-section h2 {
  line-height: 1.15;
  margin-bottom: 30px;
}




/* ==========================================================================
  31. PAGE PAGINATION
  =========================================================================== */

.page-link {
  color: #6c757d;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 2px 14px;
  margin: 0 8px;
  background-color: transparent;
  border: 2px solid transparent;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

.pagination.ico-20 [class*="flaticon-"]:before, 
.pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.9rem; } 

.page-item:first-child .page-link {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.blue-pagination .page-item.active .page-link {
  color: #fff!important;
  background-color: #1680fb;
  border: 2px solid #1680fb;
}

.green-pagination .page-item.active .page-link {
  color: #fff!important;
  background-color: #0fbc49;
  border: 2px solid #0fbc49;
}

.pink-pagination .page-item.active .page-link {
  color: #fff!important;
  background-color: #f74780;
  border: 2px solid #f74780;
}

.purple-pagination .page-item.active .page-link {
  color: #fff!important;
  background-color: #7b5dd6;
  border: 2px solid #7b5dd6;
}

.red-pagination .page-item.active .page-link {
  color: #fff!important;
  background-color: #ff1e1a;
  border: 2px solid #ff1e1a;
}

.violet-pagination .page-item.active .page-link {
  color: #fff!important;
  background-color: #be6cbe;
  border: 2px solid #be6cbe;
}

.yellow-pagination .page-item.active .page-link {
  color: #353f4f!important;
  background-color: #ffba01;
  border: 2px solid #ffba01;
}

.page-item.disabled .page-link {
  color: #6c757d;
  background-color: transparent;
  border: 2px solid transparent;
}

.page-link:hover {
  color: #6c757d;
  background-color: transparent;
  border: 2px solid #ccc;
}

.page-link:focus {
  color: #6c757d;
  background-color: transparent;
  border: 2px solid transparent;
  box-shadow: 0 0;
}




/* ==========================================================================
  32. TERMS, PRIVACY, COOKIES PAGES
  =========================================================================== */

/*------------------------------------------*/
/*  TERMS PAGE TYPOGRAPHY
/*------------------------------------------*/

.txt-block.legal-info h5 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.txt-block.legal-info h4 {
  margin-top: 80px;
  margin-bottom: 30px;
}

.txt-block.legal-info h4 span,
.txt-block.legal-info h5 span {
  margin-right: 4px;
}

.legal-info p span {
  color: #353f4f;
  font-weight: 500;
}

.legal-info a {
  font-weight: 500;
  text-decoration: underline;
}




/* ==========================================================================
  33. SIGN-IN / SIGN-UP PAGE
  =========================================================================== */

#login,
#signup {
  background: url(../images/login.jpg);
  background-position: bottom left;
  min-height: 100vh;
  padding-top: 80px;
  padding-bottom: 80px;
}

#reset-password {
  background: url(../images/reset-password.jpg);
  background-position: center center;
  min-height: 100vh;
  padding-top: 80px;
  padding-bottom: 100px;
}

.register-page-wrapper {
  overflow: hidden;
  position: relative;
  z-index: 1;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .12); 
  -moz-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .12); 
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .12); 
}

#login .register-page-wrapper {
  background: url(../images/login-wrapper.jpg);
  background-position: left top;
}

#signup .register-page-wrapper {
  background: url(../images/login-wrapper.jpg);
  background-position: right center;
}

#login .register-page-wrapper:after,
#signup .register-page-wrapper:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 50%;
  height: 100%;
  top: 0;
  background-color: #fff;
}

#login .register-page-wrapper:after {
  left: 50%;
}

#signup .register-page-wrapper:after {
  left: 0;
}

/*------------------------------------------*/
/*  REGISTER PAGE LOGO
/*------------------------------------------*/

.register-page-txt img {
  width: auto;
  max-width: inherit;
  max-height: 32px;
  margin-bottom: 55px;
}

.login-page-logo {
  text-align: center;
  margin-bottom: 45px;
}

.login-page-logo img {
  width: auto;
  max-width: inherit;
  max-height: 45px;
}

/*------------------------------------------*/
/*  REGISTER PAGE TITLE
/*------------------------------------------*/

.register-form-title {
  text-align: center;
  margin-bottom: 40px;
}

.register-form-title h3 {
  line-height: 1;
  margin-bottom: 10px;
}

.register-form-title p {
  margin-bottom: 0;
}

.register-form-title a {
  font-weight: 500;
  text-decoration: underline;
}

/*------------------------------------------*/
/*  REGISTER PAGE TEXT
/*------------------------------------------*/

#login .register-page-txt,
#signup .register-page-txt {
  padding: 70px 60px;
}

.register-page-txt h2 {
  line-height: 1.15;
  font-weight: 700;
  margin-bottom: 6px;
}

#login .register-page-copyright {
  position: absolute;
  left: 60px;
  bottom: 45px;
}

#signup .register-page-copyright {
  position: absolute;
  right: 60px;
  bottom: 45px;
}

.register-page-copyright p {
  font-size: 0.85rem;
  line-height: 1;
  margin-bottom: 0;
}

.reset-form-title {
  margin-bottom: 30px;
}

.reset-form-title h5 {
  line-height: 1;
  margin-bottom: 0;
}

.reset-form-title p.p-sm {
  font-size: 0.9rem;
  margin-top: 25px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  REGISTER PAGE FORM
/*------------------------------------------*/

.register-page-form {
  padding: 60px;
}

.login-1 .register-page-form,
.signup-1 .register-page-form {
  padding: 20px 30px;
}

.reset-page-wrapper { 
  margin: 0 10px;
}

.reset-page-wrapper form { 
  background: #fff;
  border: 1px solid #fafafa;
  padding: 50px 30px 25px;
  -webkit-box-shadow: 0px 15px 20px 0px rgba(5, 5, 5, .05);
  -moz-box-shadow: 0px 15px 20px 0px rgba(5, 5, 5, .05);
  box-shadow: 0px 15px 20px 0px rgba(5, 5, 5, .05);
}

/*------------------------------------------*/
/*  REGISTER PAGE TYPOGRAPHY
/*------------------------------------------*/

.register-page-form p.input-header {
  position: relative;
  color: #353f4f;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 12px;
  padding-left: 5px;
}

.reset-password-link p {
  position: relative;
  font-size: 0.9rem;
  line-height: 1;
  margin-top: -8px;
  margin-bottom: 10px;
}

.form-data span {
  display: block;
  font-size: 0.85rem;
  margin-bottom: 0;
}

.form-data span a {
  color: #353f4f;
  font-weight: 500;
  text-decoration: underline;
}

.register-page-form p.create-account {
  font-size: 0.925rem;
  line-height: 1;
  margin-top: 30px;
  margin-bottom: 0;
}

.register-page-form p.create-account a {
  font-weight: 500;
  text-decoration: underline;
}

/*------------------------------------------*/
/*  REGISTER PAGE SEPARATOR LINE
/*------------------------------------------*/

.separator-line {
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 25px 0px 30px;
}

.separator-line::before, 
.separator-line::after {
  content: "";
  border-top: 2px solid rgba(0, 0, 0, .1);
  margin: 0 15px 0 0;
  flex: 1 0 20px;
}

.separator-line::after {
  margin: 0 0 0 15px;
}

/*------------------------------------------*/
/*  REGISTER PAGE FORM INPUT
/*------------------------------------------*/

.reset-page-wrapper .form-control,
.register-page-form .form-control {
  height: 60px;
  background-color: #f5f6f8;
  border: 1px solid transparent;
  color: #353f4f;
  font-size: 0.95rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 25px;
  padding: 5px 20px;
   -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.login-1 .form-control,
.signup-1 .form-control {
  background-color: #fff;
  border: 1px solid #ccc;
}

.reset-page-wrapper .form-control {
  text-align: center;
}

.register-page-form .form-control::-moz-placeholder { color: #999; } 
.register-page-form .form-control:-ms-input-placeholder { color: #999; } 
.register-page-form .form-control::-webkit-input-placeholder { color: #999; } 

/*------------------------------------------*/
/*  REGISTER PAGE FORM FOCUS
/*------------------------------------------*/

.reset-page-wrapper .form-control:focus,
.register-page-form .form-control:focus {
  outline: 0px none;
  box-shadow: none;
  background-color: #fcfdfd;
  border-color: #1680fb;
}

/*------------------------------------------*/
/*  REGISTER PAGE FORM BUTTON
/*------------------------------------------*/

#signup .register-page-form .btn.submit, 
#login .register-page-form .btn.submit {
  width: 100%;
  height: 60px;
  margin-top: 20px;
}

.reset-page-wrapper .btn.submit {
  width: 100%;
  margin-bottom: 20px;
}

.register-page-form .btn-google {
  width: 100%;
  color: #333;
  font-size: 0.95rem;
  background-color: #fff;
  border: 1px solid #ccc;
}

.btn-google img {
  position: relative;
  width: 22px;
  height: 22px;
  top: -2px;
  right: 5px;
}

.register-page-form .btn-google:hover {
  border: 1px solid #333;
}

/*------------------------------------------*/
/*  REGISTER PAGE FORM MESSAGE
/*------------------------------------------*/

.reset-form-msg {
  text-align: center;
  width: 100%!important;
  display: block;
}

.reset-password-form .loading {
  color: #00b2e4;
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 500;
  padding-left: 15px;
}

.reset-password-form .error {
  position: relative;
  color: #e74c3c;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 400;
  top: -10px;
  margin-bottom: 12px;
  padding-left: 10px;
}

/*------------------------------------------*/
/*  FORM HIDE PASSWORD
/*------------------------------------------*/

.wrap-input {
  position: relative;
}

.btn-show-pass {
  color: #999999;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 20px;
  right: 20px;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.btn-show-pass.ico-20 [class^="flaticon-"]:before, 
.btn-show-pass.ico-20 [class^="flaticon-"]:after {
  font-size: 1.125rem;
}

.btn-show-pass:hover {
  color: #0195ff;
}

.btn-show-pass.active {
  color: #6a7dfe;
}




/* ==========================================================================
  34. PAGE 404
  =========================================================================== */

.page-404-txt {
  padding-top: 180px;
  padding-bottom: 100px;
}

.page-404-txt h2 {
  margin-bottom: 20px;
}

.page-404-txt h6 {
  line-height: 1.6;
  font-weight: 400;
  padding: 0 5%;
  margin-bottom: 30px;
}

.page-404-img {
  padding: 0 5%;
  margin-bottom: 60px;
}




/* ==========================================================================
  35. MODAL
  ========================================================================== */ 

.modal-body {
  padding: 0!important;
}  

#modal-1 .modal-dialog {
  max-width: 700px;
}

#modal-2 .modal-dialog {
  width: 440px;
}

#modal-3 .modal-dialog.modal-xl {
  width: 750px;
}

#modal-2.modal .modal-content,
#modal-3.modal .modal-content {
  overflow: hidden;
  -webkit-border-radius: 10px; 
  -moz-border-radius: 10px; 
  -o-border-radius: 10px; 
  border-radius: 10px;
}

#modal-3.modal .bg-img {
  background: url(../images/modal-request.jpg);
  background-position: left center;  
}

#modal-2 .modal-body-content {
  padding: 35px 40px 15px;
}

#modal-3 .modal-body-content {
  padding: 60px 35px 50px;
}

/*------------------------------------------*/
/*  MODAL WINDOW CLOSE BUTTON
/*------------------------------------------*/ 

.modal .btn-close {
  position: absolute;
  z-index: 1;
  right: 15px!important;
  top: 15px!important;
  height: 1.5rem;
  width: 1.5rem;
  background: rgba(255, 255, 255, 1)!important;
  border-radius: 50%;
  padding: 0;
  opacity: .9;
  z-index: 9999;
}

#modal-3.modal .btn-close {
  background: rgba(14, 14, 14, .3)!important;
}

.btn-close span {
  position: relative;
  line-height: 24px!important;
  top: -2px;
}

.modal .btn-close.ico-10 [class^="flaticon-"]:before, 
.modal .btn-close.ico-10 [class^="flaticon-"]:after {
  font-size: 0.6rem;
}

button.btn-close:focus {
  outline: none!important;
  text-decoration: none!important;
}

/*------------------------------------------*/
/*  MODAL WINDOW NEWSLETTER FORM
/*------------------------------------------*/ 

.modal-body .newsletter-form {
  margin-top: 25px;
}

.modal .newsletter-form .form-control {
  text-align: center;
  height: 56px;
  background-color: #f5f6f8;
  border: 1px solid transparent;
  font-size: 1rem;
  color: #353f4f;
  font-weight: 400;
  padding: 0 15px;  
  margin: 0 0 15px 0;
  box-shadow: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
}

/*------------------------------------------*/
/*  Form Input Focus
/*------------------------------------------*/

.modal .newsletter-form .form-control:focus {
  outline: 0;
  box-shadow: none;
  border-color: #1e90ff;
}

.modal .newsletter-form.purple-focus .form-control:focus {
  border-color: #8863ee;
}

/*------------------------------------------*/
/*  Form Input Placeholder
/*------------------------------------------*/

.modal .newsletter-form .form-control::-moz-placeholder { color: #aaa; } 
.modal .newsletter-form .form-control:-ms-input-placeholder { color: #aaa; } 
.modal .newsletter-form .form-control::-webkit-input-placeholder { color: #aaa; } 

/*------------------------------------------*/
/*  Form Button
/*------------------------------------------*/

.modal .input-group-btn { 
  display: block; 
  width: 100%!important; 
}

.modal .newsletter-form .btn {
  display: block; 
  width: 100%!important;
  height: 56px;
  margin: 0;
}

/*------------------------------------------*/
/*  Form Notification
/*------------------------------------------*/

.modal .form-notification {
  text-align: center;
  color: #1680fb;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 12px;
}

.modal .form-notification.valid,
.modal .form-notification.error {
   color: #fc2f4b;
}

.modal .newsletter-form.valid {
  color: #0fbc49;
}

/*------------------------------------------*/
/*  MODAL WINDOW REQUEST FORM
/*------------------------------------------*/ 

#modal-3 .request-form {
  margin: 0;
}

#modal-3 .request-form .col-md-12 {
  padding: 0;
}

#modal-3 .request-form-title p {
  margin-top: 18px;
  margin-bottom: 25px;
}

#modal-3 .request-form .form-control { 
  text-align: left;
  margin: 0 0 25px 0;
}

#modal-3 .request-form .btn {
  display: block; 
  width: 100%!important;
  height: 56px;
  margin: 0;
}

/*------------------------------------------*/
/*  Comment Form Message
/*------------------------------------------*/

#modal-3 .request-form-msg {
  width: 100%!important;
  display: block;
  margin-top: 15px;
  padding-left: 0;
}

#modal-3 .request-form .loading {
  color: #1680fb;
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 400;
}

#modal-3 .request-form .error {
  color: #e74c3c;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 10px;
}
 



/* ==========================================================================
  31. DARK MODE
  ========================================================================== */  

body.theme--dark,
.theme--dark .bg--white-100 { 
  background-color: #232734!important;  
} 

body.theme--dark { 
  color: #b1b7cd; 
}

.dark-theme-img { display: none; position: absolute; }
.theme--dark .dark-theme-img { display: inline-block; position: relative; }
.theme--dark  .owl-carousel .owl-item img.light-theme-img { display: none; position: absolute; }
.theme--dark  .owl-carousel .owl-item img.dark-theme-img { display: block; }
.theme--dark .light-theme-img { display: none; }

.theme--dark .block-shadow { 
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .13); 
  -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .13); 
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .13); 
}

.theme--dark .block-border {
  border: 1px solid #e7e7e7;
}

.theme--dark .block-border.block-shadow { 
  border: 1px solid #f5f6f6; 
}

.theme--dark .x-border { border-top: 1px solid #1d212c; border-bottom: 1px solid #1d212c; }
.theme--dark .top-border { border-top: 1px solid #1d212c; }
.theme--dark .bottom-border { border-bottom: 1px solid #1d212c; }

.theme--dark .bg--white-300, 
.theme--dark .bg--white-400,
.theme--dark .bg--purple-100,
.theme--dark .shape--purple-100:after,
.theme--dark .shape--white-500:after,
.theme--dark .shape--white-400:after,
.theme--dark .shape--white-300:after,
.theme--dark .shape--white-200:after {
  background-color: #1d212c;
}

.theme--dark .bg--02,
.theme--dark .bg--04,
.theme--dark .gr--smoke,
.theme--dark .gr--ghost,
.theme--dark .shape--whitesmoke:after {
  background-image: linear-gradient(180deg, rgba(29, 33, 44, .8) 50%, rgba(29, 33, 44, .55) 100%);
}

.theme--dark .gr--whitesmoke,
.theme--dark .shape--gr-whitesmoke:after {
  background-image: linear-gradient(180deg, rgba(29, 33, 44, .8) 50%, rgba(29, 33, 44, .05) 100%);
}
/*------------------------------------------*/
/*  SECTION DIVIDER
/*------------------------------------------*/

.theme--dark hr.divider,
.theme--dark .footer hr {
  background-image: linear-gradient(90deg, rgba(122,125,147,0) 0, #7a7d93 38%, #7a7d93 64%, rgba(122,125,147,0) 99%); 
}

.theme--dark hr.divider.divider-light {
  background-color: #fff;
  background-image: linear-gradient(90deg, rgba(206,211,246,0) 0, #dedede 38%, #dedede 64%, rgba(206,211,246,0) 99%);
  opacity: 1;
}

/*------------------------------------------*/
/*  TYPOGRAPHY
/*------------------------------------------*/

.theme--dark h1, 
.theme--dark h2, 
.theme--dark h3,
.theme--dark h4,
.theme--dark h5, 
.theme--dark h6 {  
  color: #fff;
}

.theme--dark a { color: #b1b7cd; }
.theme--dark a:hover, .theme--dark a.tra-link:hover span { color: #f7f8f9!important; }

/*------------------------------------------*/
/*  BUTTONS
/*------------------------------------------*/

.theme--dark .btn--tra-black,
.theme--dark .hover--tra-black:hover {
  color: #b1b7cd!important;
  background-color:transparent!important;
  border-color: #b1b7cd!important;
}

.theme--dark .navbar-dark .hover--tra-black:hover {
  color: #353f4f!important;
  background-color:transparent!important;
  border-color: #353f4f!important;
}

.theme--dark .white-scroll .hover--tra-black:hover {
  color: #fff!important;
  border-color: #fff!important;
  background-color: transparent!important;
}

.theme--dark .navbar-dark .scroll .hover--tra-black:hover {
  color: #fff!important;
  background-color:transparent!important;
  border-color: #fff!important;
}

.theme--dark .btn--white,
.theme--dark .hover--white:hover {
  color: #353f4f!important;
  background-color: #fff!important;
  border-color: #fff!important;
}

.theme--dark .btn--tra-white,
.theme--dark .hover--tra-white:hover {
  color: #f1f1f1!important;
  background-color: transparent!important;
  border-color: #f1f1f1!important;
}

.theme--dark .white-scroll .scroll .hover--tra-white:hover {
  color: #fff!important;
  background-color: transparent!important;
  border-color: #fff!important;
}

.theme--dark .btn--tra-grey, 
.theme--dark .hover--tra-grey:hover,
.theme--dark .white-scroll .scroll .hover--tra-grey:hover,
.theme--dark .black-scroll .scroll .hover--tra-grey:hover {
  color: #b1b7cd!important;
  background-color: transparent!important;
  border-color: #b1b7cd!important;
}

.theme--dark .btn--blue-500, 
.theme--dark .hover--blue-500:hover,
.theme--dark .white-scroll .scroll .hover--blue-500:hover,
.theme--dark .black-scroll .scroll .hover--blue-500:hover {
  color: #fff!important;
  border-color: #185abd!important;
  background-color: #185abd!important;
}

.theme--dark .btn--blue-400, 
.theme--dark .hover--blue-400:hover,
.theme--dark .white-scroll .scroll .hover--blue-400:hover,
.theme--dark .black-scroll .scroll .hover--blue-400:hover {
  color: #fff!important;
  border-color: #1680fb!important;
  background-color: #1680fb!important;
}

.theme--dark .btn--blue-300, 
.theme--dark .hover--blue-300:hover,
.theme--dark .white-scroll .scroll .hover--blue-300:hover,
.theme--dark .black-scroll .scroll .hover--blue-300:hover {
  color: #fff!important;
  border-color: #3eb1ff!important;
  background-color: #3eb1ff!important;
}

.theme--dark .btn--pink-500, 
.theme--dark .hover--pink-500:hover,
.theme--dark .white-scroll .scroll .hover--pink-500:hover,
.theme--dark .black-scroll .scroll .hover--pink-500:hover {
  color: #fff!important;
  border-color: #ff149c!important;
  background-color: #ff149c!important;
}

.theme--dark .btn--pink-400, 
.theme--dark .hover--pink-400:hover,
.white-scroll .scroll .hover--pink-400:hover,
.black-scroll .scroll .hover--pink-400:hover {
  color: #fff!important;
  border-color: #f74780!important;
  background-color: #f74780!important;
}

.theme--dark .btn--pink-300, 
.theme--dark .hover--pink-300:hover,
.theme--dark .white-scroll .scroll .hover--pink-300:hover,
.theme--dark .black-scroll .scroll .hover--pink-300:hover {
  color: #fff!important;
  border-color: #fcb5cc!important;
  background-color: #fcb5cc!important;
}

.theme--dark .btn--purple-500, 
.theme--dark .hover--purple-500:hover,
.theme--dark .white-scroll .scroll .hover--purple-500:hover,
.theme--dark .black-scroll .scroll .hover--purple-500:hover {
  color: #fff!important;
  border-color: #663ae9!important;
  background-color: #663ae9!important;
}

.theme--dark .btn--purple-400, 
.theme--dark .hover--purple-400:hover,
.theme--dark .white-scroll .scroll .hover--purple-400:hover,
.theme--dark .black-scroll .scroll .hover--purple-400:hover {
  color: #fff!important;
  border-color: #7b5dd6!important;
  background-color: #7b5dd6!important;
}

.theme--dark .btn--purple-300, 
.theme--dark .hover--purple-300:hover,
.theme--dark .white-scroll .scroll .hover--purple-300:hover,
.theme--dark .black-scroll .scroll .hover--purple-300:hover {
  color: #fff!important;
  border-color: #bdaeea!important;
  background-color: #bdaeea!important;
}

.theme--dark .btn--violet-500, 
.theme--dark .hover--violet-500:hover,
.theme--dark .white-scroll .scroll .hover--violet-500:hover,
.theme--dark .black-scroll .scroll .hover--violet-500:hover {
  color: #fff!important;
  border-color: #c73e9b!important;
  background-color: #c73e9b!important;
}

.theme--dark .btn--violet-400, 
.theme--dark .hover--violet-400:hover,
.theme--dark .white-scroll .scroll .hover--violet-400:hover,
.theme--dark .black-scroll .scroll .hover--violet-400:hover {
  color: #fff!important;
  border-color: #be6cbe!important;
  background-color: #be6cbe!important;
}

.theme--dark .btn--violet-300, 
.theme--dark .hover--violet-300:hover,
.theme--dark .white-scroll .scroll .hover--violet-300:hover,
.theme--dark .black-scroll .scroll .hover--violet-300:hover {
  color: #fff!important;
  border-color: #e2bde2!important;
  background-color: #e2bde2!important;
}

.theme--dark .section-id {
  color: #eee; 
}

.theme--dark .color--black,
.theme--dark .color--black h2, 
.theme--dark .color--black h3, 
.theme--dark .color--black h4, 
.theme--dark .color--black h5, 
.theme--dark .color--black h6, 
.theme--dark .color--black p, 
.theme--dark .color--black a,
.theme--dark .color--black li, 
.theme--dark .color--black i,
.theme--dark .color--black span, 
.theme--dar .color--white .color--black { color: #fff; }

.theme--dark .color--grey,
.theme--dark .color--grey h2, 
.theme--dark .color--grey h3, 
.theme--dark .color--grey h4, 
.theme--dark .color--grey h5, 
.theme--dark .color--grey h6, 
.theme--dark .color--grey p, 
.theme--dark .color--grey a,
.theme--dark .color--grey li, 
.theme--dark .color--grey i,
.theme--dark .color--grey span, 
.theme--dark .color--white .color--grey { color: #b1b7cd; }

/*------------------------------------------*/
/*   NAVIGATION MENU
/*------------------------------------------*/

.theme--dark .dark-menu .wsmainfull,
.theme--dark .white-menu .wsmainfull {
  background-color: #1d222f!important;
  -webkit-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  -moz-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
}

/*------------------------------------------*/
/*  HEADER LINK
/*------------------------------------------*/

.theme--dark .navbar-light .wsmenu > .wsmenu-list > li > a,
.theme--dark .navbar-dark.inner-page-header .wsmenu > .wsmenu-list > li > a,
.theme--dark .navbar-dark.light-hero-header .wsmenu > .wsmenu-list > li > a {
  color: #fff;
}

.theme--dark .navbar-light .wsmenu-list > li > a.h-link:hover,
.theme--dark .navbar-light .wsmenu > .wsmenu-list > li > a:hover,
.theme--dark .navbar-dark.inner-page-header .wsmenu > .wsmenu-list > li > a:hover,
.theme--dark .navbar-dark.light-hero-header .wsmenu > .wsmenu-list > li > a:hover {
  color: #eee!important;
}

.theme--dark .navbar-light .wsmenu > .wsmenu-list > li > a.btn:hover {
  color: #fff!important;
}

/*------------------------------------------*/
/*  HEADER SUBMENU / MEGAMENU
/*------------------------------------------*/

.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
  background-color: #2c3142!important; 
  border: solid 1px #2c3142!important; 
  -webkit-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  -moz-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
}

.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu:before,
.theme--dark .wsmenu > .wsmenu-list > li.mg_link:hover > a:after,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu:before {
  background-color: #2c3142;
  border-left: solid 1px #2c3142;
  border-top: solid 1px #2c3142;
}
 
.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
  color: #b1b7cd;
  background-color: transparent!important;
}

.theme--dark .scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
.theme--dark .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
.theme--dark .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
  color: #b1b7cd;
}

/*------------------------------------------*/
/*  NAVBAR SCROLL
/*------------------------------------------*/

.theme--dark .tra-menu .wsmainfull.scroll,
.theme--dark .white-menu .wsmainfull.scroll,
.theme--dark .dark-menu.dark-scroll .wsmainfull.scroll {
  background-color: #1d222f!important;
  padding: 0;
  -webkit-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  -moz-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
}

.theme--dark .tra-menu.navbar-dark .scroll .wsmenu > .wsmenu-list > li > a,
.theme--dark .tra-menu.navbar-light .scroll .wsmenu > .wsmenu-list > li > a,
.theme--dark .tra-menu.navbar-dark.white-scroll .scroll .wsmenu > .wsmenu-list > li > a.h-link, 
.theme--dark .tra-menu.navbar-light.white-scroll .scroll .wsmenu > .wsmenu-list > li > a.h-link {
  color: #b1b7cd;
}

.theme--dark .navbar-light.white-scroll .scroll .wsmenu-list > li > a.h-link:hover {
  color: #fff!important;
}

.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  background-color: rgba(0, 0, 0, .12)!important;
}

.theme--dark .navbar-light .scroll .btn.btn--tra-white {
  color: #fff!important;
  border-color: #fff!important;
  background-color: transparent!important;
}

/*------------------------------------------*/
/*  Logo Image
/*------------------------------------------*/

.theme--dark .navbar-dark .logo-black,
.theme--dark .navbar-dark .scroll .logo-black {
  display: none;
}

.theme--dark .navbar-dark .logo-white,
.theme--dark .navbar-dark .scroll .logo-white {
  display: block;
}

.theme--dark .navbar-dark .logo-black {
  display: block;
}

.theme--dark .navbar-dark .logo-white {
  display: none;
}

.theme--dark .navbar-dark .scroll .logo-white {
  display: block;
}

/*------------------------------------------*/
/*  HERO SECTION
/*------------------------------------------*/

.theme--dark .hero-section .color--black,
.theme--dark .hero-section .color--black h2, 
.theme--dark .hero-section .color--black h3, 
.theme--dark .hero-section .color--black h4, 
.theme--dark .hero-section .color--black h5, 
.theme--dark .hero-section .color--black h6, 
.theme--dark .hero-section .color--black p, 
.theme--dark .hero-section .color--black a,
.theme--dark .hero-section .color--black li, 
.theme--dark .hero-section .color--black i,
.theme--dark .hero-section .color--black span, 
.theme--dark .hero-section .color--white .color--black {  color: #353f4f; }

.theme--dark .hero-section p { color: #6c757d; }
.theme--dark .hero-section .color--white p { color: #fff; }
.theme--dark .hero-section.gr--whitesmoke p {color: #b1b7cd; }
.theme--dark .hero-section .section-id.color--grey { color: #6c757d; }

.theme--dark .hero-5-txt p,
.theme--dark .hero-6-txt p,
.theme--dark .hero-10-txt p,
.theme--dark .hero-15-txt p,
.theme--dark .hero-16-txt p,
.theme--dark .hero-17-txt p,
.theme--dark .hero-18-txt p,
.theme--dark .hero-19-txt p,
.theme--dark .hero-20-txt p,
.theme--dark .hero-21-txt p,
.theme--dark .hero-23-txt p,
.theme--dark .hero-25-txt p { color: #b1b7cd; }

.theme--dark .hero-section .quick-form .form-control {
  color: #fff;
  background-color: #363f54;
}

.theme--dark #hero-14.hero-section .quick-form .form-control,
.theme--dark #hero-24.hero-section .quick-form .form-control,
.theme--dark #hero-26.hero-section .quick-form .form-control {
  color: #666;
  background-color: #fff;
}

.theme--dark .hero-section .quick-form .form-control::-moz-placeholder { color: #b1b7cd; } 
.theme--dark .hero-section .quick-form .form-control:-ms-input-placeholder { color: #b1b7cd; }
.theme--dark .hero-section .quick-form .form-control::-webkit-input-placeholder { color: #b1b7cd; }

.theme--dark #hero-14.hero-section .quick-form .form-control::-moz-placeholder { color: #999; } 
.theme--dark #hero-14.hero-section .quick-form .form-control:-ms-input-placeholder { color: #999; }
.theme--dark #hero-14.hero-section .quick-form .form-control::-webkit-input-placeholder { color: #999; }

.theme--dark #hero-24.hero-section .quick-form .form-control::-moz-placeholder { color: #999; } 
.theme--dark #hero-24.hero-section .quick-form .form-control:-ms-input-placeholder { color: #999; }
.theme--dark #hero-24.hero-section .quick-form .form-control::-webkit-input-placeholder { color: #999; }

.theme--dark #hero-26.hero-section .quick-form .form-control::-moz-placeholder { color: #999; } 
.theme--dark #hero-26.hero-section .quick-form .form-control:-ms-input-placeholder { color: #999; }
.theme--dark #hero-26.hero-section .quick-form .form-control::-webkit-input-placeholder { color: #999; }

.theme--dark .hero-section .quick-form .form-control:focus {
  background-color: #505c77;
  border-color: transparent;
}

.theme--dark #hero-14.hero-section .quick-form .form-control:focus,
.theme--dark #hero-24.hero-section .quick-form .form-control:focus,
.theme--dark #hero-26.hero-section .quick-form .form-control:focus {
  background-color: #fff;
  border-color: transparent;
}

.theme--dark .hero-section .request-form .form-control {
  color: #fff;
  background-color: #363f54;
}

.theme--dark .hero-section .request-form .form-control::-moz-placeholder { color: #b1b7cd; } 
.theme--dark .hero-section .request-form .form-control:-ms-input-placeholder { color: #b1b7cd; }
.theme--dark .hero-section .request-form .form-control::-webkit-input-placeholder { color: #b1b7cd; }

.theme--dark .hero-section .request-form .form-control:focus {
  background-color: #505c77;
  border-color: transparent;
}

.theme--dark #hero-3:after,
.theme--dark #hero-4:after {
  background-color: #232734;
}

.theme--dark .hero-4-txt h2 {
  color: #353f4f;
}

.theme--dark .hero-4-txt .btn.btn--tra-black {
  color: #353f4f!important;
  background-color:transparent!important;
  border-color: #353f4f!important;
}

.theme--dark #hero-5 {
  background-image: url(../images/hero-5-dark.jpg);
}

.theme--dark .hero-6-wrapper {
  background-image: linear-gradient(90deg, #1d212c, #1d212c);
}

.theme--dark #hero-8-form {
  background-color: #1d222f;
  border: 1px solid #1d222f;
}

.theme--dark #hero-8-form p {
  color: #b1b7cd;
}

.theme--dark #hero-5 .wave-shape-bottom svg path,
.theme--dark #hero-8 .wave-shape-bottom svg path,
.theme--dark #hero-14 .wave-shape-bottom svg path,
.theme--dark #hero-16 .wave-shape-bottom svg path,
.theme--dark #hero-22 .wave-shape-bottom svg path,
.theme--dark #hero-23 .wave-shape-bottom svg path,
.theme--dark #hero-26 .wave-shape-bottom svg path {
  fill: #232734;
}

.theme--dark #hero-10 {
  background-image: url(../images/hero-10-dark.jpg);
}

.theme--dark #hero-15 {
  background-image: url(../images/hero-15-dark.jpg);
}

.theme--dark #hero-16 {
  background-image: url(../images/hero-16-dark.jpg);
}

.theme--dark #hero-17 {
  background-image: url(../images/hero-17-dark.jpg);
}

.theme--dark #hero-18:after {
  background-image: url(../images/hero-18-dark.jpg);
}

.theme--dark #hero-20:after {
  background-image: linear-gradient(180deg, #1d212c, #232734);
}

.theme--dark  #hero-21 .hero-overlay {
  background-image: url(../images/hero-21-dark.jpg);
}

.theme--dark #hero-23 {
  background-image: url(../images/hero-23-dark.jpg);
}

.theme--dark .hero-23-txt p span.color--black { color: #fff; }

.theme--dark #hero-25 {
  background-image: url(../images/hero-25-dark.jpg);
}

.theme--dark #hero-27 .hero-overlay {
  background-image: url(../images/hero-27-dark.png);
}

/*------------------------------------------*/
/*  FEATURES
/*------------------------------------------*/

.theme--dark .features-section path {
  opacity: .05;
}

.theme--dark .fbox-7 {
  border: 2px solid #363f54;
}

/*------------------------------------------*/
/*  CONTENT
/*------------------------------------------*/

.theme--dark .cbox-2-line {
  background-color: #363f54;
}

/*------------------------------------------*/
/*  STATISTIC
/*------------------------------------------*/

.theme--dark #statistic-5 .statistic-digit {
  border-bottom: 1px solid #505c77;
}

/*------------------------------------------*/
/*  REVIEWS
/*------------------------------------------*/

.theme--dark #reviews-2 .review-2.bg--white-100 {
  background-color: #1d212c!important;
}

.theme--dark .owl-theme .owl-dots .owl-dot span {
  background: #1d212c;
}

.theme--dark .owl-theme .owl-dots .owl-dot.active span {
  background: #b1b7cd;
}

.theme--dark .review-3.block-border {
  border-color: #363f54;
}

/*------------------------------------------*/
/*  PROJECT DETAILS
/*------------------------------------------*/

.theme--dark .project-title h2 {
  border-bottom: 1px solid #363f54;
}

.theme--dark .project-data p span { color: #fff; }

.theme--dark .project-description .more-projects span {
  color: #fff;
}

/*------------------------------------------*/
/*  RATING
/*------------------------------------------*/

.theme--dark .rbox-1 .star-rating {
  background-color: #1d212c!important;
  border: 1px solid #1d212c;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .13); 
  -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .13); 
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .13); 
}

/*------------------------------------------*/
/*  PRICING
/*------------------------------------------*/

.theme--dark .pricing-discount h6 {
  color: #353f4f;
}

.theme--dark .table>:not(caption)>*>* {
  color: #b1b7cd;
  background-color: #232734;
}

.theme--dark .comp-table .table thead th {
  color: #fff;
  border-color: #363f54;
}

.theme--dark .comp-table tbody th {
  border-bottom: 1px solid #363f54!important;
}

.theme--dark .comp-table .table tr td {
  border-bottom: 2px solid #363f54;
}

/*------------------------------------------*/
/*  INTEGRATIONS
/*------------------------------------------*/

.theme--dark .integrations-1-wrapper .in_tool {
  background-color: #1d212c;
  border: 1px solid #1d212c;
}

/*------------------------------------------*/
/*  PRICING
/*------------------------------------------*/

.theme--dark .switcher.bg--grey {
  background-color: #363f54;
  border: 2px solid #363f54;
}

/*------------------------------------------*/
/*  BANNER
/*------------------------------------------*/

.theme--dark .banner-16-wrapper.bg--white-100.block-border {
  border-color: #363f54;
}

/*------------------------------------------*/
/*  DOWNLOAD
/*------------------------------------------*/

.theme--dark .release-data {
  border-bottom: 1px dashed #656565;
}

.theme--dark span.version-data,
.theme--dark span.release-date span {
  color: #f1f1f1;
}

.theme--dark span.release-date {
  color: #ccc;
}

/*------------------------------------------*/
/*  FAQs
/*------------------------------------------*/

.theme--dark .faqs-section .accordion-item,
.theme--dark #faqs-2.faqs-section .accordion-item,
.theme--dark #faqs-2.faqs-section .accordion-item.is-active .accordion-thumb {
  border-bottom: 1px solid #363f54;
}

.theme--dark .faqs-section .tabs-1 li {
  border: 2px solid #363f54;
}

.theme--dark .faqs-section .tabs-1 li span,
.theme--dark .faqs-section .tabs-1 li {
  color: #b1b7cd;
}

.theme--dark .faqs-section .accordion-item .accordion-thumb:after,
.theme--dark .faqs-section .accordion-item.is-active .accordion-thumb:after {
  color: #b1b7cd;
}

/*------------------------------------------*/
/*  NEWSLETTER
/*------------------------------------------*/

.theme--dark .newsletter-section .form-control {
  color: #f1f1f1;
  background-color: #363f54;
  border: 1px solid #363f54;
}

.theme--dark .newsletter-section .form-control:focus {
  background-color: #505c77;
  border-color: transparent;
}

.theme--dark .newsletter-section .form-control::-moz-placeholder { color: #b1b7cd; } 
.theme--dark .newsletter-section .form-control:-ms-input-placeholder { color: #b1b7cd; }
.theme--dark .newsletter-section .form-control::-webkit-input-placeholder { color: #b1b7cd; }

/*------------------------------------------*/
/*  BLOG
/*------------------------------------------*/

.theme--dark .post-meta-list p,
.theme--dark .blog-post-txt a {
  color: #fff;
}

.theme--dark .blog-post-txt a:hover {
  color: #ddd!important;
}

/*------------------------------------------*/
/*  SINGLE POST
/*------------------------------------------*/

.theme--dark .txt-code {
  background-color: #1d212c;
}

.theme--dark .txt-code kbd {
  background-color: #363f54;
}

.theme--dark  .txt-code p {
  color: #b1b7cd; 
}

.theme--dark .comment-form p,
.theme--dark  .blockquote-footer,
.theme--dark .txt-code span.code-property,
.theme--dark .txt-code p code.code-language-js {
  color: #fff;
}

.theme--dark .comment-form .form-control {
  color: #f1f1f1;
  background-color: #363f54;
}

.theme--dark .comment-form .form-control:focus {
  background-color: #505c77;
  border-color: transparent;
}

.theme--dark .comment-form .form-control::-moz-placeholder { color: #b1b7cd; } 
.theme--dark .comment-form .form-control:-ms-input-placeholder { color: #b1b7cd; }
.theme--dark .comment-form .form-control::-webkit-input-placeholder { color: #b1b7cd; }

/*------------------------------------------*/
/*  CONTACTS
/*------------------------------------------*/

.theme--dark .contact-form-notice p a,
.theme--dark #contacts-1 .contact-form p {
  color: #fff;
}

.theme--dark .contact-form .form-control,
.theme--dark .contact-form .form-select {
  color: #f1f1f1;
  background-color: #363f54;
}

.theme--dark .contact-form .form-control::-moz-placeholder { color: #b1b7cd; } 
.theme--dark .contact-form .form-control:-ms-input-placeholder { color: #b1b7cd; }
.theme--dark .contact-form .form-control::-webkit-input-placeholder { color: #b1b7cd; }

.theme--dark .contact-form .form-control:focus,
.theme--dark .contact-form .form-select:focus {
  background-color: #505c77;
  border-color: transparent;
}

/*------------------------------------------*/
/*  FOOTER
/*------------------------------------------*/

.footer-logo-dark { display: none; }
.theme--dark .footer-logo { display: none; }
.theme--dark .footer-logo-dark { display: block; }
.theme--dark #footer-10 .footer-logo-dark { display: inline-block; }

.theme--dark  #footer-10 .footer-info {
  border-bottom: 1px solid #363f54;
}

.theme--dark .footer-form .newsletter-form .btn.dark-color span {
  color: #2f353e!important;
}

.theme--dark .footer-form .newsletter-form .input-group {
  background-color: #282d3c;
  border: 1px solid #505c77;
}

.theme--dark .footer-form .form-control {
  color: #fff;
}

.theme--dark .footer-form .form-control::-moz-placeholder { color: #b1b7cd; } 
.theme--dark .footer-form .form-control:-ms-input-placeholder { color: #b1b7cd; } 
.theme--dark .footer-form .form-control::-webkit-input-placeholder { color: #b1b7cd; } 

.theme--dark .legal-info p span {
  color: #fff;
}

/*------------------------------------------*/
/*  INNER PAGE
/*------------------------------------------*/

.theme--dark .page-hero-section .wave-shape-bottom path {
  fill: #232734;
}

/*------------------------------------------*/
/*  MODAL
/*------------------------------------------*/

.theme--dark #modal-2 .modal-body-content h5,
.theme--dark #modal-3 .modal-body-content h3 {
  color: #353f4f;
}

/*------------------------------------------*/
/*  PAGE 404
/*------------------------------------------*/

.theme--dark #page-404.bg--white-300, .theme--dark #footer-11.bg--white-300 { background-color: #f7f8f9; }
.theme--dark #page-404 .color--dark { color: #353f4f; }

.theme--dark #page-404 h6.color--grey, .theme--dark #footer-11.bg--white-300,
.theme--dark #footer-11.bg--white-300 .bottom-footer-list p a { color: #6c757d; }

/*------------------------------------------*/
/*  LOGIN
/*------------------------------------------*/

.theme--dark #login, .theme--dark #signup {
  background: url(../images/login_dark.jpg);
}

.theme--dark #reset-password {
  background: url(../images/reset-password-dark.html);
}

.theme--dark #login .register-page-wrapper:after,
.theme--dark #signup .register-page-wrapper:after {
  background-color: #1d212c;
}

.theme--dark .form-data span a,
.theme--dark .register-page-form p.input-header {
  color: #fff;
}

.theme--dark .separator-line::before, 
.theme--dark .separator-line::after {
  border-top: 2px solid rgba(255, 255, 255, .15);
}

.theme--dark .reset-page-wrapper .form-control,
.theme--dark .register-page-form .form-control {
  color: #f1f1f1;
  background-color: #363f54;
  border: 1px solid #363f54;
}

.theme--dark .reset-page-wrapper form { 
  background: #1d212c;
  border: 1px solid #1d212c;
}

.theme--dark .register-page-form .form-control::-moz-placeholder { color: #b1b7cd; } 
.theme--dark .register-page-form .form-control:-ms-input-placeholder { color: #b1b7cd; } 
.theme--dark .register-page-form .form-control::-webkit-input-placeholder { color: #b1b7cd; } 

.theme--dark .reset-password-form .form-control::-moz-placeholder { color: #b1b7cd; } 
.theme--dark .reset-password-form .form-control:-ms-input-placeholder { color: #b1b7cd; } 
.theme--dark .reset-password-form .form-control::-webkit-input-placeholder { color: #b1b7cd; } 

.theme--dark  .register-page-form .btn-google {
  color: #b1b7cd;
  background-color: #363f54;
  border: 1px solid #363f54;
}




/* ==========================================================================
   STYLE CHANGER 
  ========================================================================== */

#stlChanger {
  position: fixed;
  z-index: 9999;
  font-size: 15px;
  overflow: hidden;
  right: -230px;
  top: 100px;
  cursor: pointer;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

#stlChanger.open {
  right: 0px;
}

#stlChanger .bgChanger {
  min-width: 280px;
  min-height: 280px;
}

#stlChanger .blockChanger {
  width: 230px;
}

#stlChanger .chBody {
  background: #2b2e37;
  width: 230px;
  position: relative;
  overflow: hidden;
  border: 1px solid #2b2e37;
  border-radius: 4px 0px 0px 4px;
  height: 425px; 
  overflow: scroll; 
  overflow-x: hidden;
  direction: rtl;
  margin-left: 50px;
}

#stlChanger .chBody.chBody-sm {
  height: 360px; 
}

#stlChanger .chBut {
  background: #ef2853;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1000000;
  text-align: center;
  border: 1px solid #ef2853;
  border-left: none;
  padding: 0 0 0 2px;
  border-radius: 4px 0px 0px 4px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  box-shadow: 0 0 2px rgba(50, 50, 50, 0.4); 
}

#stlChanger .chBut.icon-xs [class*="flaticon-"]:before,
#stlChanger .chBut.icon-xs [class*="flaticon-"]:after {   
  color: #fff;
  font-size: 2.15rem;
  line-height: 48px!important;
}

#stlChanger p {
  font-size: 18px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 20px;
}

#stlChanger .stBgs a {
  text-decoration: none;
  width: 50px;
  height: 50px;
  float: left;
  padding: 0;
  margin: 0 5px 8px;
  cursor: pointer;
  opacity: 1;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

#stlChanger .stBgs a img {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.stBlock1 {
  margin: 0 20px 25px 20px;
}

.stBlock .btn {
  width: 100%;
  padding: 0.65rem 1.2rem;
  line-height: 1;
  margin-top: 15px;
}

.switch {
  width: 100%;
  color: #fff;
  padding: 0.4rem 1.4rem;
  border: 2px solid #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}




/* ==========================================================================
  34. COLOR SCHEME
  ========================================================================== */ 

.bg--theme,
.shape--blue-400:after { 
  background-color: #3eb1ff; 
}

.btn--theme {
  color: #fff!important;
  border-color: #3eb1ff!important;
  background-color: #3eb1ff!important;
}

.hover--theme:hover,
.white-scroll .scroll .hover--theme:hover,
.black-scroll .scroll .hover--theme:hover {
  color: #fff!important;
  border-color: #1680fb!important;
  background-color: #1680fb!important;
}

.theme--dark .white-scroll .scroll .hover--theme:hover {
  color: #fff!important;
  border-color: #1680fb!important;
  background-color: #1680fb!important;
}

.theme--dark .white-scroll .hover--theme:hover {
  color: #fff!important;
  border-color: #1680fb!important;
  background-color: #1680fb!important;
}

.color--theme, 
.color--theme h1, 
.color--theme h2, 
.color--theme h3, 
.color--theme h4, 
.color--theme h5, 
.color--theme h6, 
.color--theme p, 
.color--theme a, 
.color--theme li, 
.color--theme i, 
.color--theme span,
.color--white .color--theme { color: #3eb1ff!important; }

.loading--theme .loader { 
  border-color: transparent #3eb1ff; 
}

.loading--theme .loader::after {
  border-color: transparent rgba(22, 128, 251, .15); 
}

.nav-theme .sm-info { 
  background-color: #3eb1ff; 
}

.wsmenu > .wsmenu-list.nav-theme > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-theme > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-theme > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #3eb1ff!important;
}

.hero-section .quick-form .form-control:focus {
  border-color: #3eb1ff;
}

.cbox-2:hover .cbox-2-ico.bg--theme { 
  color: #3eb1ff; 
  border-color: #3eb1ff; 
}

.tabs-1.tabs--theme, 
.tabs-1.tabs--theme li.current:hover { 
  color: #3eb1ff; 
  border-color: #3eb1ff; 
}

.faqs-section .tabs--theme .tabs-1 li.current,
.faqs-section .tabs--theme .tabs-1 li.current:hover {
  background-color: #3eb1ff;
  border-color: #3eb1ff!important;
}

.switch-wrap input:checked + .switcher.switcher--theme { 
  background-color: #3eb1ff; 
  border-color: #3eb1ff; 
}

.shape-ico.color--theme path,
.shape-ico.color--theme path,
.shape-ico.color--theme path {
  fill: #f4f9fc;
}

.newsletter-section .form-control:focus {
  background-color: #fff;
  border-color: #353f4f;
}

.theme-pagination .page-item.active .page-link {
  color: #fff!important;
  background-color: #3eb1ff;
  border: 2px solid #3eb1ff;
}




/* ==========================================================================
  34. XXXXX
  ========================================================================== */ 

.t-title {
  text-align: center; 
  color: #fff;
  background-color:#1d222f;
  padding: 10px 5px; 
  margin: 0 0 40px;
}

.t-title.btn-s-title {
  margin: 0;
}

.t-title h6 {
  color: #fff;
  margin-bottom: 0;
}