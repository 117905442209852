.drawer-main{
    padding: 10px;
    margin-top: 40px;
    text-align: left;
}

.div-close{
    text-align: left;
}

.image-close{
    font-size: 28px;
    margin-top: 20px;
}

.text-bar{
    font-size: 18px;
    margin-top: 15px;
    /* margin-left: 50px; */
}

.free-invoice-ribbon .ant-ribbon {
    transform: translateY(-50%);
    /* top: 50%; */
  }
  
  .free-invoice-ribbon .ant-ribbon-inner {
    right: -10px; 
    top: 0; 
  }

  .free-invoice-ribbon{
    font-size: 11px !important;
    height: 20px !important;
  }
  
  li {
    display: inline-block; 
    vertical-align: middle;
  }
  
  .h-link {
    line-height: 1.5; 
    padding: 0 10px; 
  }

  .link-invoice{
    padding-top: 21px;
    padding-right: 10px;
    font-size: 17px;
    font-weight: 500;
  }
  

@media (min-width: 481px) and (max-width: 684px) and (orientation: landscape) {
    .logo-ease{
        margin-left: 80px !important;
    }
}

@media (min-width: 685px) and (max-width: 799px) and (orientation: landscape) {
    .logo-ease{
        margin-left: 100px !important;
    }
}

@media (min-width: 685px) and (max-width: 799px) and (orientation: portrait) {
    .logo-ease{
        margin-left: 37px;
    }
}

@media (min-width: 800px) and (max-width: 1025px) and (orientation: landscape){
    .logo-ease{
        margin-left: 47px !important;
    }
}

@media (min-width: 786px) {
    .header-container{
        height: 30px !important;
    }
}

@media (min-width: 480px) {
    .header-container{
        height: 50px !important;
    }

    .wsanimated-arrow{
        margin-top: -10px;
    }
}