.ant-table-body {
  overflow-x: auto;
  scrollbar-width: none;
}

.ant-table-body::-webkit-scrollbar {
  display: none;
}

.ant-table-thead > tr > th {
  background-color: #f5f5f5 !important;
  height: 50px !important;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}
