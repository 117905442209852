.container-select-option {
  font-size: 12px;
  line-height: 1;
}

.button-action {
  margin-top: 7px;
}

.stock-quantity {
  color: green;
  font-size: 14px;
  margin-top: -21px;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
  .stockTransferCommentMobView{
    margin-top: 25px;
  }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
  .stockTransferCommentMobView{
    margin-top: 25px;
  }
}