.select-document {
    margin-top: 10px;
    width: 50% !important;
}

.card-container {
    background-color: #F5F5F5;
    padding: 10px !important;
    margin-top: 20px;
}

.card-container-select{
    padding: 0px !important;
    margin-top: 0px;
    height: calc(100vh - 650px);
    border: 0px;
}

.category-container{
    margin-top: -12px;
}

.inputDefault {
    margin-top: 1px !important;
    width: 70%;
}

.labelFieldName {
    margin-top: 15px;
}

.labelInputModal {
    margin-left: 50px;
    width: 80%;
    position: absolute;
    margin-top: -22px !important;
}

.fieldDataType {
    margin-top: -10px !important;
}

.selectDatatype {
    width: 78% !important;
    margin-left: 58px;
    position: absolute;
    margin-top: -22px;
}

.datePickerCustom {
    width: 82% !important;
    margin-left: 40px;
    margin-top: -8px !important;
}

.inputDefault {
    width: 83%;
    margin-left: 38px;
    position: absolute;
    margin-top: -22px !important;
}

.divModalInputLabels {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}

.divInputLabelName{
    margin-top: 20px !important;
}

.divSelectDataType{
    margin-top: 20px !important;
}

.divDefaultValue{
    margin-top: -8px;
}

.buttonAddColumnField{
    margin-top: -23px;
}

.card-container-add{
    padding: 10px !important;
    margin-top: 20px;
    height: calc(100vh - 480px);
    border: 0px !important;
}

.selectConditional{
    margin-top: -20px !important;
    width: 68%;
    margin-left: 132px;
}

.card-container-add.select-active {
    height: calc(100vh - 420px)
}

.divDefaultValue {
    transition: margin-top 0.3s ease;
    margin-top: -8px !important; 
}

.divDefaultValue.margin-top-15 {
    margin-top: 20px !important;
}

.modal-custom-fields{
    margin-top: -20px !important;
    justify-self: center !important;
    place-self: center;
    place-items: center;
}

.inputEditCustomField{
    margin-bottom: 8px;
    width: 60%;
}

.delete-edit-custom{
    margin-left: -5px;
}

.ant-modal .ant-modal-content {
    position: relative;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 20px 24px;
    width: 500px;
}

.select-level{
    width: 358px !important;
    margin-left: 87px;
}

.list-custom-field{
    height: calc(100vh - 250px) !important;
}