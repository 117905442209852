.breadcrumbs {
  margin: 16px 0 !important;
  flex: 1;
}

.searchButtonForUser {
  text-align: right;
  margin-top: -40px;
}

.content-user-management {
  padding-top: 10;
  margin: 0;
  padding-left: 0;
  min-height: 280;
  border-radius: 10px;
  background-color: #f5f5f5;
  margin-top: 20px;
}


.button-add-user {
  margin-left: auto;
}

.div-actions {
  display: flex;
  gap: 8;
}

.searchInputUser {
  width: 150px;
}

.buttonAddUser {
  width: 150px;
}

.head-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


@media screen and (min-width: 0px) and (max-width: 413px) {
  .buttonUserMobileView{
      display: flex;
      flex-direction: row;
  }

  .searchUserMob{
      margin-top: 10px;
  }

  .buttomCancelMobView{
    margin-right: 12px;
  }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
  .buttonUserMobileView{
      display: flex;
      flex-direction: row;
  }

  .searchUserMob{
      margin-top: 10px;
  }

  .buttomCancelMobView{
    margin-right: 12px;
  }
}