.newFields {
    display: flex;
    margin-top: 20px;
    column-gap: 15px;
}

.catData {
    margin-top: 10px;
    padding: 5px;
    border-radius: 2px;
    border: 1px solid silver;
}

.fieldIcon {
    font-size: 30px;
    cursor: pointer;
}

.iconContainer {
    width: 100px;
}

.heirarchySpace {
    width: 50px;
}

.list-category{
    height: auto;
    min-height: calc(100vh - 270px);
    margin-top: 20px;
    border-radius: 10px;
    padding: 16px 16px;
    border: 1px solid rgba(140, 140, 140, 0.35);
}

.action-button{
    justify-content: right !important;
    margin-top: -22px;
    /* margin-left: 200px !important; */
}

.category-node {
    display: flex;
    align-items: center;
    width: 100% !important;
}

.category-form{
    margin-top: 15px;
}

.expand-icon{
    height: 14px !important;
    width: 14px !important;
    font-size: 10px !important;
    margin-top: 5px;
}

.div-category{
    display: flex;
    flex-direction: row !important;
}

.input-search-category{
  width: 76%;
}

.buttonAddCategory{
    margin-left: 15px;
}

.backLinkSM{
    color: #1677ff
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-tree .ant-tree-node-content-wrapper, 
:where(.css-dev-only-do-not-override-ccdg5a).ant-tree .ant-tree-checkbox+span {
    position: relative;
    z-index: auto;
    min-height: 24px;
    margin: 0;
    padding: 0 4px;
    color: inherit;
    line-height: 24px;
    background: transparent;
    width: 100%;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s, border 0s, line-height 0s, box-shadow 0s;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
    .mobAddCategory{
        display: flex;
        flex-direction: row;
    }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
    .mobAddCategory{
        display: flex;
        flex-direction: row;
    }
}