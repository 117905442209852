.main-container {
  width: 100%;
  height: 100%;
  /* margin-top: -210px; */
  z-index: 10;
}

.container-summary {
  margin-top: -50px;
}

.container-blocks {
  margin-top: -50px;
}

.image-inventory {
  height: 50px;
  width: 50px;
}

.paragh-sales {
  margin-top: 7px;
}

.fbox-12 {
  height: 320px;
}

@media (max-width: 1024px) {
  /* .main-container {
        height: 500px;
    } */
  .container-summary,
  .container-blocks {
    margin-top: -40px;
  }
  .image-inventory {
    height: 40px;
    width: 40px;
  }
  .paragh-sales {
    margin-top: 6px;
  }
  .fbox-12 {
    height: 400px;
    width: 180px;
  }
}

@media (max-width: 768px) {
  .main-container {
    padding-top: 50px;
    z-index: 10;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .container-blocks {
    margin-top: 20px;
  }

  .container-summary {
    margin-top: 20px;
  }

  .col-md-7,
  .col-md-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .fbox-13-wrapper {
    text-align: center;
  }

  .fbox-12 {
    margin-bottom: 20px;
  }

  .fbox-ico {
    display: flex;
    justify-content: center;
  }

  .image-inventory {
    height: 40px;
    width: 40px;
  }

  .s-46 {
    font-size: 28px;
    line-height: 1.2;
  }

  .cbox-1 {
    margin-bottom: 20px;
  }

  .cbox-1 p {
    font-size: 16px;
  }

  .fbox-13-wrapper .row {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-aligns the cards */
  }

  .fbox-12 {
    width: 100%; /* Ensure each card takes up full width on smaller screens */
    margin-bottom: 20px; /* Add margin between cards */
    box-sizing: border-box; /* Ensure padding and borders are included in the width */
  }

  /* Adjust the image size within the blocks */
  .fbox-ico img {
    width: 50px;
    height: 50px;
  }

  /* Adjust the text size */
  .fbox-txt h5 {
    font-size: 18px;
    text-align: center; /* Center-align the text */
  }

  .fbox-txt p {
    font-size: 14px;
    text-align: center; /* Center-align the text */
  }

  .fbox-12 {
    min-height: 200px; /* Set a minimum height for each card */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribute space evenly within the card */
    height: 280px;
    width: 280px;
    margin-left: -80px;
  }
}

@media (max-width: 480px) {
  /* .main-container {
        height: 300px;
    } */
  .container-summary,
  .container-blocks {
    margin-top: -20px;
  }
  .image-inventory {
    height: 25px;
    width: 25px;
  }
  .paragh-sales {
    margin-top: 4px;
  }
  .fbox-12 {
    margin-left: 30px;
  }

  .shape--purple-100 {
    background-color: transparent;
  }

  .title-feature {
    font-size: 30px !important;
    font-weight: 500 !important;
  }

  .main-container {
    height: 100%;
    width: 100%;
    top: 25px;
  }
}

@media screen and (min-width: 0px) and (max-width: 413px) {
  .main-container {
    margin: 0px !important;
    padding: 0px !important;
  }

  .container-blocks {
    place-items: center !important;
  }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
  .main-container {
    margin: 0px !important;
    padding: 0px !important;
  }

  .container-blocks {
    place-items: center !important;
  }
}
