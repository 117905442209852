.approve {
    color: green;
    cursor: pointer;
}

.delete {
    color: red;
    cursor: pointer;
}

.input{
    height: 32px;
}