.permissionBody {
    display: flex;
    padding: 2px 0 0 20px;
    column-gap: 3px;
    row-gap: 3px;
}

.permissionCell {
    padding: 10px;
    min-width: 190px;
    background-color: rgb(246, 246, 246);
}

.default-permission{
    /* padding: 10px 10px; */
    font-size: 14px;
    margin-top: 10px;
}

.heading-element{
    margin-left: 16px;
}

.text-default{
    font-size: 20px;
}

.paragh-default{
    font-size: 15px;
    margin-left: 20px;
}

/* .table-container-permission{
    overflow-x: scroll;
} */

.lock-icon{
    font-size: 35px !important;
}

.content-permission{
    /* padding-top: 10; */
    margin: 0;
    /* padding-left: 0;
    padding-right: 20; */
    height: auto;
    background-color: #FFFFFF;
    border-radius: 10px;
}