.info-icon {
  color: gray;
  font-size: 20px;
}

.total-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  font-size: 14px;
}

.label,
.value {
  font-size: 17px;
  font-weight: 600;
}

.grand-total .label {
  font-weight: bolder;
}

.total-item .value input {
  padding: 0px 24px;
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
}

.button-group {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
}

.button-large {
  width: 36%;
  margin-left: 8px;
}

.textArea-container {
  position: relative;
  height: fit-content;
}

.textArea-wrapper {
  position: relative;
  display: inline-block;
}

.termCondition-textarea {
  width: 100%;
}

.edit-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 1;
}

.termsCondition {
  flex-grow: 1;
}

.terms-text {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}