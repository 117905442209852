.blogImage {
  height: auto;
  width: 150px;
  border-radius: 5px;
}

.actionBody {
  display: flex;
  column-gap: 15px;
  margin-top: 20px;
}

.content-blog{
  margin-top: 20px !important;
}

.div-input-button{
  margin-top: -40px;
}

.searchInputBlog{
  width: 150px;
  margin-left: 950px;
}

.buttonAddBlog{
  width: 150px;
}

.actionBody>div {
  cursor: pointer;
}

.anticon svg {
  display: inline-block;
  height: 15px !important; 
  margin-top: 0 !important;
}

.tox-icon>svg {
  height: 30px !important;
}

.blogBody {
  display: flex;
  column-gap: 10px;
  margin-left: 10px;
  padding: 10px 5px;
  border-bottom: 1px solid #e3e3e3;
  margin-top: 10px;
  justify-content: space-between;
}

.blogData {
  display: flex;
  column-gap: 20px;
}

.blogActions {
  display: flex;
  column-gap: 20px;
}

.blogTitle {
  font-size: 16px;
  font-weight: 500;
}

.edit {
  color: green;
  cursor: pointer;
  margin-left: -100px;
}

.delete {
  color: red;
  cursor: pointer;
}

.div-input-button {
  display: flex;
  justify-content: space-between;
}

.input-search {
  margin-right: 20px;
}

.button-add-blog {
  margin-left: auto;
}

.content-blog {
  padding-top: 10;
  margin: 0;
  padding-left: 0;
  padding-right: 20;
  min-height: 280;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.button-publish {
  margin-left: 10px;
}