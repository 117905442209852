.inventory-list-container {
    width: 100%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .inventory-list-item {
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .parent-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .child-items {
    margin-top: 10px; 
    padding-left: 20px; 
  }
  
  .inventory-list-item-child {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px 0;
  }
  
  .list-item-text {
    font-size: 16px;
    color: #333;
  }
  
  .list-item-switch {
    margin-left: auto;
  }  