.container-main{
    margin-left: 60px;
    margin-top: 15px !important;
}

.sub-container-term{
    margin-left: 25px !important;
}

.rounded-lg{
  margin-left: 32px;
  margin-top: 10px;
}

#terms-and-condition {
  overflow-y: auto; /* Ensure this container allows vertical scrolling */
  height: 100vh;
}

/* Mobile screens 480px and below */
@media (max-width: 480px) {
    .container-main {
      margin: 10px !important; /* Reduce the margin for smaller screens */
      margin-left: -21px !important;
    }

    .term-footer-container{
      height: calc(100vh - 600px);
      width: 100%;
    }
  
    .sub-container-term {
      margin-left: -75px !important; /* Remove left margin to center the title */
      margin-top: 85px !important;
    }
  
    .min-h-screen {
      min-height: auto; /* Adjust the minimum height to fit the content */
    }

  
    .text-3xl {
      font-size: 1.75rem; /* Adjust title font size */
    }
  
    .text-2xl {
      font-size: 1.5rem; /* Adjust subtitle font size */
    }

    /* .list-inside {
        padding-left: 20px; 
      } */
    
      ul, p {
        font-size: 0.9rem; /* Adjust the text size for readability */
        line-height: 1.4; /* Improve line spacing */
      }
    
      .mb-6 {
        margin-bottom: 12px; /* Reduce bottom margin */
      }
    
      /* .mt-10 {
        margin-top: 20px; 
      } */
    
      /* .mt-8 {
        margin-top: 16px
      } */
    
      .rounded-lg {
        border-radius: 8px; 
        margin-top: -15px;
        width: auto;
        margin-left: 15px;
      }

      #terms-and-condition {
        overflow-y: auto; /* Ensure this container allows vertical scrolling */
        height: 100%;
      }
  
  }

  
  /* Small screens (phones) */
@media (max-width: 640px) {
    .container-main {
      margin: 10px; /* Less margin for smaller screens */
    }
  
    .sub-container-term {
      margin-left: 0; /* Reset the margin */
      text-align: center; /* Center align the title */
    }
  
    h1.text-3xl {
      font-size: 1.5rem; /* Smaller font size for mobile */
      margin-top: 1rem; /* Adjust top margin */
    }
  
    h2.text-2xl {
      font-size: 1.25rem; /* Smaller font size for mobile */
    }
  
    .space-y-6 {
      padding: 10px; /* Add padding for better readability on smaller screens */
    }

    #terms-and-condition {
      overflow-y: auto; /* Ensure this container allows vertical scrolling */
      height: 100vh;
    }
  }
  
  /* Medium screens (tablets) */
  @media (min-width: 641px) and (max-width: 1024px) {
    .container-main {
      margin: 20px; /* Adequate margin for tablets */
    }
  
    .sub-container-term {
      margin-left: 10px; /* Slightly adjust margin */
      text-align: center; /* Center align the title */
    }
  
    h1.text-3xl {
      font-size: 1.75rem; /* Adjust font size for tablets */
      margin-top: 1.5rem; /* Adjust top margin */
    }
  
    h2.text-2xl {
      font-size: 1.5rem; /* Adjust font size for tablets */
    }

    #terms-and-condition {
      overflow-y: auto; /* Ensure this container allows vertical scrolling */
      height: 100vh;
    }
  }
  
  /* Large screens (desktops) */
  @media (min-width: 1025px) {
    .container-main {
      margin: 60px; /* Original margin for larger screens */
    }
  
    .sub-container-term {
      margin-left: 18px; /* Original margin */
    }
  }
  
  @media screen and (min-width: 1241px) and (max-width: 1440px) {
    .container-main{
      margin-left: 60px;
      margin-top: 15px !important;
  }
  }
  
  @media screen and (min-width: 1441px) and (max-width: 1640px) {
    .container-main{
      margin-left: 60px;
      margin-top: 15px !important;
  }
  }
  
  @media screen and (min-width: 1641px) and (max-width: 2440px) {
    .container-main{
      margin-left: 150px;
      margin-top: 15px !important;
  }
  }