.additional-charge-table {
  background-color: white;
  border-radius: 8px; 
}

.buttonSpacing {
  margin-top: 10px;
}

.itemGridFields-sm {
  width: 100px;
}

.itemGridFields-md {
  width: 150px;
}

.itemGridFields-lg {
  width: 200px;
}

.delete-button {
  border: none;
  padding: 0; 
  cursor: pointer;
}
