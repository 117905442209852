.contact-main-container{
    height: calc(100vh - 250px);
    padding: 20px;
    width: calc(100vw - 260px);
    margin: 10px 110px 10px 110px !important;
    display: flex;
    flex-direction: row;
}

.contact-sub-container-left{
    height: auto;
    width: 50%;
    padding: 10px;
}

.contact-sub-container-right{
    height: auto;
    width: 50%;
    padding: 15px;
}

.heading-contact-us{
    font-weight: 400;
}

.text-email-info{
    color: black;
    font-size: 16px;
    font-weight: 200;
    margin-top: 10px;
}

.text-contact-info{
    color: black;
    font-size: 16px;
    font-weight: 200;
    margin-top: 5px;
}

.text-contact-intro{
    color: black;
    font-weight: 200;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
    .contact-main-container{
        height: auto;
        padding: 20px;
        width: auto;
        margin: 40px 10px 10px  !important;
        display: flex;
        flex-direction: column;
    }
    
    .contact-sub-container-left{
        height: auto;
        width: 100%;
        padding: 10px;
    }
    
    .contact-sub-container-right{
        height: auto;
        width: 100%;
        padding: 10px;
    }
    
    .heading-contact-us{
        font-weight: 400;
    }
    
    .text-email-info{
        color: black;
        font-size: 16px;
        font-weight: 200;
        margin-top: 10px;
    }
    
    .text-contact-info{
        color: black;
        font-size: 16px;
        font-weight: 200;
        margin-top: 5px;
    }
    
    .text-contact-intro{
        color: black;
        font-weight: 200;
    }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
    .contact-main-container{
        height: auto;
        padding: 20px;
        width: auto;
        margin: 40px 10px 10px !important;
        display: flex;
        flex-direction: column;
    }
    
    .contact-sub-container-left{
        height: auto;
        width: 100%;
        padding: 10px;
    }
    
    .contact-sub-container-right{
        height: auto;
        width: 100%;
        padding: 10px;
    }
    
    .heading-contact-us{
        font-weight: 400;
    }
    
    .text-email-info{
        color: black;
        font-size: 16px;
        font-weight: 200;
        margin-top: 10px;
    }
    
    .text-contact-info{
        color: black;
        font-size: 16px;
        font-weight: 200;
        margin-top: 5px;
    }
    
    .text-contact-intro{
        color: black;
        font-weight: 200;
    }
}

@media screen and (min-width: 1241px) and (max-width: 1440px) {
    .contact-main-container{
        height: calc(100vh - 250px);
        padding: 20px;
        width: calc(100vw - 260px);
        margin: 10px 110px 10px 110px !important;
        display: flex;
        flex-direction: row;
    }
  }
  
  @media screen and (min-width: 1441px) and (max-width: 1640px) {
    .contact-main-container{
        height: calc(100vh - 250px);
        padding: 20px;
        width: calc(100vw - 260px);
        margin: 10px 110px 10px 110px !important;
        display: flex;
        flex-direction: row;
    }
  }
  
  @media screen and (min-width: 1641px) and (max-width: 2440px) {
    .contact-main-container{
        height: calc(100vh - 250px);
        padding: 20px;
        width: calc(100vw - 260px);
        margin: 10px 190px 10px 190px !important;
        display: flex;
        flex-direction: row;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .contact-main-container{
        height: auto;
        padding: 20px;
        width: calc(100vw - 100px);
        margin: 10px 60px 10px 60px !important;
        display: flex;
        flex-direction: row;
    }
    
    .contact-sub-container-left{
        height: auto;
        width: 50%;
        padding: 10px;
    }
    
    .contact-sub-container-right{
        height: auto;
        width: 50%;
        padding: 10px;
        align-items: center;
        place-content: center;
    }
    
    .heading-contact-us{
        font-weight: 400;
    }
    
    .text-email-info{
        color: black;
        font-size: 16px;
        font-weight: 200;
        margin-top: 10px;
    }
    
    .text-contact-info{
        color: black;
        font-size: 16px;
        font-weight: 200;
        margin-top: 5px;
    }
    
    .text-contact-intro{
        color: black;
        font-weight: 200;
    }
    
    .inputUserName{
        margin-top: 20px;
    }
    
    .inputEmailAddress{
        margin-top: 10px;
    }
    
    .inputUserMessage{
        margin-top: 10px;
    }
    
    .buttonContactSubmit{
        margin-top: 10px;
    }
  }