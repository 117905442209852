
.addMore {
    color: #1677ff;
    cursor: pointer;
}

.formLabel {
    color: black;
    font-size: 16px;
    font-weight: 500;
}

.addressList {
    padding: 10px;
    border: 1px dotted gray;
    border-radius: 5px;
    margin-bottom: 10px;
}

.searchbuyer {
    width: 150px;
    margin-right: 20px;
}

.select-buyer {
    width: 150px;
    margin-left: 780px;
    position: absolute;
    margin-top: -42px;
}

.div-head-breadcrumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Layout Styles */
.layout {
    background-color: #f0f4ff;
    /* Light blue background */
    border-radius: 8px;
    /* Rounded corners */
    padding: 0px 15px 15px 15px;
    /* Consistent padding */
    transition: all 0.3s ease-in-out;
    /* Smooth transition */
}

/* Breadcrumb Styles */
.breadcrumb {
    font-size: 14px;
    /* Slightly larger font */
    color: #1a1a1a;
    /* Darker color for better readability */
    transition: color 0.3s ease-in-out;
}

.breadcrumb-item:hover {
    color: #1890ff;
    /* Hover effect */
}

/* Button Styles */
.ant-btn-link {
    color: #1890ff;
    /* Blue color for buttons */
    transition: color 0.3s ease-in-out;
    /* Smooth color transition */
}

.ant-btn-link:hover {
    color: #40a9ff;
    /* Lighter blue on hover */
}

.ant-input:focus {
    border-color: #40a9ff;
    /* Lighter blue on focus */
    box-shadow: 0 0 8px rgba(24, 144, 255, 0.4);
    /* Glow effect */
}

/* Table Styles */
.table-container {
    background-color: white;
    /* White background for the table */
    border-radius: 8px;
    /* Rounded corners */
    transition: all 0.3s ease-in-out;
}

.table-container tr:hover {
    background-color: #e6f7ff;
    /* Light blue background on hover */
    transition: background-color 0.3s ease-in-out;
}

/* Pagination Styles */
.ant-pagination {
    background-color: transparent;
    /* Clear background */
    transition: all 0.3s ease-in-out;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .flexBox {
        flex-direction: column;
        /* Stack elements on smaller screens */
        transition: all 0.3s ease-in-out;
    }
}

@media screen and (min-width: 0px) and (max-width: 413px) {
    .buttonCompanyMobileRow{
        display: flex;
        flex-direction: row;
    }

    .searchCompanyInputMob{
        margin-top: 10px;
    }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
    .buttonCompanyMobileRow{
        display: flex;
        flex-direction: row;
    }

    .searchCompanyInputMob{
        margin-top: 10px;
    }
}