.layout-address-main {
  margin: 0 20px;
}

.action-address {
  display: flex;
}

.profileCover {
  height: 200px;
  background-color: aliceblue;
  border-radius: 10px;
  background: url('https://t3.ftcdn.net/jpg/05/06/47/74/360_F_506477403_zZAR1UIabJYIWKvFKpbP1Z70vWbVv7co.jpg');
  background-size: cover;
  
}

.profileLogoBody {
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
}

.logoAndDetails {
  display: flex;
  column-gap: 20px;
}

.profileLogo {
  width: 100px; 
  height: 100px;
  border-radius: 50%;
  margin-top: -40px;
  border: 2px solid white;
  object-fit: cover; 
}

.companyName {
  font-size: 20px;
  font-weight: 500;
}

.companyDetails{
  display: flex;
  width: 100%;
  column-gap: 50px;
}

.companyDetailsMain {
  flex-grow: 3;
  flex-shrink: 1;
  flex-basis: 0;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border-radius: 10px;
  padding: 0 20px 20px 20px;
}

.companyDetailsToEdit {
  flex-grow: 9;
  flex-shrink: 1;
  flex-basis: 0;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border-radius: 10px;
  padding: 20px;
}

.profileFields {
  max-width: 250px;
  margin-bottom: 20px;
}

.headingDetails{ 
  padding: 10px 0;
}

.heading {
  margin: 20px 0 10px 0;
  color: #1677ff;
}

.companyDetailsMain > div {
  padding-top: 15px;
}

.form-user-details{
  margin-top: 30px !important;
}

.input-company-name{
  margin-left: 50px;
  width: 500px;
}

.input-company-email{
  margin-left: 55px;
  width: 500px;
}

.input-contact{
  margin-left: 19px;
  width: 500px;
}

.input-company-website{
  margin-left: 40px;
  width: 500px;
}

.input-upload{
  margin-left: 58px;
}

.input-name{
  width: 500px;
  margin-left: 115px;
}

.input-user-contact{
  width: 500px;
  margin-left: 80px;
}

.input-email{
  width: 500px;
  margin-left: 118px;
}

.input-role{
  width: 500px;
  margin-left: 123px;
}

.profileWrapper {
    overflow: auto !important; 
}

.menu-account{
  width: 250px;
  min-width: 250px; 
  max-width: 250px; 
}