.head-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.breadcrumbs{
    margin: 16px 0;
    flex: 1;
}

.search-input{
    display: flex;
    justify-content: space-between;
}

.searchInputStore{
    margin-right: 20px;
    width: 150px;
}

.buttonAddStore{
    margin-left: auto;
    width: 150px;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
    .buttonStoreMobileViewRow{
        display: flex;
        flex-direction: row;
    }

    .searchStoreInputMob{
        margin-top: 10px;
    }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
    .buttonStoreMobileViewRow{
        display: flex;
        flex-direction: row;
    }

    .searchStoreInputMob{
        margin-top: 10px;
    }
}