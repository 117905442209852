.Item-Breadcrummb{
    margin: 16px 0px;
    flex: 1;
}

.div-head-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.inputDivItem{
    display: flex;
    justify-content: space-between;
}

.inputSearchItem{
    margin-right: 20px;
    width: 150px !important;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
    .buttonItemFilter{
        height: auto;
        width: 30px;
        margin-left: 20px;
    }

    .mobileViewFilterSearchRow{
        margin-top: 10px;
    }

    .buttonEllipsisMobile{
        margin-left: 18px;
    }

    .mobActionColumn{
        display: flex;
        flex-direction: column;
    }

    .mobItemIdCol{
        width: 60px !important;
    }

    .actionButtonMob{
        margin-left: 5px;
        margin-bottom: 5px;
    }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
    .buttonItemFilter{
        height: auto;
        width: 30px;
        margin-left: 20px;
    }

    .mobileViewFilterSearchRow{
        margin-top: 10px;
    }

    .buttonEllipsisMobile{
        margin-left: 50px;
    }

    .mobActionColumn{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .mobItemIdCol{
        width: 40px;
    }

    .actionButtonMob{
        margin-left: 10px;
    }
}