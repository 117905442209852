.cascader-div {
    display: flex;
    gap: 10px;
    /* flex-wrap: wrap; */
    justify-content: flex-end;
}

.search-quotation {
    width: 170px;
}

.cascaderOne {
    width: 150px;
}

.cascaderTwo {
    width: 150px;
    margin-left: 10px;
}

.search-quotation {
    width: 150px;
}

.space-docs {
    margin-left: 10px !important;
}

.documentSlicerPanel {
    position: relative;
    min-height: 100%;
    /* Ensures the panel takes full height */
}

.loader-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
    .searchDocumentInput{
        width: 80% !important;
    }

    .buttonDocumentFilter{
        margin-left: 15px;
    }

    .documentFilterSearchRow{
        margin-top: 10px;
    }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
    .searchDocumentInput{
        width: 80% !important;
    }

    .buttonDocumentFilter{
        margin-left: 15px;
    }

    .documentFilterSearchRow{
        margin-top: 10px;
    }
}