.addressList {
    margin-top: 10px;
}

.addressHeader {
    display: flex;
}

.defaultStatus {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 10px;
    transition: all 0.3s ease;
    margin-left: 10px;
}

.defaultStatus.is-not-default {
    border: 1px solid #1677ff;
    font-size: 10px;
    color: #1677ff;
    padding: 2px 5px 0px 5px;
    border-radius: 3px;
    margin-left: 10px;
    cursor: pointer;
}

.defaultStatus.Default {
    border: 1px solid rgb(19, 215, 19);
    font-size: 10px;
    color: rgb(21, 164, 21);
    padding: 2px 5px 0px 5px;
    border-radius: 3px;
    margin-left: 10px;
    cursor: pointer;
}

.defaultStatus:hover {
    opacity: 0.9;
}

.addressRadio {
    margin-left: auto !important;
}
 
.ant-modal-close {
    padding: 5px !important;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
     .ant-modal .ant-modal-content{
        max-width: 100%;
    }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
     .ant-modal .ant-modal-content{
        max-width: 100%;
    }
}