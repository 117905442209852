.modal-series-container {
  width: 700px !important;
  /* margin-top: -40px; */
}

.document-series-heading-one {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.table-document-series {
  margin-top: 15px;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 5px;
}

.series-form {
  margin-top: 10px;
}

.heading-series {
  font-weight: 500;
  font-size: 18px !important;
}

.heading-series-profile{
  font-weight: 400;
  font-size: 20px !important;
}

.div-back-to-document{
    display: flex;
    flex-direction: row;
}

.paragh-series {
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
}

.evenRow {
  background-color: #fff;
  width: calc(100% - 10px) !important;
  margin-left: 5px !important;
  border-radius: 12px !important;
  height: 40px !important;
  transition: all 0.3s ease;
}

.ant-table-thead>tr>th {
  background-color: #ffffff;
  font-weight: bold;
}

.ant-table-thead>tr>th {
  font-size: 14px;
}

.custom-row:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.9);
  width: 90%;
  margin: 0 5px;
  box-sizing: border-box;
}

.custom-row {
  background-color: #f5f5f5;
}

.custom-row:nth-child(odd) tr {
  padding: 10px;
  border-radius: 15px;
  border-top: 15px;
  background-color: #f5f5f5;
}

.custom-row th {
  background-color: #f5f5f5;
}

.ant-table-thead>tr>th {
  background-color: #ffffff !important;
}

  .checkbox{
    width: 14px; 
  height: 14px;
  margin-top: 8px;
  }

  .icon-arrow-left{
    margin-right: 10px;
    cursor: pointer;
    position: absolute;
    margin-top: 15px;
  }

  .span-heading-series{
    margin-left: 20px;
  }

  .modal-header-profile{
    align-items: center !important;
    display: flex;
    flex-direction: row;
  }

  .button-add-series{
    margin-left: auto !important;
    margin-bottom: 16px;
  }