.bulk-drawer-container{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.logo-excel{
    width: 30px;
    height: 30px;
}

.link-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 10px;
}

.sub-link-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    width: 100%;
}

.text-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #969292;
    /* margin-top: 5px; */
}

.upload-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
}

.custom-file-upload {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px dashed #d9d9d9;
    text-align: center;
    width: 100%;
    height: calc(100vh - 550px);
    border-radius: 10px;
    align-items: center !important;
    justify-content: center !important;
}

#file-upload {
    display: none;
}