.documentContainer {
  background: white;
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: black;
  overflow-y: auto;
  overflow-x: hidden;
}

.documentHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.buyerSupplierContainer {
  display: flex;
  column-gap: 20px;
}

.supplierDetails {
  /* border: 1px solid black; */
  /* padding: 10px; */
  flex-grow: 1;
}

.buyerDetails {
  /* border: 1px solid black; */
  /* padding: 20px; */
  flex-grow: 1;
  /* Removed the comma here */
}

.formContainer {
  border: 1px solid silver;
  padding: 10px;
  margin-top: 20px;
  display: inline-block;
  width: 100%;
}

.formFields {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
}

.formLabel {
  display: flex;
  flex: 1;
}

.formValue {
  margin-left: 10px;
  font-weight: normal;
}

.documentTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.documentTable th,
.documentTable td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.logisticDetails {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.logistic-info-section {
  flex: 1;
  margin-right: 20px;
}

.totals {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.total-item {
  display: flex;
  justify-content: space-between;
}

.termsAndCondition {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.watermark {
  position: fixed;
  /* Keeps the watermark in place */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Centers the watermark */
  font-size: 150px;
  /* Large font for the watermark */
  color: rgba(0, 0, 0, 0.1);
  /* Light, translucent text */
  z-index: 1;
  /* Sends the watermark behind other content */
  pointer-events: none;
  /* Prevents interaction with the watermark */
  user-select: none;
  /* Prevents text selection */
  white-space: nowrap;
  /* Prevents text wrapping */
  font-weight: bold;
  /* Optional: Makes the text bold */
  text-transform: uppercase;
  /* Optional: Uppercase text */
  transform: translate(-50%, -50%) rotate(-30deg);
}

.slicerLoader {
  width: 100%;
  height: calc(100vh - 270px);
  position: absolute;
  background: white;
  opacity: 0.5;
}

.header-split-view{
  height: 50px;
  background-color: #f2f2f0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 100;
}

.spanIndex{
  margin-right: 20px;
}

.iconArrowUp{
  margin-left: 20px;
}

@media print {
  body * {
    visibility: hidden;
    margin: 0;
    padding: 0;
  }

  #documentContainer,
  #documentContainer * {
    visibility: visible;
    box-shadow: none;
    color: black;
  }

  #documentContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  @page {
    margin: 50px;
    /* Remove default page margins */
  }
}

@media screen and (min-width: 0px) and (max-width: 413px) {
  .documentContainer{
    padding: 15px;
  }

  .buyerSupplierContainer{
    display: flex;
    flex-direction: column;
  }

  .supplierDetails{
    max-width: 100% !important;
  }

  .logisticDetails{
    display: flex;
    flex-direction: column !important;
  }

  .bankDetails{
    max-width: 100% !important;
    width: 100% !important;
    margin: 0px !important;
  }

  .documentMobileRow{
    display: flex;
    flex-direction: row !important;
  }

  .documentButtonRow{
    display: flex;
    flex-direction: row !important;
  }

  .containerDocumentTable{
    width : 100%;
    overflow-x: auto !important;
  }

  .buttonEditDocument{
    margin-left: 20px;
  }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
  .documentContainer{
    display: flex;
    flex-direction: column;
  }

  .buyerSupplierContainer{
    display: flex;
    flex-direction: column;
  }

  .supplierDetails{
    max-width: 100% !important;
  }

  .documentTable{
    overflow-y: auto !important;
  }

  .logisticDetails{
    display: flex;
    flex-direction: column !important;
  }

  .bankDetails{
    max-width: 100% !important;
    width: 100% !important;
    margin: 0px !important;
  }

  .documentMobileRow{
    display: flex;
    flex-direction: row !important;
  }

  .documentButtonRow{
    display: flex;
    flex-direction: row !important;
  }

  .containerDocumentTable{
    width : 100%;
    overflow-x: auto !important;
  }

  .buttonEditDocument{
    margin-left: 20px;
  }
}