.container-privacy{
    padding: 0px !important;
    margin-left: 70px;
    margin-top:25px !important;
}

.heading-privacy{
    margin-left: 49px ;
}

.paragh-privacy{
    margin-left: 49px;
    margin-top: 10px
}

#privacy-policy{
    overflow-y: auto;
    height: 100vh;
}

.list-cookie{
    list-style-type: disc !important;
}

@media (max-width: 480px) {
    .container-privacy{
        margin-top: 55px !important;
        /* width: 100%;
        height: 100%; */
        padding: 0px;
        margin: 0px;
    }

    .heading-privacy{
        padding-top: 20px !important;
        margin-left: 15px;
    }

    .paragh-privacy{
        margin-left: 15px;
    }

    #privacy-policy{
        overflow-y: auto;
        height: 100vh;
    }
}

@media screen and (min-width: 1241px) and (max-width: 1440px) {
    .container-privacy{
        padding: 0px !important;
        margin-left: 70px;
        margin-top:25px !important;
    }
  }
  
  @media screen and (min-width: 1441px) and (max-width: 1640px) {
    .container-privacy{
        padding: 0px !important;
        margin-left: 70px;
        margin-top:25px !important;
    }
  }
  
  @media screen and (min-width: 1641px) and (max-width: 2440px) {
    .container-privacy{
        padding: 0px !important;
        margin-left: 154px;
        margin-top:25px !important;
    }
  }