.timelineItem {
  height: 200px;
  width: 200px;
}

.timelineItem:nth-child(even) {
  margin-top: 83px;
}

.timelineParent {
  display: flex;
  column-gap: 30px;
  padding: 10px;
  height: calc(100vh - 480px) !important;
}

.strongTitle {
  font-size: 16px;
  font-weight: 500;
}

.textCenter {
  height: calc(100vh - 690px);
}

.timelineHeading {
  font-size: 12px;
  font-weight: 400;
}

.timelineArrowYellow {
  position: relative;
  display: inline-block;
  width: 100%;
  /* Arrow body width */
  height: 10px;
  /* Arrow body height */
}

.timelineArrowYellow::after {
  content: "";
  position: absolute;
  top: -5px;
  right: -20px;
  /* Offset the triangle to the right */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  /* Match half the arrow's height */
  border-bottom: 10px solid transparent;
  /* Match half the arrow's height */
  border-left: 20px solid #f2cb06;
  /* Width and color of the pointy face */
}

.timelineArrowGreen {
  position: relative;
  display: inline-block;
  width: 100%;
  /* Arrow body width */
  height: 10px;
  /* Arrow body height */
}

.timelineArrowGreen::after {
  content: "";
  position: absolute;
  top: -5px;
  right: -20px;
  /* Offset the triangle to the right */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  /* Match half the arrow's height */
  border-bottom: 10px solid transparent;
  /* Match half the arrow's height */
  border-left: 20px solid #6db201
    /* Width and color of the pointy face */
}

.timelineArrowBlue {
  position: relative;
  display: inline-block;
  width: 90%;
  /* Arrow body width */
  height: 10px;
  /* Arrow body height */
}

.timelineArrowBlue::after {
  content: "";
  position: absolute;
  top: -5px;
  right: -20px;
  /* Offset the triangle to the right */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  /* Match half the arrow's height */
  border-bottom: 10px solid transparent;
  /* Match half the arrow's height */
  border-left: 20px solid #00a0e0
    /* Width and color of the pointy face */
}

.timelineArrowPurple {
  position: relative;
  display: inline-block;
  width: 90%;
  /* Arrow body width */
  height: 10px;
  /* Arrow body height */
}

.timelineArrowPurple::after {
  content: "";
  position: absolute;
  top: -5px;
  right: -20px;
  /* Offset the triangle to the right */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  /* Match half the arrow's height */
  border-bottom: 10px solid transparent;
  /* Match half the arrow's height */
  border-left: 20px solid #7f2af7
    /* Width and color of the pointy face */
}

.timelineArrowPink {
  position: relative;
  display: inline-block;
  width: 100%;
  /* Arrow body width */
  height: 10px;
  /* Arrow body height */
}

.timelineArrowFinal {
  position: relative;
  display: inline-block;
  width: 100%;
  /* Arrow body width */
  height: 10px;
  /* Arrow body height */
}

.timelineArrowFinalPurchase {
  position: relative;
  display: inline-block;
  width: 100%;
  /* Arrow body width */
  height: 10px;
  /* Arrow body height */
}

.timelineArrowMagenta{
  position: relative;
  display: inline-block;
  width: 75%;
  height: 10px;
}

.timelineArrowFinal::after {
  content: "";
  position: absolute;
  top: -5px;
  right: -20px;
  /* Offset the triangle to the right */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  /* Match half the arrow's height */
  border-bottom: 10px solid transparent;
  /* Match half the arrow's height */
  border-left: 20px solid #1777ff
    /* Width and color of the pointy face */
}

.timelineArrowPink::after {
  content: "";
  position: absolute;
  top: -5px;
  right: -20px;
  /* Offset the triangle to the right */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  /* Match half the arrow's height */
  border-bottom: 10px solid transparent;
  /* Match half the arrow's height */
  border-left: 20px solid #bb0697;
  /* Width and color of the pointy face */
}

.yellowColor {
  color: #000000;
  background-color: #f2cb06;
  border-radius: 10px;
  padding: 5px 10px;
  width: auto;
}

.yellowBackground {
  background: #f2cb06
}

.blueColor {
  color: #FFFFFF;
  background-color: #00a0e0;
  border-radius: 10px;
  padding: 5px 10px;
  width: auto;
}

.blueBackground {
  background: #00a0e0
}

.purpleColor {
  color: #FFFFFF;
  background-color: #7f2af7;
  border-radius: 10px;
  padding: 5px 10px;
  width: 110px !important;
}

.purpleBackground {
  background: #7f2af7
}

.GreenColor {
  color: #000000;
  background-color: #9bc900;
  border-radius: 10px;
  padding: 5px 10px;
  width: 110px;
}

.GreenBackground {
  background: #6db201;
}

.pinkColor {
  color: #FFFFFF;
  background-color: #bb0697;
  border-radius: 10px;
  padding: 5px 10px;
  width: auto;
}

.pinkBackground {
  background: #bb0697;
}

.magentaColor{
  background-color: #F72C5B;
  width: auto;
  padding: 5px 10px;
  color: white;
  border-radius: 10px;
}

.magentaBackground{
  background-color: #F72C5B;
}

.finalColor {
  color: #FFFFFF;
  background-color: #1777ff;
  border-radius: 10px;
  padding: 5px 10px;
  width: auto;
}

.finalBackground {
  background: #1777ff;
}

.docText {
  color: #005ae0;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
}

.checkCircleIcon {
  font-size: 30px;
  color: #6db201;
}

.connectingLineUp {
  height: 20px;
  width: 2px;
  background: #000000;
  margin-left: 20px;
  margin-bottom: -10px;
}

.connectingLineDown {
  height: 20px;
  width: 2px;
  background: #000000;
  margin-left: 20px;
  margin-top: -10px;
}

.timelineDisableHandler {
  pointer-events: none; 
  filter: grayscale(100%);
  opacity: 0.3;
}

.parent-div{
  overflow-y: scroll;
}

.card-parent{
  display: flex;
  flex-direction: column;
}

.cardChild{
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #E4E5E4;
  overflow-y: scroll;
}

.div-paragh-element{
  line-height: 10px;
}

.card-editor .jodit-toolbar {
  height: 30px !important;
  overflow: hidden;
}

.card-editor .jodit-wysiwyg {
  height: 30px !important;
  line-height: 1.5;
  padding: 5px;
}

.card-info{
  line-height: 10px;
}

.card-comment-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: none;
  overflow: visible;
  transition: max-height 0.3s ease-in-out;
  padding: 10px;
  border-radius: 10px;
}

.card-comment-editor{
  display: flex;
  flex-direction: column;
  width: 100% !important;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}

.circular-div{
  background-color: #1777ff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin-right: 10px;
}

.jodit-container{
  width: 100%;
}

.comments-section {
  max-height: none;
  overflow: visible;
  transition: max-height 0.3s ease-in-out;
}

.comment-object{
  display: flex;
  flex-direction: row;
}

.fontWeight{
  font-weight: 500;
}

.paragh-placeholder{
  width: 160px
}

.card-company-details{
  height: auto;
  width: 320px;
  border: 1px solid gray;
  padding: 10px;
  border-radius: 10px;
  float: right;
}

.timelineDetailsRow{
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.timelineCard{
  flex: 1;
  max-width: 200px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.invoiceStatusCard{
  background-color: rgb(230, 247, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  border-radius: 10px;
  border-left: 3px solid #abccfa;
}

.goodStatusCard{
  background-color: #fffbe6;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  border-radius: 10px;
  border-left: 3px solid #f8e8bc;
}

.companyCard{
  background-color: rgb(255, 240, 246);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  border-radius: 10px;
  border-left: 3px solid #faadcb;
}

.dateCard{
  background-color: rgb(240, 245, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  border-radius: 10px;
  border-left: 3px solid #8bb7f4;
}

.timelineTags{
  height: auto !important;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .timelineParent{
    height: calc(100vh - 500px) !important;
  }

  .textCenter{
    height: calc( 100vh - 690px );
  }
}

@media screen and (min-width: 901px) and (max-width: 1240px) {
  .timelineParent{
    height: calc(100vh - 500px) !important;
  }

  .textCenter{
    height: calc( 100vh - 690px );
  }
}

@media screen and (min-width: 1241px) and (max-width: 1440px) {
  .timelineParent{
    height: calc(100vh - 550px) !important;
  }

  .textCenter{
    height: calc( 100vh - 690px );
  }
}

@media screen and (min-width: 1441px) and (max-width: 1640px) {
  .timelineParent{
    height: calc(100vh - 500px) !important;
  }

  .textCenter{
    height: calc( 100vh - 690px );
  }
}

@media screen and (min-width: 1641px) and (max-width: 2440px) {
  .timelineParent{
    height: calc(100vh - 550px) !important;
  }

  .textCenter{
    height: calc( 100vh - 770px );
  }
}