.div-head-breadcrumbs{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.breadcrumb-main{
    margin: 16px 0;
    flex: 1;
}

.breadcrumb-active{
  color:'#1780FB'
}

.input-div{
    display: flex;
    justify-content: space-between;
}

.search-input-team{
    margin-top: -40px;
    text-align: right;
}

.searchInputTeam{
  width: 150px;
  margin-right: 20px;
}

.buttonAddTeam{
    margin-left: auto !important;
    width: 150px;
}

.input-teamname
  .input-teamDesc{
    width: 100% !important;
    margin: 0 auto !important;
    padding: 0 !important;
  }

  .form-team{
    width: 100% !important;
  }

  .form-item-name{
    width: 100%;
  }

  .form-item-desc{
    width: 100%;
  }

.list-item-meta{
  margin-left: 15px;
}

.action-button{
  display: flex;
  flex-direction: row;
}

.teamDesc{
  margin-top: 10px;
}

