.container-invoicing {
  margin: 0px 110px;
}

.introduction-section {
  height: calc(100vh - 370px);
  justify-content: center !important;
  text-align: center;
  align-items: center !important;
  padding: 50px;
}

.image-section {
  height: calc(100vh - 50px);
}

.detailing-section {
  height: calc(100vh - 300px);
  margin-top: 80px;
}

.section-faqs {
  height: auto !important;
  padding-bottom: 50px;
}

.data-security {
  height: calc(100vh - 520px);
  margin-top: 0px !important;
}

.heading-FAQS {
  font-size: 28px;
  font-weight: 300;
  display: flex;
  flex-direction: row;
}

.text-data-encryption {
  font-size: 20px;
  color: black;
}

.heading-intro {
  font-size: 40px;
  font-weight: 200;
  margin-top: 20px;
}

.span-intro {
  color: #1777ff;
}

.paragh-intro {
  margin-top: 20px;
}

.button-demo {
  margin-left: 16px;
  height: 50px;
}

.button-demo::hover {
  background-color: black;
  color: white;
}

.button-invoice {
  height: 50px;
}

.image-tablet {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.review-container {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 20px;
}

.play-store-icon {
  height: 24px;
  width: 24px;
}

.google-icon {
  height: 24px;
  width: 24px;
}

.telegram-icon {
  height: 20px;
  width: 20px;
}

.playstore-container {
  height: 40px;
  width: 40px;
  border: 1px solid gray;
  border-radius: 50px;
  padding: 5px;
  background-color: white;
  z-index: 15;
}

.googleicon-container {
  height: 40px;
  width: 40px;
  border: 1px solid gray;
  border-radius: 50px;
  padding: 5px;
  margin-left: -10px;
  background-color: white;
  z-index: 10 !important;
}

.share-container {
  height: 40px;
  width: 40px;
  border: 1px solid gray;
  border-radius: 50px;
  padding: 5px;
  margin-left: -10px;
  z-index: 1 !important;
}

.container-rating {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 19px;
}

.container-star {
  display: flex;
  flex-direction: row;
}

.text-rating {
  color: black;
  font-size: 30px;
  font-weight: 200;
  margin-left: 10px;
}

.image-star {
  height: 12px;
  width: 12px;
}

.text-customer {
  color: black;
  font-size: 13px;
}

.detailing-sub-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.detailing-mini-container {
  height: 300px;
  width: 50%; 
  display: flex;
  flex-direction: column;
  align-items: center !important;
  margin-top: 5%;
  overflow: hidden; 
  position: relative;
}

.detailing-mini-container-one {
  height: 100%;
  width: 50%;
  margin-left: 50px;
  /* border-top: 1px solid gray; */
  margin-top: 20px;
}

.row-details {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.background-image {
  background-color: #1777ff;
  color: white;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  align-items: center;
  text-align: center !important;
}

.columnDetails {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.button-faqs {
  color: #1777ff;
  border: 1px solid #1777ff;
  margin-top: 5px;
  margin-left: 10px;
  border-radius: 30px;
  width: 100px;
  font-size: 18px;
}

.side-tab {
  height: 80%;
  width: 50%;
  margin-top: 1px;
  margin-left: 0%;
}

.divider-invoice {
  border-color: gray;
}

.data-sub-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.data-mini-container {
  width: 45%;
}

.data-mini-container-one {
  width: 45%;
  margin-left: 10%;
}

.row-data-security {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  line-height: 25px;
}

.faq-cards {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.faq-card {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: black;
}

.faq-answer {
  margin-top: 10px;
  font-size: 16px;
  color: black;
  font-weight: 200;
  display: flex;
  justify-content: flex-start;
}

.paragh-faq {
  font-size: 16px;
  font-weight: 300;
  color: black;
  margin-top: 5px;
}

.button-sub-faq {
  border: 1px solid black;
  border-radius: 30px;
}

.image-create-invoice {
  height: 100px; 
  width: 100px; 
  object-fit: cover; 
  transition: all 0.3s ease-in-out; 
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  z-index: 0;
}

.image-create-invoice:hover {
  height: 100%; 
  width: 100%;
  z-index: 1; 
}

.row-images {
  margin-top: 20px !important;
  width: 100%;
  padding: 0px;
  justify-content: space-evenly !important;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 432px) {
  .container-invoicing {
    margin: 10px 10px;
  }

  .introduction-section {
    height: calc(100vh - 250px) !important;
    text-align: center;
  }

  .image-section {
    height: calc(100vh - 300px);
    width: 100%;
    text-align: center;
    justify-content: center !important;
  }

  .detailing-section {
    height: auto !important;
    padding-bottom: 0px;
    margin-top: 0px !important;
  }

  .data-security {
    height: calc(100vh - 360px);
    margin-top: 20px;
  }

  .heading-intro {
    font-size: 24px;
  }

  .access-button {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
  }

  .button-demo {
    margin-left: 10px;
    height: 35px;
    font-size: 12px;
  }

  .button-invoice {
    height: 35px;
    font-size: 12px;
  }

  .detailing-sub-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .detailing-mini-container {
    height: calc(100vh - 450px);
    width: 100%;
    margin: 0px;
    /* background-color: aliceblue; */
    padding: 0px !important;
  }

  .detailing-mini-container-one {
    height: 65%;
    width: 100%;
    margin-top: -9px;
    margin-left: auto;
  }

  .row-details {
    display: flex;
    flex-direction: row;
    height: 24%;
    /* border-bottom: 1px solid gray; */
    margin-top: 20px;
  }

  .side-tab {
    width: 100%;
    height: 90%;
    margin-left: 0%;
  }

  .background-image {
    background-color: #1777ff;
    color: white;
    height: 20px;
    width: 20px;
    border-radius: 30px;
    align-items: center;
    text-align: center !important;
    font-size: 13px;
  }

  .image-tablet {
    margin-left: -3px !important;
  }

  .data-sub-container {
    display: flex;
    flex-direction: column;
  }

  .data-mini-container {
    width: 100%;
  }

  .data-mini-container-one {
    width: 100%;
    margin-left: 0%;
    margin-top: 5px;
  }

  .row-data-security {
    display: flex;
    flex-direction: row;
  }

  .text-data-encryption {
    font-size: 18px;
  }

  .background-image {
    margin-top: 5px;
  }

  .carousel-image {
    width: 100% !important;
    max-width: 300px;
    height: auto !important;
    margin: 22px auto;
  }

  .image-create-invoice{
    height: 100% !important;
    width: 100% !important;
  }
}

@media screen and (min-width: 1241px) and (max-width: 1440px) {
  .container-invoicing {
    margin: 0px 150px !important;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1640px) {
  .container-invoicing {
    margin: 0px 110px !important;
  }
}

@media screen and (min-width: 1641px) and (max-width: 2440px) {
  .container-invoicing {
    margin: 0px 200px;
  }

  .introduction-section {
    height: calc(100vh - 450px);
    justify-content: center !important;
    text-align: center;
    align-items: center !important;
    padding: 50px;
  }

  .data-security {
    height: calc(100vh - 620px);
    margin-top: 80px !important;
  }
}
